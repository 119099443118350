import BaseStore from "@/core/classes/base/BaseStore";
import EthicalAdvicesService from "../services/EthicalAdvicesServices";

import { defineStore } from "pinia";
import { useEthicalAdvicesStore } from "./EthicalAdvicesStore";
import EthicalAdvice from "../models/EthicalAdvice";

export const useEthicalAdviceStore = defineStore("ethicalAdviceStore", {
  state: () => ({}),
  getters: {
    allAdvices() {
      const ethicalAdvicesStore = useEthicalAdvicesStore();
      return ethicalAdvicesStore.paginatedEthicalAdvices.collection;
    },
  },
  actions: {
    createEthicalAdvice(data) {
      return BaseStore.promiseHandler(
        () => EthicalAdvicesService.createEthicalAdvice(data),
        (data) => {
          this.allAdvices.unshift(new EthicalAdvice(data.data));
        }
      );
    },
    editEthicalAdvice(id, data) {
      return BaseStore.promiseHandler(
        () => EthicalAdvicesService.editEthicalAdvice(id, data),
        () => {
          // Update advice content in paginated collection
          const itemIndex = this.allAdvices.findIndex(
            (advice) => advice.id == id
          );
          this.allAdvices[itemIndex].content = data.advice;
        }
      );
    },
    deleteEthicalAdvice(id) {
      return BaseStore.promiseHandler(
        () => EthicalAdvicesService.deleteEthicalAdvice(id),
        () => {
          this.allAdvices.splice(
            this.allAdvices.findIndex((advice) => advice.id === id),
            1
          );
        }
      );
    },
  },
});

import { defineStore } from "pinia";
import { useTrainingProgramsStore } from "./TrainingProgramsStore";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgram from "../models/TrainingProgram";
import TrainingProgramsService from "../services/TrainingProgramsService";

export const useTrainingProgramStore = defineStore("trainingProgramStore", {
  state: () => ({
    trainingProgram: new TrainingProgram(),
  }),
  getters: {
    allTrainingPrograms() {
      const trainingProgramStore = useTrainingProgramsStore();
      return trainingProgramStore.paginatedTrainingPrograms.collection;
    },
  },
  actions: {
    fetchTrainingProgram(id) {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.getTrainingProgram(id),
        (data) => {
          this.trainingProgram = new TrainingProgram(data.data);
        }
      );
    },
    createTrainingProgram(data, video) {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.createTrainingProgram(data),
        (data) => {
          this.uploadTrainingProgramVideo(data.data.id, video);
        }
      );
    },
    editTrainingProgram(id, data, video) {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.editTrainingProgram(id, data),
        () => {
          if (video != null) {
            this.uploadTrainingProgramVideo(id, video);
          }
        }
      );
    },
    toggleTrainingProgram(id, data) {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.editTrainingProgram(id, data),
        () => {
          var index = this.allTrainingPrograms.findIndex((tp) => tp.id === id);
          // toggle status locally
          this.allTrainingPrograms[index].isEnabled =
            this.allTrainingPrograms[index].isEnabled == 0 ? 1 : 0;
        }
      );
    },
    deleteTrainingProgram(id) {
      return BaseStore.promiseHandler(
        () => TrainingProgramsService.deleteTrainingProgram(id),
        () => {
          this.allTrainingPrograms.splice(
            this.allTrainingPrograms.findIndex((tp) => tp.id === id),
            1
          );
        }
      );
    },
    uploadTrainingProgramVideo(id, video) {
      TrainingProgramsService.uploadTrainingProgramVideo(id, video);
    },
  },
});

<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName>ترتيب ظهور أسئلة طلب المنحة التدريبية </template>
    <v-container>
        <SortQuestionTabs />
    </v-container>
  </BaseCRUDPageLayout>
</template>
<script>
import SortQuestionTabs from "../components/SortQuestionTabs.vue";

export default {
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "أسئلة التقدم على منحة تدريبية",
          href: "/training-program-application-questions",
          disabled: false,
        },
        {
          text: "ترتيب ظهور الأسئلة",
          disabled: true,
        },
      ],
    };
  },
  components: { SortQuestionTabs },
};
</script>

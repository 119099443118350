import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";
import {
  fetchNotificationsAPI,
  markNotificationsAsReadAPI,
  readNotificationAPI,
} from "./Notifications.api";

export default class NotificationsService extends BaseService {
  static fetchNotifications(params) {
    return Client.get(fetchNotificationsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static readNotification(notificationId) {
    return Client.put(readNotificationAPI(notificationId), {}).then((res) =>
      Promise.resolve(res)
    );
  }
  static markNotificationsAsRead() {
    return Client.put(markNotificationsAsReadAPI, {}).then((res) =>
      Promise.resolve(res)
    );
  } 
}

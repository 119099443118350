export const getAdminsAPI = "/admins";
export const createAdminAPI = "/admins";
export function getAdminAPI(id) {
  return "/admins/" + id;
}
export function editAdminAPI(id) {
  return "/admins/" + id;
}
export function deleteAdminAPI(id) {
  return "/admins/" + id;
}

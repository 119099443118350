import { defineStore } from "pinia";

import PaginatedProjectsCollection from "../models/PaginatedProjectsCollection";
import ProjectKeyTransformations from "../models/ProjectKeyTransformations";
import ProjectsService from "../services/ProjectsServices";
import BaseStore from "@/core/classes/base/BaseStore";

export const useProjectsStore = defineStore("projectsStore", {
  state: () => ({
    paginatedProjects: new PaginatedProjectsCollection(),
    projectsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      var sortObj =
        this.projectsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                ProjectKeyTransformations[this.projectsTableOptions.sortBy[0]],
              sort_desc: this.projectsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.projectsTableOptions.page,
        ...sortObj,
        items_per_page: this.projectsTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.projectsTableOptions = options;
      this.fetchProjects();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedProjects();
      this.fetchProjects();
    },
    resetPaginatedProjects() {
      this.paginatedProjects = new PaginatedProjectsCollection();
    },
    fetchProjects() {
      return BaseStore.promiseHandler(
        () => ProjectsService.getProjects(this.getSearchPageParameters),
        (data) => {
          this.paginatedProjects = new PaginatedProjectsCollection(data);
        }
      );
    },
  },
});

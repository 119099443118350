import { StatisticsType } from "../components/statics/StatisticsTypes";

export default class PublishersStatistics {
  newPublishersCountPerDuration;
  publishersTotalCount;
  //duration of stat
  newPublishersCountDuration;

  constructor(data) {
    if (data) {
      this.newPublishersCountPerDuration =
        data?.new_publishers_count_per_duration;
      this.publishersTotalCount = data?.total_publishers_count;
      //setting durations
      this.newPublishersCountDuration = data?.new_publishers_count_duration;
    } else {
      const localData = JSON.parse(
        localStorage.getItem(StatisticsType.PublishersStatistics)
      );
      this.newPublishersCountPerDuration =
        localData?.new_publishers_count_per_duration ?? {};
      this.publishersTotalCount = localData?.total_publishers_count ?? null;
      //setting durations
      this.newPublishersCountDuration =
        localData?.new_publishers_count_duration ?? "6_months";
    }
  }
}

<template>
  <v-card color="lightSecondary" rounded="xl">
    <v-card-title>
      <v-container>
        <v-row justify="center">
          <!--JOB PUBLISHER PROFILE-->
          <v-col align="center" cols="12" md="6" class="secondary--text">
            <RouterLink v-if="hasPermission(showPublisherPermission)" :to="'/job-publishers/' + job.publisher.id">
              <v-avatar color="white" size="100">
                <v-img
                  v-if="job.publisher.logoURL"
                  contain
                  :src="job.publisher.logoURL"
                  class="rounded"
                ></v-img>
                <v-icon v-else x-large color="lightSecondary"
                  >mdi-domain</v-icon
                >
              </v-avatar>
              <h3 class="secondary--text">{{ job.publisher.name }}</h3>
            </RouterLink>
            <div v-else>
              <v-avatar color="white" size="100">
                <v-img
                  v-if="job.publisher.logoURL"
                  contain
                  :src="job.publisher.logoURL"
                  class="rounded"
                ></v-img>
                <v-icon v-else x-large color="lightSecondary"
                  >mdi-domain</v-icon
                >
              </v-avatar>
              <h3 class="secondary--text">{{ job.publisher.name }}</h3>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <!--JOB OPPORTUNITY DETAILS-->
    <v-card-text>
      <v-card class="py-5" rounded="xl" elevation="5">
        <v-container>
          <v-row>
            <v-col align="start">
              <v-row class="mb-10 text">
                <h2 class="secondary--text mx-3">{{ job.title }}</h2>
                <v-chip color="primary"> {{ job.pluckFieldNames().toString() }}</v-chip>
              </v-row>
              <div class="accent--text">
                تاريخ النشر:
                {{ job.createdAt }}
              </div>
              <div v-if="job.publishedAt" class="accent--text">
                تاريخ القبول:
                {{ job.publishedAt }} <br />
                عدد طلبات التقديم: {{ job.applicantsCount }}
              </div>
              <div>
                <v-icon color="primary">mdi-state-machine</v-icon> 
                <span class="primary--text">حالة الفرصة : </span>
                {{translateLabel(job.status)}} 
              </div>
              <div>
                <v-icon color="primary">mdi-poll</v-icon>
                <span class="primary--text"> مستوى الخبرة المطلوبة: </span>
                {{ job.requiredExperienceLevel }}
              </div>
              <div>
                <v-icon color="primary">mdi-clock-outline</v-icon>
                <span class="primary--text"> نوع الدوام : </span>
                {{ translateLabel(job.shiftType) }} ({{ job.workHours }} ساعة)
              </div>
              <div v-if="job.minSalary && job.maxSalary">
                <v-icon color="primary">mdi-cash-multiple</v-icon>
                <span class="primary--text"> مجال الراتب : </span>
                ({{ formatCurrency(job.minSalary) }} -
                {{ formatCurrency(job.maxSalary) }} )
                <v-chip dark color="warning" v-if="job.isSalaryShown">الراتب ظاهر للمتقدمين</v-chip>
                <v-chip dark color="warning"  v-else>الراتب غير ظاهر للمتقدمين</v-chip>
              </div>
              <div v-if="job.minAge && job.maxAge">
                <v-icon color="primary">mdi-account-supervisor</v-icon>
                <span class="primary--text"> مجال العمر : </span>
                ({{ job.minAge }} - {{ job.maxAge }}سنة )
              </div>
              <div>
                <v-icon color="primary">mdi-certificate-outline</v-icon>
                <span class="primary--text"> التحصيل العلمي المطلوب: </span>
                {{ job.requiredEducation }} ({{
                  translateLabel(job.requiredEducationLevel)
                }})
              </div>

              <div>
                <v-icon color="primary">mdi-list-status</v-icon>
                <span class="primary--text"> إنهاء خدمة العلم: </span>
                ({{ translateLabel(job.requiredMilitaryServiceStatus) }})
              </div>

              <div>
                <v-icon color="primary">mdi-gender-male-female</v-icon>
                <span class="primary--text"> الجنس المطلوب: </span>
                ({{ translateLabel(job.requiredGender) }})
              </div>

              <div
                v-if="
                  job.workLocationType == 'on_site' ||
                  job.workLocationType == 'hybrid'
                "
              >
                <v-icon color="primary">mdi-map-marker</v-icon>
                <span class="primary--text"> موقع العمل: </span>
                {{ job.workLocation }} ({{ translateLabel(job.workLocationType) }})
              </div>
              <div v-else>
                <v-icon color="primary">mdi-map-marker</v-icon>
                <span class="primary--text"> موقع العمل: </span>
                 عمل عن بعد
              </div>
              <div>
                <h4 class="secondary--text mt-5">المهارات المطلوبة:</h4>
                <ul class="mr-7">
                  <li v-for="skill in job.requiredSkills" :key="skill">
                    {{ skill }}
                  </li>
                </ul>
              </div>

              <div>
                <h4 class="secondary--text">تفاصيل الفرصة:</h4>
                <div v-if="job.responsibilities">
                  المسؤوليات: {{ job.responsibilities }}
                </div>
                <div v-if="job.extraRequirements">
                  متطلبات إضافية:{{ job.extraRequirements }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card-text>
    <!--JOB PUBLISHER DETAILS-->
    <v-container>
      <v-row>
        <v-col cols="12" md="11" align="start" class="secondary--text">
          <div>
            <h4>عن ناشر فرصة العمل</h4>
            {{ job.publisher.about }}
          </div>
          <div>
            <v-icon color="primary">mdi-briefcase-variant-outline</v-icon>
            المجال:
            {{ job.publisher.field }}
          </div>
          <div>
            <v-icon color="primary">mdi-map-marker</v-icon> الموقع:
            {{ job.publisher.location }}
          </div>
          <div>
            <v-icon color="primary">mdi-email-outline</v-icon> البريد
            الالكتروني:
            {{ job.publisher.email }}
          </div>
          <div>
            <v-icon color="primary">mdi-cellphone</v-icon> رقم الهاتف:
            {{ job.publisher.phoneNumber }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";
import JobOpportunity from "../models/JobOpportunity";
import CurrencyFormattingMixin from "@/core/mixins/CurrencyFormattingMixin.vue";

export default {
  mixins: [PermissionManagerMixin,CurrencyFormattingMixin],
  props: {
    job: Object,
  },
  data(){
    return {
      showPublisherPermission:READ_JOB_PUBLISHERS_PERMISSION,
    }
  },
  methods: {
    translateLabel(prop) {
      return JobOpportunity.trans(prop);
    },
  },
  components: { RouterLink },
};
</script>

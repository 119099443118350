import pinia from "@/store";
import Resumable from "resumablejs";
import router from "@/router";

import { Notify } from "../notify/Notify";
import { useGlobalStore } from "../../../store/GlobalStore";

const globalStore = useGlobalStore(pinia);

let resumable = new Resumable({
  target: process.env.VUE_APP_API_BASE_URL,
  fileType: ["mp4"],
  headers: {
    accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  chunkSize: 1 * 1024 * 1024,
  testChunks: false,
  fileParameterName: "video",
  throttleProgressCallbacks: 1,
});

resumable.on("filesAdded", function () {
  (resumable.opts.headers.authorization =
    "Bearer " + localStorage.getItem("token")),
    globalStore.changeLoadingStatus(true);
  resumable.upload();
});

resumable.on("error", function () {
  globalStore.changeLoadingStatus(false);
  Notify.showMessage("error", "حدث خطأ أثناء رفع ملفات الفيديو");
});

resumable.on("complete", function () {
  globalStore.changeLoadingStatus(false);
  Notify.showMessage("success", "تم رفع ملفات الفيديو بنجاح");
  router.go(-1);
});

export default resumable;

import UserLayout from "@/core/layouts/UserLayout.vue";
import {
  READ_TP_APPLICATIONS_PERMISSION,
  WRITE_TP_APPLICATIONS_PERMISSION,
} from "@/core/statics/Permissions";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";
import TrainingProgramApplicationsPage from "../pages/TrainingProgramApplicationsPage.vue";
import TrainingProgramApplicationPage from "../pages/TrainingProgramApplicationPage.vue";
import TrainingProgramApplicationExportKeysPage from "../pages/TrainingProgramApplicationExportKeysPage.vue";

export default {
  path: "/training-program-applications",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "training_program_applications",
      component: TrainingProgramApplicationsPage,
      meta: {
        title: "طلبات التقديم على المنح التدريبية",
        requiredPermission: READ_TP_APPLICATIONS_PERMISSION,
      },
    },
    {
      path: "/training-program-applications/export-keys",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          name: "training_program_application_export_keys",
          component: TrainingProgramApplicationExportKeysPage,
          meta: {
            title: "تنسيق تصدير الطلبات",
            requiredPermission: WRITE_TP_APPLICATIONS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/training-program-applications/:id",
      name: "training_program_application",
      component: TrainingProgramApplicationPage,
      meta: {
        title: "طلب منحة تدريبية",
        requiredPermission: READ_TP_APPLICATIONS_PERMISSION,
      },
    },
  ],
};

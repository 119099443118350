export default {
  id: "id",
  name: "name",
  field: "field",
  location: "location",
  logoURL: "logo",
  email: "email",
  about: "about",
  phoneNumber: "phone_number",
  isRestricted: "is_restricted",
  isUserVisible: "is_user_visible",
  createdAt: "created_at",
};

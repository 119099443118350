<template>
  <v-row class="mt-15">
    <v-col cols="12" sm="6">
      <v-form ref="form">
        <h3 class="mb-5 primary--text">تغيير كلمة السر</h3>
        <BasePasswordField
          label="كلمة السر القديمة"
          placeholder="كلمة السر القديمة"
          v-model="oldPassword"
          :rules="[requiredRule, passwordRule]"
        >
        </BasePasswordField>
        <BasePasswordField
          label="كلمة السر الجديدة"
          placeholder="كلمة السر الجديدة"
          v-model="newPassword"
          :rules="[requiredRule, passwordRule]"
        >
        </BasePasswordField>
        <BasePasswordField
          label="تأكيد كلمة السر"
          placeholder="تأكيد كلمة السر"
          v-model="newPasswordConfirmation"
          :rules="[requiredRule, confirmPasswordRule(this.newPassword)]"
        >
        </BasePasswordField>
      </v-form>
    </v-col>
    <v-col cols="12" sm="4" align="center" align-self="center">
      <v-btn
        color="lightSecondary"
        class="px-7"
        rounded
        @click="changePassword()"
        >تغيير كلمة السر</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "pinia";
import { useAuthStore } from "../store";
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["sendChangePassword"]),
    changePassword() {
      if (this.$refs.form.validate()) {
        var data = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
        this.sendChangePassword(data)
          .then(()=>{
            this.$refs.form.reset()
          })
          .catch((err) => console.error(err));
      }
    },
  },
};
</script>

export const getTrainingProgramsAPI = "/training-programs";
export const createTrainingProgramsAPI = "/training-programs";
export function getTrainingProgramAPI(id) {
  return "/training-programs/" + id;
}
export function editTrainingProgramAPI(id) {
  return "/training-programs/" + id;
}
export function deleteTrainingProgramAPI(id) {
  return "/training-programs/" + id;
}
export function uploadTrainingProgramAPI(id) {
  return "/training-programs/upload-video/" + id;
}

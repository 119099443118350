import { defineStore } from "pinia";

import JobApplicant from "../models/JobApplication";
import BaseStore from "@/core/classes/base/BaseStore";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import ApplicantKeyTransformations from "../models/key_transformations/ApplicationKeyTransformations";
import PaginatedJobApplicationsCollection from "../models/paginated_collections/PaginatedJobApplicationsCollection";

export const useJobApplicantsStore = defineStore("jobApplicantsStore", {
  state: () => ({
    paginatedApplicants: new PaginatedJobApplicationsCollection(),
    jobId: null,
    applicantsTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    isOrganizationGraduate: null,
    applicant: new JobApplicant(),
  }),
  getters: {
    getFilterSearchSortPageParameters() {
      var sortObj =
        this.applicantsTableOptions.sortBy.length != 0
          ? {
              sort_by:
                ApplicantKeyTransformations[
                  this.applicantsTableOptions.sortBy[0]
                ],
              sort_desc: this.applicantsTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        search_key: this.searchKey,
        page: this.applicantsTableOptions.page,
        items_per_page: this.applicantsTableOptions.itemsPerPage,
        ...sortObj,
        is_organization_graduate: this.isOrganizationGraduate,
      });
    },
  },
  actions: {
    setJobId(id) {
      this.jobId = id;
    },
    setFilterNFetch(organizationGraduateFilter) {
      this.isOrganizationGraduate = organizationGraduateFilter;
      this.resetPaginatedApplicants();
      this.fetchJobApplicants();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedApplicants();
      this.fetchJobApplicants();
    },
    setTableOptionsNFetch(options) {
      (this.applicantsTableOptions = options), this.fetchJobApplicants();
    },
    resetPaginatedApplicants() {
      this.paginatedApplicants = new PaginatedJobApplicationsCollection();
    },
    fetchJobApplicants() {
      return BaseStore.promiseHandler(
        () =>
          JobOpportunitiesService.getJobApplicants(
            this.jobId,
            this.getFilterSearchSortPageParameters
          ),
        (data) => {
          this.paginatedApplicants = new PaginatedJobApplicationsCollection(
            data
          );
        }
      );
    },
    fetchJobApplicant(applicantId) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.getJobApplicant(applicantId),
        (data) => {
          this.applicant = new JobApplicant(data.data);
        }
      );
    },
    passJobApplicant(applicantId) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.passJobApplicant(applicantId),
        () => {
          this.updateJobApplicantStatus(applicantId, "passed");
        }
      );
    },
    rejectJobApplicant(applicantId) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.rejectJobApplicant(applicantId),
        () => {
          this.updateJobApplicantStatus(applicantId, "rejected");
        }
      );
    },
    updateJobApplicantStatus(applicantId, newStatus) {
      this.paginatedApplicants.collection.map((applicant) => {
        if (applicant.id == applicantId) {
          applicant.status = newStatus;
        }
      });
      this.applicant.status = newStatus;
    },
  },
});

<template>
  <v-container v-can:[showPermission]>
    <v-row>
      <v-col cols="10" md="12" align="center">
        <v-row>
          <h3 class="mb-5 secondary--text">
            لمحة عن ناشري فرص العمل ضمن المنصة
            <span class="mr-2 text-subtitle-2 primary--text">
              (قد لا تعكس الإحصاءات التالية بالضرورة نشاط المنصة في آخر 24 ساعة)
            </span>
          </h3>
        </v-row>
        <v-row justify="space-around">
          <NewPublishersCountPerDurationCard />
          <PublishersTotalCountCard />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { READ_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions } from "pinia";
import { useStatisticsStore } from "../store/StatisticsStore";
import { StatisticsType } from "./statics/StatisticsTypes";
import NewPublishersCountPerDurationCard from "./statistics_cards/publishers/NewPublishersCountPerDurationCard.vue";
import PublishersTotalCountCard from "./statistics_cards/publishers/PublishersTotalCountCard.vue";

export default {
  components: {
    NewPublishersCountPerDurationCard,
    PublishersTotalCountCard,
  },
  mounted() {
    this.fetchStatistics(StatisticsType.PublishersStatistics)
      ?.then()
      ?.catch((err) => console.error(err));
  },
  data() {
    return {
      showPermission: READ_JOB_PUBLISHERS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useStatisticsStore, ["fetchStatistics"]),
  },
};
</script>

<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <DetailsPage :obj="project" :isNews="false"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditProjectButton
            :item="project"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteProjectButton
            :item="project"
            :goBackWhenItsFinished="true"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useProjectStore } from "../store/ProjectStore";

import DetailsPage from "@/core/components/news_and_projects/DetailsPage.vue";
import EditProjectButton from "../components/action_buttons/EditProjectButton.vue";
import DeleteProjectButton from "../components/action_buttons/DeleteProjectButton.vue";

export default {
  mounted() {
    var projectId = this.$route.params.id;
    this.fetchProject(projectId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useProjectStore, ["project"]),
  },
  methods: {
    ...mapActions(useProjectStore, ["fetchProject"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "المشاريع المميزة",
          href: "/projects",
          disabled: false,
        },
        {
          text: "تفاصيل المشروع رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { DetailsPage, EditProjectButton, DeleteProjectButton },
};
</script>

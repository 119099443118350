<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedFeedback"
    :searchable="false"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:[`item.rating`]="{ item }">
      <v-rating
        v-if="item.rating != null && item.rating != undefined"
        color="accent"
        hover
        length="5"
        readonly
        size="34"
        :value="item.rating"
      ></v-rating>
      <label v-else>لم يتم تحديد تقييم</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePlatformFeedbackStore } from "../store/PlatformFeedbackStore";

export default {
  computed: {
    ...mapState(usePlatformFeedbackStore, ["paginatedFeedback"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "محتوى الرأي", value: "content" },
        { text: "التقييم", value: "rating" },
        { text: "تاريخ الإرسال", value: "createdAt" },
      ],
    };
  },
  mounted() {
    this.fetchPlatformFeedback()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(usePlatformFeedbackStore, [
      "fetchPlatformFeedback",
      "setTableOptionsNFetch",
    ]),
  },
};
</script>

<template>
  <BaseTableItemAction
    v-can:[editPermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-pencil"
    actionName="تعديل"
    :to="'/faqs/' + item.id + '/edit'"
  >
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_FAQS_PERMISSION } from '@/core/statics/Permissions';

export default {
  props: {
    item: Object,
  },
  data() {
    return{
      editPermission:WRITE_FAQS_PERMISSION,
    }
  },
};
</script>

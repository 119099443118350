<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <!-- Title -->
          <BaseTextField
            label="العنوان"
            placeholder="عنوان الخبر"
            persistent-placeholder
            v-model="title"
            :rules="[requiredRule, notEmptyRule]"
          ></BaseTextField>

          <!-- Description -->
          <v-textarea
            label="الشرح"
            placeholder="شرح الخبر"
            persistent-placeholder
            v-model="description"
            outlined
            :rules="[requiredRule, notEmptyRule]"
          ></v-textarea>

          <!-- Youtube links -->
          <BaseCombobox
            outlined
            v-model="youtubeLinks"
            :items="[...youtubeLinks]"
            label="روابط اليوتيوب"
            placeholder="روابط الفيديوهات المنشورة على منصة اليوتيوب، والتي ترغب بعرضها مع الخبر"
            hint="ضع الرابط الذي ترغب بإضافته ثم انقر enter"
            persistent-placeholder
            prepend-inner-icon="mdi-youtube"
            chips
            clearable
            multiple
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-icon class="ml-2" color="primary"> mdi-link </v-icon>
                {{ data.item }}
              </v-chip>
            </template>
          </BaseCombobox>

          <!-- Facebook links -->
          <BaseCombobox
            outlined
            v-model="facebookLinks"
            :items="[...facebookLinks]"
            label="روابط الفيسبوك"
            placeholder="روابط الفيديوهات المنشورة على منصة الفيسبوك، والتي ترغب بعرضها مع الخبر"
            hint="ضع الرابط الذي ترغب بإضافته ثم انقر enter"
            persistent-placeholder
            prepend-inner-icon="mdi-facebook"
            clearable
            chips
            multiple
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-icon class="ml-2" color="primary"> mdi-link </v-icon>
                {{ data.item }}
              </v-chip>
            </template>
          </BaseCombobox>

          <!-- Cover image -->
          <v-file-input
            v-model="coverImage"
            accept="image/png, image/jpeg"
            prepend-inner-icon="mdi-image"
            prepend-icon=""
            label="الصورة الأساسية"
            placeholder="الصورة الأساسية التي ستظهر مع الخبر"
            persistent-placeholder
            outlined
            @change="changeCoverImage()"
          >
            <template v-slot:selection>
              <div style="position: relative">
                <v-btn
                  @click.stop="removeCoverImage()"
                  fab
                  x-small
                  class="delete-icon"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
                <v-img class="ma-2 rounded image" :src="coverImageUrl"></v-img>
              </div>
            </template>
          </v-file-input>

          <!-- Images -->
          <v-file-input
            v-model="images"
            accept="image/png, image/jpeg"
            prepend-inner-icon="mdi-image"
            prepend-icon=""
            label="الصور"
            placeholder="الصور التي ترغب بعرضها مع الخبر"
            persistent-placeholder
            outlined
            multiple
            @change="changeImages()"
          >
            <template v-slot:selection="data">
              <div style="position: relative">
                <v-btn
                  @click.stop="removeImage(data)"
                  fab
                  x-small
                  class="delete-icon"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
                <v-img
                  class="ma-2 rounded image"
                  :src="imageUrls[data.index]"
                ></v-img>
              </div>
            </template>
          </v-file-input>

          <!-- Videos -->
          <v-file-input
            v-model="videos"
            accept="video/mp4"
            prepend-inner-icon="mdi-video"
            prepend-icon=""
            label="الفيديوهات"
            placeholder="الفيديوهات التي ترغب بعرضها مع الخبر"
            persistent-placeholder
            outlined
            multiple
            @change="changeVideos()"
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-avatar class="ml-2" color="lightSecondary">
                  {{ data.index + 1 }}
                </v-avatar>
                {{ data.text ? data.text : data.file }}
              </v-chip>
            </template>
          </v-file-input>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="submitForm()"
            >
              تعديل الخبر
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { EventBus } from "@/main";
import { mapActions, mapState } from "pinia";
import { useNewsStore } from "../../store/NewsStore";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      title: "",
      description: "",
      coverImage: null,
      isCoverImageChanged: false,
      isImagesChanged: false,
      isVideosChanged: false,
      images: [],
      videos: [],
      youtubeLinks: [],
      facebookLinks: [],
      deletedIds: [],
    };
  },

  computed: {
    ...mapState(useNewsStore, ["news"]),
    imageUrls() {
      var urls = [];
      this.images.map((image) => {
        image?.size
          ? urls.push(URL.createObjectURL(image))
          : urls.push(image.name);
      });
      return urls;
    },
    coverImageUrl() {
      return this.coverImage?.size
        ? URL.createObjectURL(this.coverImage)
        : this.coverImage.name;
    },
  },
  methods: {
    ...mapActions(useNewsStore, ["editNews"]),

    /*
     This function is whenever the user clicks the delete icon on the field, or changes the data
      We will add all videos ids to the deletedIds array, and the local video array will be erased automatically (v-model)
    */
    changeVideos() {
      if (!this.isVideosChanged) {
        this.isVideosChanged = true;
        this.news.medias.map((media) => {
          if (media.type == "video") {
            this.deletedIds.push(media.id);
          }
        });
      }
    },
    /*
     This function is whenever the user clicks the delete icon on the field, or changes the data
      We will add all images ids to the deletedIds array, and the local image array will be erased automatically (v-model)
    */
    changeImages() {
      if (!this.isImagesChanged) {
        this.isImagesChanged = true;
        this.news.medias.map((media) => {
          if (!media.isCover && media.type == "image") {
            this.deletedIds.push(media.id);
          }
        });
      }
    },
    /*
     This function is whenever the user clicks the delete icon on the field, or changes the data
      We will add all cover image id to the deletedIds array, and the local image file will be erased automatically (v-model)
    */
    changeCoverImage() {
      if (!this.isCoverImageChanged) {
        this.isCoverImageChanged = true;
        /* used return to break the loop when found the item, the cover image is just one item, so we don't need to loop the whole array */
        this.news.medias.map((image, index) => {
          if (image.isCover) {
            this.deletedIds.push(image.id);
            this.news.medias.splice(index, 1);
            return;
          }
        });
      }
    },
    /*
      This function is whenever the user clicks on the delete (close) icon on the image
      Since the cover image is one file, not an array, we don't need to check every delete action to add media id to deletedId
      Just the first remove we will add its id to the array, then the other deleting processes will be ignored and just remove it from the local list
    */
    removeCoverImage() {
      if (!this.isCoverImageChanged) {
        this.isCoverImageChanged = true;
        /* used return to break the loop when found the item, the cover image is just one item, so we don't need to loop the whole array */
        this.news.medias.map((image, index) => {
          if (image.isCover) {
            this.coverImage = null;
            this.deletedIds.push(image.id);
            this.news.medias.splice(index, 1);
            return;
          }
        });
      } else {
        this.coverImage = null;
      }
    },
    /*
      This function whenever user click on the delete (close) icon on the image
      if the removed image is found in the new medias list, then it will be removed from the list, then add it's id to [deletedIds] list
      else remove the image as usual from the local images list [images]
    */
    removeImage(image) {
      var index = this.news.medias.findIndex(
        (media) => media.localPath == image.file.name
      );
      if (index != -1) {
        this.deletedIds.push(this.news.medias[index].id);
        this.news.medias.splice(index, 1);
        this.images.splice(image.index, 1);
      } else {
        this.images.splice(image.index, 1);
      }
    },
    /*
      initialize medias arrays [images,videos,facebookLinks,youtubeLinks] and coverImage, based on the media type
      because "file input v-model" only takes an array of files, so we will generate a file for each (image, video) with an empty array of bytes, and the name will be the path
    */
    initializeMedias() {
      this.title = this.news.title;
      this.description = this.news.description;
      this.news.medias.map((media) => {
        if (media.type == "image") {
          if (media.isCover) {
            this.coverImage = new File([], media.localPath);
          } else {
            this.images.push(new File([], media.localPath));
          }
        } else if (media.type == "video") {
          this.videos.push(new File([], media.localPath));
        } else {
          if (media.externalPlatform == "Facebook") {
            this.facebookLinks.push(media.externalLink);
          } else {
            this.youtubeLinks.push(media.externalLink);
          }
        }
      });
    },
    /*
      return form data object in the required format for send
    */
    getFormData() {
      var coverImageToSend = null;
      if (this.coverImage?.size) {
        coverImageToSend = this.coverImage;
      }
      var newImagesToSend = [];
      this.images?.map((image) => {
        if (image.size) newImagesToSend.push(image);
      });

      var fbLinksToSend = [...this.facebookLinks];
      var ybLinksToSend = [...this.youtubeLinks];

      this.news.medias.map((media) => {
        if (media.externalPlatform == "Facebook") {
          var facebookIndex = fbLinksToSend.findIndex(
            (el) => el == media.externalLink
          );
          if (facebookIndex != -1) {
            fbLinksToSend.splice(facebookIndex, 1);
          }
        } else if (media.externalPlatform == "YouTube") {
          var youtubeIndex = ybLinksToSend.findIndex(
            (el) => el == media.externalLink
          );
          if (youtubeIndex != -1) {
            ybLinksToSend.splice(youtubeIndex, 1);
          }
        }
      });

      return {
        title: this.title,
        description: this.description,
        cover_image: coverImageToSend,
        images: newImagesToSend,
        facebook: fbLinksToSend,
        youtube: ybLinksToSend,
        deleted_ids: this.deletedIds,
      };
    },
    submitForm() {
      if (this.$refs.form.validate()) {

        EventBus.$emit("formSubmitted");
        var newVideosToSend = [];
        this.videos.map((video) => {
          if (video.size) newVideosToSend.push(video);
        });
        this.editNews(this.news.id, this.getFormData(), newVideosToSend)
          .then(() => {
            if (!newVideosToSend.length) {
              this.$router.push("/news");
            }
          })
          .catch((err) => console.error(err));
      }
    },
  },
  watch: {
    news: {
      deep: true,
      handler(oldValue, newValue) {
        // We must add this condition because we are doing logic on the news list (remove items from medias)
        // Without this condition for every delete process "initializeMedias()" will be called
        if (oldValue != newValue) {
          this.initializeMedias();
        }
      },
    },
    facebookLinks: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          // we are adding not deleting
          if (newValue.length > oldValue.length) return;
          // else
          oldValue.map((value) => {
            // find deleted item
            if (!newValue.includes(value)) {
              // check if news medias array has this item and delete id
              this.news.medias.map((media, index) => {
                if (media.externalLink == value) {
                  // remove item and add its id to deletedIds array
                  this.deletedIds.push(media.id);
                  this.news.medias.splice(index, 1);
                }
              });
            }
          });
        }
      },
    },
    youtubeLinks: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue != oldValue) {
          // we are adding not deleting
          if (newValue.length > oldValue.length) return;
          // else
          oldValue.map((value) => {
            // find deleted item
            if (!newValue.includes(value)) {
              // check if news medias array has this item and delete id
              this.news.medias.map((media, index) => {
                if (media.externalLink == value) {
                  // remove item and add its id to deletedIds array
                  this.deletedIds.push(media.id);
                  this.news.medias.splice(index, 1);
                }
              });
            }
          });
        }
      },
    },
    $data: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 550px;
}
.image {
  height: auto;
  max-width: 200px;
  border: 2px solid var(--v-primary-base);
}
.delete-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}
.v-chip {
  max-width: 400px;
}
</style>

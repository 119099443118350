import UserLayout from "@/core/layouts/UserLayout.vue";
import JobPublisherPage from "../pages/JobPublisherPage.vue";
import JobPublishersPage from "../pages/JobPublishersPage.vue";

import { READ_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  path: "/job-publishers",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_publishers",
      component: JobPublishersPage,
      meta: {
        title: "ناشري فرص العمل",
        requiredPermission: READ_JOB_PUBLISHERS_PERMISSION,
      },
    },
    {
      path: "/job-publishers/:id",
      component: JobPublisherPage,
      name: "job_publisher",
      meta: {
        title: "تفاصيل ناشر ",
        requiredPermission: READ_JOB_PUBLISHERS_PERMISSION,
      },
    },
  ],
};

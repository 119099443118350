import BaseService from "@/core/classes/base/BaseService";
import {
    platformFeedbackAPI
} from "./PlatformFeedback.api";
import Client from "@/core/classes/client/Client";

export default class PlatformFeedbackService extends BaseService {
  static getPaginatedFeedback(params) {
    return Client.get(platformFeedbackAPI,params).then((res) => Promise.resolve(res));
  }
}
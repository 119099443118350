<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <!-- Title -->
          <BaseTextField
            label="العنوان"
            placeholder="عنوان الخبر"
            persistent-placeholder
            v-model="title"
            :rules="[requiredRule, notEmptyRule]"
          ></BaseTextField>

          <!-- Description -->
          <v-textarea
            label="الشرح"
            placeholder="شرح الخبر"
            persistent-placeholder
            v-model="description"
            outlined
            :rules="[requiredRule, notEmptyRule]"
          ></v-textarea>

          <!-- Youtube links -->
          <BaseCombobox
            outlined
            v-model="youtubeLinks"
            :items="[...youtubeLinks]"
            label="روابط اليوتيوب"
            placeholder="روابط الفيديوهات المنشورة على منصة اليوتيوب، والتي ترغب بعرضها مع الخبر"
            hint="ضع الرابط الذي ترغب بإضافته ثم انقر enter"
            persistent-placeholder
            prepend-inner-icon="mdi-youtube"
            chips
            clearable
            multiple
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-icon class="ml-2" color="primary"> mdi-link </v-icon>
                {{ data.item }}
              </v-chip>
            </template>
          </BaseCombobox>

          <!-- Facebook links -->
          <BaseCombobox
            outlined
            v-model="facebookLinks"
            :items="[...facebookLinks]"
            label="روابط الفيسبوك"
            placeholder="روابط الفيديوهات المنشورة على منصة الفيسبوك، والتي ترغب بعرضها مع الخبر"
            hint="ضع الرابط الذي ترغب بإضافته ثم انقر enter"
            persistent-placeholder
            prepend-inner-icon="mdi-facebook"
            clearable
            chips
            multiple
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-icon class="ml-2" color="primary"> mdi-link </v-icon>
                {{ data.item }}
              </v-chip>
            </template>
          </BaseCombobox>

          <!-- Cover image -->
          <v-file-input
            v-model="coverImage"
            accept="image/png, image/jpeg"
            prepend-inner-icon="mdi-image"
            prepend-icon=""
            label="الصورة الأساسية"
            placeholder="الصورة الأساسية التي ستظهر مع الخبر"
            persistent-placeholder
            outlined
          >
            <template v-slot:selection>
              <div style="position: relative">
                <v-btn
                  @click.stop="removeCoverImage()"
                  fab
                  x-small
                  class="delete-icon"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
                <v-img class="ma-2 rounded image" :src="coverImageUrl"></v-img>
              </div>
            </template>
          </v-file-input>

          <!-- Images -->
          <v-file-input
            v-model="images"
            accept="image/png, image/jpeg"
            prepend-inner-icon="mdi-image"
            prepend-icon=""
            label="الصور"
            placeholder="الصور التي ترغب بعرضها مع الخبر"
            persistent-placeholder
            outlined
            multiple
          >
            <template v-slot:selection="data">
              <div style="position: relative">
                <v-btn
                  @click.stop="removeImage(data.index)"
                  fab
                  x-small
                  class="delete-icon"
                >
                  <v-icon> mdi-close </v-icon>
                </v-btn>
                <v-img
                  class="ma-2 rounded image"
                  :src="imageUrls[data.index]"
                ></v-img>
              </div>
            </template>
          </v-file-input>

          <!-- Videos -->
          <v-file-input
            v-model="videos"
            accept="video/mp4"
            prepend-inner-icon="mdi-video"
            prepend-icon=""
            label="الفيديوهات"
            placeholder="الفيديوهات التي ترغب بعرضها مع الخبر"
            persistent-placeholder
            outlined
            multiple
          >
            <template v-slot:selection="data">
              <v-chip>
                <v-avatar class="ml-2" color="lightSecondary">
                  {{ data.index + 1 }}
                </v-avatar>
                {{ data.text }}
              </v-chip>
            </template>
          </v-file-input>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="submitForm()"
            >
              إضافة الخبر
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

import { EventBus } from "@/main";
import { mapActions } from "pinia";
import { useNewsStore } from "../../store/NewsStore";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      title: "",
      description: "",
      coverImage: null,
      images: [],
      videos: [],
      youtubeLinks: [],
      facebookLinks: [],
    };
  },
  computed: {
    imageUrls() {
      var urls = [];
      this.images.map((image) => urls.push(URL.createObjectURL(image)));
      return urls;
    },
    coverImageUrl() {
      return this.coverImage ? URL.createObjectURL(this.coverImage) : "";
    },
  },
  methods: {
    ...mapActions(useNewsStore, ["createNews"]),
    submitForm() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.createNews(this.getFormData(), this.videos)
          .then(() => {
            if (!this.videos.length) {
              this.$router.push("/news");
            }
          })
          .catch((err) => console.error(err));
      }
    },
    removeCoverImage() {
      this.coverImage = null;
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    getFormData() {
      return {
        title: this.title,
        description: this.description,
        cover_image: this.coverImage,
        images: this.images,
        facebook: this.facebookLinks,
        youtube: this.youtubeLinks,
      };
    },
  },
  watch: {
    $data: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 550px;
}
.image {
  height: auto;
  max-width: 150px;
  border: 2px solid var(--v-primary-base);
}
.delete-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
}
</style>

<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل مشروع </template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <EditProjectForm />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapActions } from "pinia";
import { useProjectStore } from "../store/ProjectStore";
import { useTrainingProgramsStore } from "@/modules/training_programs/store/TrainingProgramsStore";

import EditProjectForm from "../components/forms/EditProjectForm.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "المشاريع المميزة",
          href: "/projects",
          disabled: false,
        },
        {
          text: "تعديل المشروع رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions(useProjectStore, ["fetchProject"]),
    ...mapActions(useTrainingProgramsStore, ["fetchAllTrainingPrograms"]),
  },
  components: { EditProjectForm },
  mounted() {
    this.fetchAllTrainingPrograms();
    this.fetchProject(this.$route.params.id)
      .then(() => {})
      .catch((err) => console.error(err));
  },
};
</script>

<style></style>

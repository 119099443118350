<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">تعديل مجال فرصة عمل</p>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-form ref="form">
              <v-text-field :rules="[requiredRule]" v-model="field" outlined persistent-placeholder label="اسم المجال"
                placeholder="أدخل اسم المجال هنا"></v-text-field>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-btn @click="sendEditRequest()" class="accent rounded-pill px-10">
        تعديل
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useJobFieldStore } from "../../store/JobFieldStore";

import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  props: {
    item: Object,
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      field: this.item.name,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useJobFieldStore, ["editJobField"]),
    sendEditRequest() {
      if (this.$refs.form.validate()) {
        var data = { name: this.field };
        this.editJobField(this.item.id, data);
        this.closeDialog();
      }
    },
  },
};
</script>

<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedTrainingPrograms"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowTrainingProgramButton :item="item" />
      <EditTrainingProgramButton :item="item" />
    </template>
    <template v-slot:[`item.isEnabled`]="{ item }">
      <ToggleProgramActivation v-if="hasPermission(toggleActivationPermission)" :item="item" />
      <v-icon color="primary" v-else-if="item.isEnabled">mdi-check-outline</v-icon>
      <v-icon color="primary" v-else>mdi-close</v-icon>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { WRITE_TPS_PERMISSION } from "@/core/statics/Permissions";
import { useTrainingProgramsStore } from "../store/TrainingProgramsStore";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import ToggleProgramActivation from "./action_buttons/ToggleProgramActivation.vue";
import ShowTrainingProgramButton from "./action_buttons/ShowTrainingProgramButton.vue";
import EditTrainingProgramButton from "./action_buttons/EditTrainingProgramButton.vue";


export default {
  mixins:[PermissionManagerMixin],
  computed: {
    ...mapState(useTrainingProgramsStore, ["paginatedTrainingPrograms"]),
    ...mapWritableState(useTrainingProgramsStore,["searchKey"]),
  },
  data() {
    return {
      //TODO make sure what cols are sortable
      toggleActivationPermission:WRITE_TPS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "الاسم", value: "name" },
        { text: "حالة الإظهار", value: "isEnabled" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchTrainingPrograms()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useTrainingProgramsStore, [
      "fetchTrainingPrograms",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  components: {
    ToggleProgramActivation,
    ShowTrainingProgramButton,
    EditTrainingProgramButton,
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>

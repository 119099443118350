<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName>طلبات تمديد وإغلاق فرصة العمل رقم {{ id }} </template>
      <template v-slot:dataTable>
        <JobStatusChangeRequestsTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import JobStatusChangeRequestsTable from "../components/tables/JobStatusChangeRequestsTable.vue";
  
  export default {
    data() {
      return {
        id: this.$route.params.id,
        links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "فرصة العمل رقم " +this.$route.params.id,
          href: "/job-opportunities/" +this.$route.params.id,
          disabled: false,
        },
        {
          text: "طلبات التمديد والإغلاق",
          disabled: true,
        },
      ],
      };
    },
    components: { JobStatusChangeRequestsTable },
  };
  </script>
  
import { defineStore } from "pinia";
import BaseStore from "@/core/classes/base/BaseStore";
import JobPublishersService from "../services/JobPublishersService";
import JobPublisherKeyTransformations from "../models/JobPublisherKeyTransformations";
import PaginatedJobPublisherCollection from "../models/PaginatedJobPublisherCollection";

export const useJobPublishersStore = defineStore("jobPublishersStore", {
  state: () => ({
    paginatedPublishers: new PaginatedJobPublisherCollection(),
    publishersTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      var sortObj =
        this.publishersTableOptions.sortBy.length != 0
          ? {
              sort_by:
                JobPublisherKeyTransformations[
                  this.publishersTableOptions.sortBy[0]
                ],
              sort_desc: this.publishersTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.publishersTableOptions.page,
        ...sortObj,
        items_per_page: this.publishersTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.publishersTableOptions = options;
      this.fetchPublishers();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedPublishers();
      this.fetchPublishers();
    },
    resetPaginatedPublishers() {
      this.paginatedPublishers = new PaginatedJobPublisherCollection();
    },
    fetchPublishers() {
      return BaseStore.promiseHandler(
        () =>
          JobPublishersService.getJobPublishers(this.getSearchPageParameters),
        (data) => {
          this.paginatedPublishers = new PaginatedJobPublisherCollection(data);
        }
      );
    },
  },
});

<template>
  <v-row justify="space-around">
    <!--Keys items-->
    <v-col cols="12" md="8">
      <p class="secondary--text my-10">
        يمكنك سحب الأسئلة لتغيير ترتيب عرضها في كل خطوة....
      </p>
      <v-tabs
        v-model="currentTab"
        fixed-tabs
        show-arrows
        background-color="transparent"
        centered
      >
        <v-tab class="primary--text" href="#first-step"> الخطوة الأولى </v-tab>
        <v-tab class="primary--text" href="#second-step">
          الخطوة الثانية
        </v-tab>
        <v-tab class="primary--text" href="#third-step"> الخطوة الثالثة </v-tab>
      </v-tabs>
      <v-tabs-items class="transparent" v-model="currentTab">
        <v-tab-item value="first-step">
          <BaseDraggable v-model="firstStepQuestions">
            <v-card
              v-for="(question, index) in firstStepQuestions"
              :key="index"
              class="my-3 py-5 px-5"
            >
              <div class="secondary--text">
                {{ question.label }}
                <label class="my-2 primary--text mx-3">
                  ( ترتيب الحقل في هذه الخطوة:
                  {{ firstStepQuestions[index].order }})
                </label>
              </div>
            </v-card>
          </BaseDraggable>
        </v-tab-item>
        <v-tab-item value="second-step">
          <BaseDraggable v-model="secondStepQuestions">
            <v-card
              v-for="(question, index) in secondStepQuestions"
              :key="index"
              class="my-3 py-5 px-5"
            >
              <div class="secondary--text">
                {{ question.label }}
                <label class="my-2 primary--text mx-3">
                  ( ترتيب الحقل في هذه الخطوة:
                  {{ secondStepQuestions[index].order }})
                </label>
              </div>
            </v-card>
          </BaseDraggable>
        </v-tab-item>
        <v-tab-item value="third-step">
          <BaseDraggable v-model="thirdStepQuestions">
            <v-card
              v-for="(question, index) in thirdStepQuestions"
              :key="index"
              class="my-3 py-5 px-5"
            >
              <div class="secondary--text">
                {{ question.label }}
                <label class="my-2 primary--text mx-3">
                  ( ترتيب الحقل في هذه الخطوة:
                  {{ thirdStepQuestions[index].order }})
                </label>
              </div>
            </v-card>
          </BaseDraggable>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <!--Actions-->
    <v-col cols="12" md="4">
      <v-btn class="my-2 d-block" color="accent" @click="sendNewQuestionSort()">
        حفظ الترتيب</v-btn
      >
      <v-btn class="my-2" color="light" @click="redirectBack()">إلغاء </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { useTrainingProgramQuestionsStore } from "../store/TrainingProgramApplicationQuestionsStore";
import { EventBus } from "@/main";

export default {
  data() {
    return {
      currentTab: null,
      firstStepQuestions: [],
      secondStepQuestions: [],
      thirdStepQuestions: [],
    };
  },
  watch: {
    firstStepQuestions: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
        this.firstStepQuestions.forEach((question, index) => {
          question.order = index + 1;
        });
      },
    },
    secondStepQuestions: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
        this.secondStepQuestions.forEach((question, index) => {
          question.order = index + 1;
        });
      },
    },
    thirdStepQuestions: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
        this.thirdStepQuestions.forEach((question, index) => {
          question.order = index + 1;
        });
      },
    },
  },
  mounted() {
    this.fetchSortedQuestions()
      .then(() => {
        this.firstStepQuestions = this.sortedQuestions.filter(
          (question) => question.stepNumber == 1
        );
        this.secondStepQuestions = this.sortedQuestions.filter(
          (question) => question.stepNumber == 2
        );
        this.thirdStepQuestions = this.sortedQuestions.filter(
          (question) => question.stepNumber == 3
        );
      })
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useTrainingProgramQuestionsStore, [
      "fetchSortedQuestions",
      "sendNewSortedTPQuestions",
    ]),
    redirectBack() {
      EventBus.$emit("formSubmitted");
      this.$router.go(-1);
    },
    sendNewQuestionSort() {
      //this function combines all three steps questions and calculate the total order of each question
      var totalOrderedQuestions = [];
      this.firstStepQuestions.map((question) => {
        totalOrderedQuestions.push(question);
      });
      this.secondStepQuestions.map((question) => {
        totalOrderedQuestions.push({
          ...question,
          order: question.order + this.firstStepQuestionsCount,
        });
      });
      this.thirdStepQuestions.map((question) => {
        totalOrderedQuestions.push({
          ...question,
          order:
            question.order +
            this.firstStepQuestionsCount +
            this.secondStepQuestionsCount,
        });
        this.sendNewSortedTPQuestions(totalOrderedQuestions)
          .then(() => {
            this.redirectBack();
          })
          .catch((err) => console.error(err));
      });
    },
  },
  computed: {
    ...mapState(useTrainingProgramQuestionsStore, ["sortedQuestions"]),
    firstStepQuestionsCount() {
      return this.firstStepQuestions.length;
    },
    secondStepQuestionsCount() {
      return this.secondStepQuestions.length;
    },
    thirdStepQuestionsCount() {
      return this.thirdStepQuestions.length;
    },
  },
};
</script>

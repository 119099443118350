<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <TrainingProgramDetails :trainingProgram="trainingProgram"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditTrainingProgramButton
            v-can:[editPermission]
            :item="trainingProgram"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { WRITE_TPS_PERMISSION } from "@/core/statics/Permissions";
import { useTrainingProgramStore } from "../store/TrainingProgramStore";

import TrainingProgramDetails from "../components/TrainingProgramDetails.vue";
import EditTrainingProgramButton from "../components/action_buttons/EditTrainingProgramButton.vue";

export default {
  mounted() {
    var tpId = this.$route.params.id;
    this.fetchTrainingProgram(tpId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useTrainingProgramStore, ["trainingProgram"]),
  },
  methods: {
    ...mapActions(useTrainingProgramStore, ["fetchTrainingProgram"]),
  },
  data() {
    return {
      fetchingData: true,
      editPermission: WRITE_TPS_PERMISSION,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مسارات المنح التدريبية",
          href: "/training-programs",
          disabled: false,
        },
        {
          text: "تفاصيل المسار التدريبي رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { TrainingProgramDetails, EditTrainingProgramButton },
};
</script>

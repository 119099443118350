import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import JobOpportunitiesService from "../services/JobOpportunitiesService";
import JobStatusChangeRequestKeyTransformations from "../models/key_transformations/JobStatusChangeRequestKeyTransformations";
import PaginatedJobStatusChangeRequestCollection from "../models/paginated_collections/PaginatedJobStatusChangeRequestCollection";

export const useJobStatusChangeRequestsStore = defineStore(
  "jobStatusChangeRequestsStore",
  {
    state: () => ({
      jobId: null,
      paginatedRequests: new PaginatedJobStatusChangeRequestCollection(),
      requestsTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      searchKey: null,
    }),
    getters: {
      getSearchPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.requestsTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  JobStatusChangeRequestKeyTransformations[
                    this.requestsTableOptions.sortBy[0]
                  ],
                sort_desc: this.requestsTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.requestsTableOptions.page,
          ...sortObj,
          items_per_page: this.requestsTableOptions.itemsPerPage,
          search_key: this.searchKey,
        });
      },
    },
    actions: {
      setJobId(id) {
        this.jobId = id;
      },
      setTableOptionsNFetch(options) {
        (this.requestsTableOptions = options), this.fetchRequests();
      },
      setSearchNFetch(searchKey) {
        this.searchKey = searchKey;
        this.resetPaginatedRequests();
        this.fetchRequests();
      },
      resetPaginatedRequests() {
        this.paginatedRequests =
          new PaginatedJobStatusChangeRequestCollection();
      },
      fetchRequests() {
        return BaseStore.promiseHandler(
          () =>
            JobOpportunitiesService.getJobStatusChangeRequests(
              this.jobId,
              this.getSearchPageParameters
            ),
          (data) =>
            (this.paginatedRequests =
              new PaginatedJobStatusChangeRequestCollection(data))
        );
      },
    },
  }
);

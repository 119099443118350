<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> الأخبار </template>
    <template v-slot:tableActions>
      <CreateNewsButton />
    </template>
    <template v-slot:dataTable>
      <NewsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import CreateNewsButton from "../components/action_buttons/CreateNewsButton.vue";
import NewsTable from "../components/NewsTable.vue";

export default {
  components: { CreateNewsButton, NewsTable },
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الأخبار",
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style></style>

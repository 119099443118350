import { defineStore } from "pinia";
import { useTrainingProgramQuestionsStore } from "./TrainingProgramApplicationQuestionsStore";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgramQuestion from "../models/TrainingProgramQuestion";
import TrainingProgramApplicationQuestionsService from "../services/TrainingProgramApplicationQuestionsService";

export const useTrainingProgramQuestionStore = defineStore(
  "trainingProgramQuestionStore",
  {
    state: () => ({
      question: new TrainingProgramQuestion(),
    }),
    getters: {
      allQuestions() {
        const trainingProgramQuestionsStore =
          useTrainingProgramQuestionsStore();
        return trainingProgramQuestionsStore.paginatedQuestions.collection;
      },
    },
    actions: {
      fetchQuestion(id) {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.getTPApplicationQuestion(
              id
            ),
          (data) => {
            this.question = new TrainingProgramQuestion(data.data);
          }
        );
      },
      deleteQuestion(id) {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.deleteTPApplicationQuestion(
              id
            ),
          () => {
            //remove job from paginated collection
            this.allQuestions.splice(
              this.allQuestions.findIndex((q) => q.id === id),
              1
            );
          }
        );
      },
      toggleQuestion(id) {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationQuestionsService.toggleTPApplicationQuestionActivation(
              id
            ),
          () => {
            //edit question activation from paginated collection
            this.allQuestions.map((q) => {
              if (q.id == id) {
                q.isActivated = !q.isActivated;
              }
            });
          }
        );
      },
      createQuestion(data) {
        return BaseStore.promiseHandler(() =>
          TrainingProgramApplicationQuestionsService.createTPApplicationQuestion(
            data
          )
        );
      },
      editQuestion(id, data) {
        return BaseStore.promiseHandler(() =>
          TrainingProgramApplicationQuestionsService.editTPApplicationQuestion(
            id,
            data
          )
        );
      },
    },
  }
);

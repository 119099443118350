<script>
import { utils, writeFile } from "xlsx";

export default {
  methods: {
    downloadExportedXlsxFile(dataToExport,resourceExportName) {
      const data = utils.json_to_sheet(dataToExport);
      const workBook = utils.book_new();
      utils.book_append_sheet(workBook, data, "data");
      const fileName = `${resourceExportName}_(${this.getCurrentTimeStamp()})`;
      const fileNameWithExtension = fileName + ".xlsx";
      writeFile(workBook, fileNameWithExtension);
    },
    getCurrentTimeStamp() {
      return new Date().toLocaleString(undefined, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>
</script>
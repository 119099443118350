import Trainer from "./Trainer";
import keysTransformations from "./TrainerResumeKeyTransformations";

export default class TrainerResume{
    constructor(data){
        this.id=data?.id;
        this.trainer=new Trainer(data?.user);
        this.trainingField=data?.training_field;
        this.resume=process.env.VUE_APP_BASE_URL +data?.path;
        this.createdAt=data?.created_at;
    }

    static transformKeysForSend(obj) {
        var transformedObj = {};
        for (let key in obj) {
          transformedObj[keysTransformations[key]] = obj[key];
        }
        return transformedObj;
      }
}
export default class TrainingProgramSuggestion{
    constructor(data){
        this.id=data?.id;
        this.relatedProfession=data?.related_profession;
        this.description=data?.description;
        this.suggesterName=data?.suggester_name;
        this.suggesterNumber=data?.suggester_number;
        this.suggesterStudyField=data?.suggester_study_field;
        this.createdAt=data?.created_at;
    }
}
<template>
    <BaseTableItemAction
      v-can:[editPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-pencil"
      actionName="تعديل"
      :to="'/training-program-application-questions/' + item.id + '/edit'"
    >
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import {WRITE_TP_APPLICATION_QUESTIONS_PERMISSION } from '@/core/statics/Permissions';
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return{
        editPermission:WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
      }
    },
  };
  </script>
  
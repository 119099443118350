<template>
  <div>
    <v-btn @click="openExportDialog" color="primary">
      <v-icon class="ml-3">mdi-export</v-icon>
      تصدير الطلبات
    </v-btn>
    <ExportApplicationsDialog
      :showCondition="showExportDialog"
      @closed="closeExportDialog"
    />
  </div>
</template>
<script>
import ExportApplicationsDialog from "../dialogs/ExportApplicationsDialog.vue";

export default {

  data() {
    return {
      showExportDialog: false,
    };
  },
  methods: {
    openExportDialog() {
      this.showExportDialog = true;
    },
    closeExportDialog() {
      this.showExportDialog = false;
    },
  },
  components: { ExportApplicationsDialog },
};
</script>

<template>
  <v-switch
    v-can:[togglePermission]
    :input-value="item.isEnabled"
    inset
    readonly
    @click="toggleActivation()"
  ></v-switch>
</template>

<script>
import { mapActions } from "pinia";
import { WRITE_TPS_PERMISSION } from "@/core/statics/Permissions";
import { useTrainingProgramStore } from "../../store/TrainingProgramStore";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      tp: this.item,
      togglePermission: WRITE_TPS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useTrainingProgramStore, ["toggleTrainingProgram"]),
    toggleActivation() {
      // toggle object enable value
      this.tp.isEnabled = this.tp.isEnabled == 0 ? 1 : 0;
      // send editing request with new enable status
      this.toggleTrainingProgram(this.item.id, this.tp)
        .then()
        .catch((err) => console.error(err));
    },
  },
};
</script>

<template>
  <BaseTableItemAction
    v-can:[deletePermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-pencil"
    actionName="تعديل"
    @click="openEditDialog()"
  >
    <BaseCATQuestionFormDialog
      :showCondition="showEditDialog"
      :questionObject="question"
      formActionName="تعديل السؤال"
      @closed="closeEditDialog"
      @questionSubmitted="
        (submittedQuestion) => sendQuestionData(submittedQuestion)
      "
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { mapActions } from "pinia";
import { useCareerAptitudeTestQuestionStore } from "../../store/CareerAptitudeTestQuestionStore";
import { WRITE_CAREER_TEST_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";

import BaseCATQuestionFormDialog from "../dialogs/BaseCATQuestionFormDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      deletePermission: WRITE_CAREER_TEST_QUESTIONS_PERMISSION,
      showEditDialog: false,
      question: this.item,
    };
  },
  methods: {
    ...mapActions(useCareerAptitudeTestQuestionStore, ["editQuestion"]),
    sendQuestionData(questionData) {
      this.editQuestion(this.item.id, questionData)
        .then(() => {
          this.closeEditDialog();
        })
        .catch();
    },
    openEditDialog() {
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
  },
  components: { BaseCATQuestionFormDialog },
};
</script>

<style></style>

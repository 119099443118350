<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedPublishers"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowJobPublisherButton :item="item" />
    </template>
    <template v-slot:[`item.isRestricted`]="{ item }">
      <TogglePublisherBlockStatus
        v-if="hasPermission(toggleBlockingPermission)"
        :item="item"
      />
      <label  v-else-if="item.isRestricted">
        محظور
        <v-icon color="primary">mdi-cancel</v-icon>
      </label>
      <label  v-else>
        غير محظور  
        <v-icon color="primary">mdi-check</v-icon>
      </label>
  
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobPublishersStore } from "../store/JobPublishersStore";
import { WRITE_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import ShowJobPublisherButton from "./action_buttons/ShowJobPublisherButton.vue";
import TogglePublisherBlockStatus from "./action_buttons/TogglePublisherBlockStatus.vue";

export default {
  mixins: [PermissionManagerMixin],
  computed: {
    ...mapState(useJobPublishersStore, ["paginatedPublishers"]),
    ...mapWritableState(useJobPublishersStore,["searchKey"]),
  },
  data() {
    return {
      toggleBlockingPermission: WRITE_JOB_PUBLISHERS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "الاسم", value: "name" },
        { text: "المجال", value: "field" },
        { text: "البريد الالكتروني", value: "email" },
        { text: "تاريخ الانضمام", value: "createdAt" },
        { text: "حالة الحظر", value: "isRestricted" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchPublishers()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useJobPublishersStore, [
      "fetchPublishers",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  components: { TogglePublisherBlockStatus, ShowJobPublisherButton },
  destroyed(){
    this.searchKey=null;
  },
};
</script>

import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import CareerAptitudeTestQuestionsService from "../services/CareerAptitudeTestQuestionsService";
import CareerAptitudeTestQuestionKeyTransformations from "../models/CareerAptitudeTestQuestionKeyTransformations";
import PaginatedCareerAptitudeTestQuestionsCollection from "../models/PaginatedTrainingProgramQuestionCollection";

export const useCareerAptitudeTestQuestionsStore = defineStore(
  "careerAptitudeTestQuestionsStore",
  {
    state: () => ({
      paginatedQuestions: new PaginatedCareerAptitudeTestQuestionsCollection(),
      questionTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      searchKey: null,
    }),
    getters: {
      getSearchPageParameters() {
        var sortObj =
          this.questionTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  CareerAptitudeTestQuestionKeyTransformations[
                    this.questionTableOptions.sortBy[0]
                  ],
                sort_desc: this.questionTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.questionTableOptions.page,
          ...sortObj,
          items_per_page: this.questionTableOptions.itemsPerPage,
          search_key: this.searchKey,
        });
      },
    },
    actions: {
      setTableOptionsNFetch(options) {
        this.questionTableOptions = options;
        this.fetchQuestions();
      },
      setSearchNFetch(searchKey) {
        this.searchKey = searchKey;
        this.resetPaginatedQuestions();
        this.fetchQuestions();
      },
      resetPaginatedQuestions() {
        this.paginatedQuestions =
          new PaginatedCareerAptitudeTestQuestionsCollection();
      },
      fetchQuestions() {
        return BaseStore.promiseHandler(
          () =>
            CareerAptitudeTestQuestionsService.getCareerAptitudeQuestions(
              this.getSearchPageParameters
            ),
          (data) => {
            this.paginatedQuestions =
              new PaginatedCareerAptitudeTestQuestionsCollection(data);
          }
        );
      },
    },
  }
);

<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">حذف السؤال</p>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row justify="center">
          <v-col align="center" cols="9">
            <p>هل أنت متأكد من أنك تريد حذف السؤال؟</p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-row justify="space-around">
        <v-btn @click="sendDeleteRequest()" class="accent rounded-pill px-10">
          نعم
        </v-btn>
        <v-btn @click="closeDialog()" class="lightNeutral rounded-pill px-10"
          >لا</v-btn
        >
      </v-row>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useFAQStore } from "../../store/FAQStore";

export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useFAQStore, ["deleteFAQ"]),
    sendDeleteRequest() {
      this.deleteFAQ(this.item.id)
        .then(() => this.closeDialog())
        .catch(() => {});
    },
  },
};
</script>

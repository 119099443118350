import { defineStore } from "pinia";
import NotificationsService from "../services/NotificationsService";
import PaginatedNotificationCollection from "../models/PaginatedNotificationCollection";

export const useNotificationsStore = defineStore("notificationsStore", {
  state: () => ({
    notifications: new PaginatedNotificationCollection(),
    unreadNotificationCount:0,
  }),
  getters: {},
  actions: {
    fetchNotifications() {
      return new Promise((resolve, reject) => {
        NotificationsService.fetchNotifications({
          page: this.notifications.currentPage,
        })
          .then((res) => {
            let { data, status } = res;
            return NotificationsService.resolveStatusResponse(status, res).then(
              () => {
                this.unreadNotificationCount=data.unread_notification_count;
                this.notifications = new PaginatedNotificationCollection(data);
                return resolve(res);
              }
            );
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    markNotificationAsRead(notificationId) {
      return new Promise((resolve, reject) => {
        NotificationsService.readNotification(notificationId)
          .then((res) => {
            let { data, status } = res;
            data;
            return NotificationsService.resolveStatusResponse(status, res).then(
              () => {
                this.updateNotificationReadStatus(notificationId);
                return resolve(res);
              }
            );
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    markAllNotificationsAsRead() {
      return new Promise((resolve, reject) => {
        NotificationsService.markNotificationsAsRead()
          .then((res) => {
            let { data, status } = res;
            data;
            return NotificationsService.resolveStatusResponse(status, res).then(
              () => {
                this.updateAllNotificationReadStatus();
                return resolve(res);
              }
            );
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAllNotificationReadStatus() {
      this.notifications.collection.map((notification) => {
        notification.readAt = new Date().toISOString();
      });
      this.unreadNotificationCount=0;
    },
    updateNotificationReadStatus(notificationId){
      this.notifications.collection.map((notification) => {
        if(notification.id==notificationId)
          notification.readAt = new Date().toISOString();
      });
      this.unreadNotificationCount-=1;
    },
    fetchNextPage() {
      !this.notifications.isLastPage
        ? this.notifications.currentPage++
        : console.warn("no more pages!");
      this.fetchNotifications();
    },
    fetchPreviousPage() {
      this.notifications.currentPage != 1
        ? this.notifications.currentPage--
        : console.warn("no previous pages!");
      this.fetchNotifications();
    },
  },
});

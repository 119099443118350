import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import Admin from "./Admin";

export default class PaginatedAdminCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((admin) => {
      this.collection.push(new Admin(admin));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((admin) => {
      this.collection.push(new Admin(admin));
    });
  }
}

import Media from "@/core/classes/models/Media";

export default class News {
  id;
  title;
  description;
  adderId;
  adderName;
  description;
  createdAt;
  medias = [];
  constructor(data) {
    this.id = data?.id;
    this.title = data?.title;
    this.adderId = data?.adder_id;
    this.description = data?.description;
    this.adderName = data?.added_by?.name;
    this.createdAt = data?.created_at;
    data?.medias?.map((media) => this.medias.push(new Media(media)));
  }
}

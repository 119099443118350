import { defineStore } from "pinia";
import { useJobFieldsStore } from "./JobFieldsStore";

import BaseStore from "@/core/classes/base/BaseStore";
import JobField from "../models/JobField";
import JobFieldsService from "../services/JobFieldsService";

export const useJobFieldStore = defineStore("jobFieldStore", {
  state: () => ({
    field: new JobField(),
  }),
  getters: {
    allJobFields() {
      const jobFieldsStore = useJobFieldsStore();
      return jobFieldsStore.paginatedJobFields.collection;
    },
  },
  actions: {
    fetchJob(id) {
      return BaseStore.promiseHandler(
        () => JobFieldsService.getJobField(id),
        (data) => (this.field = new JobField(data.data))
      );
    },
    createJobField(data) {
      return BaseStore.promiseHandler(
        () => JobFieldsService.createJobFieldAPI(data),
        (data) => {
          data;
          // this.allJobFields.unshift(new JobField(data.data)); FIXME: the created job field does not return in the response
        }
      );
    },
    editJobField(id, data) {
      return BaseStore.promiseHandler(
        () => JobFieldsService.editJobField(id, data),
        () => {
          const itemIndex = this.allJobFields.findIndex(
            (field) => field.id == id
          );
          this.allJobFields[itemIndex].name = data.name;
        }
      );
    },
    deleteJobField(id) {
      return BaseStore.promiseHandler(
        () => JobFieldsService.deleteJobField(id),
        () => {
          this.allJobFields.splice(
            this.allJobFields.findIndex((job) => job.id === id),
            1
          );
        }
      );
    },
  },
});

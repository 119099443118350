import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import JobPublisher from "./JobPublisher";

export default class PaginatedJobPublisherCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((jobPublisher) => {
      this.collection.push(new JobPublisher(jobPublisher));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((jobPublisher) => {
      this.collection.push(new JobPublisher(jobPublisher));
    });
  }
}

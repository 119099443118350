<template>
  <BaseConfirmationDialog
    :showCondition="showCondition"
    title="رفض فرصة عمل"
    confirmationMessage="هل أنت متأكد من رفض هذه الفرصة؟"
    @closed="$emit('closed')"
  >
    <template v-slot:confirmationButton>
      <v-btn @click="confirmAction()" class="accent rounded-pill px-10">
        نعم
      </v-btn>
    </template>
  </BaseConfirmationDialog>
</template>
<script>
import { mapActions } from "pinia";
import { useJobOpportunityStore } from "../../../store/JobOpportunityStore";
import BaseConfirmationDialog from "../BaseConfirmationDialog.vue";
export default {
  props: {
    showCondition: Boolean,
    item: Object,
  },
  components: { BaseConfirmationDialog },
  methods: {
    ...mapActions(useJobOpportunityStore, ["rejectJob"]),
    confirmAction(){
        this.rejectJob(this.item.id).then().catch((err)=>console.error(err))
    }
  },
};
</script>

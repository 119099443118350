<template>
  <BaseFAQForm
    :faqObject="faq"
    formActionName="إنشاء السؤال"
    @faqSubmitted="(submittedFaq) => sendFaqData(submittedFaq)"
  />
</template>

<script>
import { mapActions } from "pinia";
import FAQ from "../../models/FAQ";
import { useFAQStore } from "../../store/FAQStore";
import BaseFAQForm from "./BaseFAQForm.vue";

export default {
  data() {
    return {
      faq: new FAQ(),
    };
  },
  methods: {
    ...mapActions(useFAQStore, ["createFAQ"]),
    sendFaqData(faq) {
      this.createFAQ(faq)
        .then(() => {
          this.$router.push("/faqs");
        })
        .catch();
    },
  },
  components: { BaseFAQForm },
};
</script>

<style scoped></style>

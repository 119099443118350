<template>
    <v-card
      height="200px"
      width="300"
      class="my-5 mx-3 elevation-6"
    >
      <v-container fill-height>
        <v-row align="center" justify="center">
          <v-col align="center" justify="center" style="position: relative">
            <p class="value primary--text">{{ value }}</p>
            <p class="secondary--text">{{ unit }}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
</template>

<script>
export default {
  props: {
    unit: String,
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.value {
  font-size: 50px;
}
.unit {
  margin-top: -30px;
  left: 0;
  right: 0;
  min-width: 20px;
  padding-right: 90px;
  position: absolute;
}
</style>
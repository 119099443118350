import TrainingProgramQuestion from "@/modules/training_program_appilication_questions/models/TrainingProgramQuestion";

export default class TrainingProgramApplicationExportKey{
    constructor(data){
        this.id=data?.id;
        this.label=data?.label;
        this.order=data?.order;
        if(data?.question)
            this.question=new TrainingProgramQuestion(data?.question);
    }
}
import JobPublisherKeyTransformations from "./JobPublisherKeyTransformations";
export default class JobPublisher {
  id;
  name;
  field;
  location;
  logoURL;
  email;
  phoneNumber;
  isRestricted;
  isUserVisible;
  about;
  constructor(data) {
    this.id = data?.id;
    this.name = data?.name;
    this.field = data?.field;
    this.location = data?.location;
    this.logoURL = data?.logo ? process.env.VUE_APP_BASE_URL + data.logo : null;
    this.phoneNumber = data?.phone_number;
    this.email = data?.email;
    this.about = data?.about;
    this.isUserVisible = data?.is_user_visible;
    this.isRestricted = data?.is_restricted;
    this.createdAt=data?.created_at;
  }
  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[JobPublisherKeyTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}

<template>
  <BaseSelect
    :items="allAdvices"
    :value="ids"
    clearable
    multiple
    item-value="id"
    label="النصائح الأخلاقية"
    placeholder="اختر النصائح الأخلاقية التي تود عرضها في هذا اليوم"
    persistent-placeholder
    @change="changeSelectedAdvices($event)"
  >
    <template v-slot:item="{ item }">
      {{ item.id }} - {{ item.content }}
    </template>
    <template v-slot:selection="{ item }">
      <v-chip>{{ item.id }} - {{ item.content }}</v-chip>
    </template>
  </BaseSelect>
</template>

<script>
import { mapState, mapWritableState } from "pinia";
import { useEthicalAdvicesStore } from "../../store/EthicalAdvicesStore";

export default {
  props: {
    ids: Array,
    date: String,
  },
  computed: {
    ...mapState(useEthicalAdvicesStore, ["allAdvices"]),
    ...mapWritableState(useEthicalAdvicesStore, ["schedulingCalendar"]),
  },
  methods: {
    changeSelectedAdvices(advices) {
      this.schedulingCalendar[this.date] = advices;
    },
  },
};
</script>

<style></style>

<template>
    <BaseCountCard :value="jobsStats.jobsTotalCount" unit="فرصة عمل منشورة" />
</template>
<script>
import { useStatisticsStore } from '@/modules/home/store/StatisticsStore';
import { mapState } from 'pinia';
import BaseCountCard from '../../base_statistics_cards/BaseCountCard.vue';

export default{
    components: { BaseCountCard },
    computed:{
        ...mapState(useStatisticsStore,["jobsStats"]),
    }
}
</script>
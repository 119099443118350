<template>
  <div v-can:[createPermission]>
    <v-btn to="/training-programs/create" color="accent"
      ><v-icon>mdi-plus</v-icon> إنشاء مسار جديد
    </v-btn>
  </div>
</template>

<script>
import { WRITE_TPS_PERMISSION } from "@/core/statics/Permissions";
export default {
  data() {
    return {
      createPermission: WRITE_TPS_PERMISSION,
    };
  },
};
</script>

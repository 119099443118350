var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDialog',{attrs:{"showCondition":_vm.show},on:{"closed":function($event){return _vm.closeDialog()}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" تصفية الطلبات "),_c('v-icon',[_vm._v("mdi-filter")])]},proxy:true},{key:"body",fn:function(){return [_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","items":_vm.educationLevelsOptions,"label":"الشهادة العلمية","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.educationLevelFilter),callback:function ($$v) {_vm.educationLevelFilter=$$v},expression:"educationLevelFilter"}}),_c('v-select',{attrs:{"rounded":"","dense":"","chips":"","clearable":"","item-value":"id","items":_vm.trainingPrograms,"label":"البرنامج التدريبي","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.title))])]}}]),model:{value:(_vm.trainingProgramFilter),callback:function ($$v) {_vm.trainingProgramFilter=$$v},expression:"trainingProgramFilter"}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","text":"","rounded":""},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v("إزالة عوامل التصفية")]),_c('v-btn',{attrs:{"color":"accent","rounded":""},on:{"click":function($event){return _vm.setFilterState()}}},[_vm._v("تطبيق عوامل التصفية")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }
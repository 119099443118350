<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> تعديل سؤال  </template>
      <v-container>
        <v-row>
          <v-col v-if="!fetchingData" cols="12">
            <EditTPQuestionForm :question="question" />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import { mapState ,mapActions} from "pinia";
  import EditTPQuestionForm from "../components/forms/EditTPQuestionForm.vue";
  import { useTrainingProgramQuestionStore } from "../store/TrainingProgramApplicationQuestionStore";
  
  export default {
    mounted() {
      var questionId = this.$route.params.id;
      this.fetchQuestion(questionId)
        .then(() => {
          this.fetchingData = false;
        })
        .catch((err) => console.error(err));
    },
    computed: {
      ...mapState(useTrainingProgramQuestionStore, ["question"]),
    },
    methods: {
      ...mapActions(useTrainingProgramQuestionStore, ["fetchQuestion"]),
    },
    data() {
      return {
        fetchingData: true,
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "أسئلة التقدم على منحة تدريبية",
            href: "/training-program-application-questions",
            disabled: false,
          },
          {
            text: "تعديل السؤال رقم " + this.$route.params.id,
            disabled: true,
          },
        ],
      };
    },
    components: { EditTPQuestionForm },
  };
  </script>
  
<template>
  <div>
    <BaseLineChartCard
      :labelsArray="
        getLabelsArray(publishersStats.newPublishersCountPerDuration)
      "
      chartLabel="ناشر فرص عمل جديد"
      :dataArray="getDataArray(publishersStats.newPublishersCountPerDuration)"
    >
      <template v-slot:title> عدد ناشري فرص العمل المنضمين </template>
      <template v-slot:durationSelection>
        <BaseSelect
          :items="durations"
          v-model="publishersStats.newPublishersCountDuration"
          chips
          dense
          placeholder="المدة الزمنية"
          value="value"
        >
          <template v-slot:item="{ item }"> خلال {{ item.label }} </template>
          <template v-slot:selection="{ item }">
            خلال {{ item.label }}
          </template>
        </BaseSelect>
      </template>
    </BaseLineChartCard>
  </div>
</template>

<script>
import { useStatisticsStore } from "@/modules/home/store/StatisticsStore";
import { mapActions, mapWritableState } from "pinia";
import BaseLineChartCard from "../../base_statistics_cards/BaseLineChartCard.vue";
import StatisticsDurations from "../../statics/StatisticsDurations";

export default {
  components: { BaseLineChartCard },
  methods: {
    ...mapActions(useStatisticsStore, [
      "getLabelsArray",
      "getDataArray",
      "fetchPublishersStatistics",
    ]),
  },
  computed: {
    ...mapWritableState(useStatisticsStore, ["publishersStats"]),
  },
  watch: {
    "publishersStats.newPublishersCountDuration": function () {
      this.fetchPublishersStatistics()
        .then()
        .catch((err) => console.error(err));
    },
  },
  data() {
    return {
      durations: StatisticsDurations,
    };
  },
};
</script>

<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> السير الذاتية الخاصة بالمدربين</template>
      <template v-slot:dataTable>
        <TrainerResumesTable />
      </template>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import TrainerResumesTable from "../components/TrainerResumesTable.vue";

  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "السير الذاتية للمدربين",
            disabled: true,
          },
        ],
      };
    },
    components: { TrainerResumesTable },
  };
  </script>
  
import UserLayout from "@/core/layouts/UserLayout.vue";
import { READ_PLATFORM_FEEDBACK_PERMISSION} from "@/core/statics/Permissions";
import PlatformFeedbacksPage from "../pages/PlatformFeedbacksPage";

export default {
  path: "/platform-feedback",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "platform_feedback",
      component: PlatformFeedbacksPage,
      meta: {
        title: "الآراء المرسلة عن المنصة",
        requiredPermission: READ_PLATFORM_FEEDBACK_PERMISSION,
      },
    },
  ],
};

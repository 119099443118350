<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12">
          <JobPublisherDetails :publisher="jobPublisher"
        /></v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useJobPublisherStore } from "../store/JobPublisherStore";
import JobPublisherDetails from "../components/JobPublisherDetails.vue";

export default {
  mounted() {
    var publisherId = this.$route.params.id;
    this.fetchPublisher(publisherId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useJobPublisherStore, ["jobPublisher"]),
  },
  methods: {
    ...mapActions(useJobPublisherStore, ["fetchPublisher"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "ناشري فرص العمل",
          href: "/job-publishers",
          disabled: false,
        },
        {
          text: "تفاصيل الناشر رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { JobPublisherDetails },
};
</script>

<template>
  <BaseTableItemAction
    v-if="item.status=='open'"
    v-can:[closePermission]
    v-on="$listeners"
    v-bind="$attrs"    
    icon="mdi-close"
    actionName="إغلاق الفرصة"
    @click="openJobClosingDialog()"
  >
    <CloseJobDialog
      :showCondition="showCloseDialog"
      :item="item"
      @closed="closeJobClosingDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
import CloseJobDialog from "../../dialogs/job_dialogs/CloseJobConfirmationDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      closePermission:WRITE_JOBS_PERMISSION,
      showCloseDialog: false,
    };
  },
  methods: {
    openJobClosingDialog() {
      this.showCloseDialog = true;
    },
    closeJobClosingDialog() {
      this.showCloseDialog = false;
    },
  },
  components: { CloseJobDialog },
};
</script>

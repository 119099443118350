<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedPublishersFeedback"
    @debouncedSearch="
      (searchKey) => setPublishersFeedbackSearchNFetch(searchKey)
    "
    @optionsChanged="
      (options) => setPublishersFeedbackTableOptionsNFetch(options)
    "
  >
  <template v-slot:[`item.id`]="{ item }">
      <RouterLink :to="'/job-opportunities/' + item.job.id + '/applicants/'+item.id">
        {{ item.id }}
      </RouterLink>
    </template>
    <template v-slot:[`item.rate`]="{ item }">
      <v-rating
        v-if="item.rate != null && item.rate != undefined"
        color="accent"
        length="5"
        readonly
        size="18"
        :value="item.rate"
      ></v-rating>
      <label v-else>لم يتم تحديد تقييم</label>
    </template>
    <template v-slot:[`item.job.title`]="{ item }">
      <RouterLink :to="'/job-opportunities/' + item.job.id">
        {{ item.job.title }}
      </RouterLink>
    </template>
    <template v-slot:[`item.applicant.name`]="{ item }">
      <RouterLink
        v-if="hasPermission(showApplicantPermission)"
        :to="'/users/' + item.applicant.id"
      >
        {{ item.applicant.name }}
      </RouterLink>
      <label v-else> {{ item.applicant.name }}</label>
    </template>
    <template v-slot:[`item.job.publisher.name`]="{ item }">
      <RouterLink
        v-if="hasPermission(showPublisherPermission)"
        :to="'/job-publishers/' + item.job.publisher.id"
      >
        {{ item.job.publisher.name }}
      </RouterLink>
      <label v-else> {{ item.job.publisher.name }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobOpportunitiesFeedbackStore } from "../../store/JobOpportunitiesFeedbackStore";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import {
  READ_USERS_PERMISSION,
  READ_JOB_PUBLISHERS_PERMISSION,
} from "@/core/statics/Permissions";

export default {
  mixins: [PermissionManagerMixin],
  components: {
    RouterLink,
  },
  computed: {
    ...mapState(useJobOpportunitiesFeedbackStore, [
      "paginatedPublishersFeedback",
    ]),
    ...mapWritableState(useJobOpportunitiesFeedbackStore,["publishersFeedbackSearchKey"]),
  },
  data() {
    return {
      showPublisherPermission:READ_JOB_PUBLISHERS_PERMISSION,
      showApplicantPermission:READ_USERS_PERMISSION,
      headers: [
        { text: "رقم طلب التقدم ", align: "start", value: "id" },
        { text: "مسمى فرصة العمل", align: "start", value: "job.title" },
        { text: " ناشر فرصة العمل", value: "job.publisher.name" },
        { text: "المتقدم على فرصة العمل", value: "applicant.name" },
        { text: "محتوى الملاحظة ", value: "rateContent" },
        { text: "تقييم المتقدم", value: "rate" },
        { text: "تاريخ الإرسال", value: "rateAt" },
      ],
    };
  },
  mounted() {
    this.fetchPublishersFeedback();
  },
  methods: {
    ...mapActions(useJobOpportunitiesFeedbackStore, [
      "fetchPublishersFeedback",
      "setPublishersFeedbackSearchNFetch",
      "setPublishersFeedbackTableOptionsNFetch",
    ]),
  },
  destroyed(){
    this.publishersFeedbackSearchKey=null;
  },
};
</script>

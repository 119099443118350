import AdminPage from "../pages/AdminPage.vue";
import AdminsPage from "../pages/AdminsPage.vue";
import EditAdminPage from "../pages/EditAdminPage.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import CreateAdminPage from "../pages/CreateAdminPage.vue";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

import {
  WRITE_ADMINS_PERMISSION,
  READ_ADMINS_PERMISSION,
} from "@/core/statics/Permissions";

export default {
  path: "/admins",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "admins",
      component: AdminsPage,
      meta: {
        title: "مشرفي المنصة",
        requiredPermission: READ_ADMINS_PERMISSION,
      },
    },
    {
      path: "/admins/create",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          component: CreateAdminPage,
          name: "create_admin",
          meta: {
            title: "إضافة مشرف جديد",
            requiredPermission: WRITE_ADMINS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/admins/:id/edit",
      component: BaseFormPageWrapper,
      children: [
        {
          path: "",
          component: EditAdminPage,
          name: "edit_admin",
          meta: {
            title: "تعديل مشرف",
            requiredPermission: WRITE_ADMINS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/admins/:id",
      name: "admin_details",
      component: AdminPage,
      meta: {
        title: "تفاصيل المشرف",
        requiredPermission: READ_ADMINS_PERMISSION,
      },
    },
  ],
};

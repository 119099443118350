<template>
  <v-btn
    v-if="paginatedCollection.collection.length != 0 && !isLoading"
    color="secondary"
    :disabled="paginatedCollection.isLastPage"
    text
    @click="onclick()"
    v-bind="$attrs"
  >
    <v-icon>mdi-arrow-left</v-icon>
    التالي
  </v-btn>
</template>

<script>
import { mapState } from "pinia";
import { useGlobalStore } from "@/store/GlobalStore";
import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";

export default {
  props: {
    paginatedCollection: BasePaginatedCollection,
    onclick: Function,
  },
  computed: {
    ...mapState(useGlobalStore, ["isLoading"]),
  },
};
</script>

<style></style>

<template>
  <v-row>
    <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
      <v-avatar class="lightNeutral elevation-12" size="200">
        <v-img
          v-if="publisher.logoURL != null"
          contain
          :src="publisher.logoURL"
          class="rounded"
        ></v-img>
        <v-icon v-else x-large color="lightSecondary">mdi-domain</v-icon>
      </v-avatar>
    </v-col>
    <v-col cols="12" sm="8" order="2" order-sm="1">
      <div class="my-5">
        <span class="primary--text"> اسم الناشر: </span>
        {{ publisher.name }}
      </div>
      <div class="my-5">
        <span class="primary--text"> مجال الناشر: </span>
        {{ publisher.field }}
      </div>
      <div class="my-5">
        <span class="primary--text"> رقم الهاتف: </span>
        {{ publisher.phoneNumber }}
      </div>
      <div class="my-5">
        <span class="primary--text"> البريد الالكتروني: </span>
        {{ publisher.email }}
      </div>
      <div class="my-5">
        <span class="primary--text"> مكان العمل: </span>
        {{ publisher.location }}
      </div>
      <div class="my-5">
        <span class="primary--text">حول الناشر: </span>
        {{ publisher.about }}
      </div>
      <div class="my-5">
        <span class="primary--text">تاريخ الانضمام : </span>
        {{ publisher.createdAt }}
      </div>
      <v-checkbox
        readonly
        label="معلومات الناشر ظاهرة ضمن إعلاناته"
        :input-value="publisher.isUserVisible"
      ></v-checkbox>
      <v-checkbox
        readonly
        label="حالة الحظر"
        :input-value="publisher.isRestricted"
      ></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    publisher: Object,
  },
};
</script>

<style scoped></style>

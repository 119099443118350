var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('BaseTextField',{attrs:{"label":"نص السؤال","placeholder":"نص السؤال","readonly":_vm.readOnly,"rules":[_vm.requiredRule]},model:{value:(_vm.question.label),callback:function ($$v) {_vm.$set(_vm.question, "label", $$v)},expression:"question.label"}}),_c('BaseSelect',{attrs:{"chips":"","clearable":"","readonly":_vm.readOnly,"item-value":"value","items":_vm.questionTypes,"label":"نمط السؤال","placeholder":"نمط السؤال","rules":[_vm.requiredRule]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.question.type),callback:function ($$v) {_vm.$set(_vm.question, "type", $$v)},expression:"question.type"}}),(_vm.question.type == 'radio' || _vm.question.type == 'selection')?_c('BaseCombobox',{attrs:{"outlined":"","readonly":_vm.readOnly,"items":_vm.question.answers,"label":"الخيارات المحتملة للإجابة","placeholder":"الخيارات المحتملة للإجابة","hint":"ضع عنوان الخيار ثم انقر enter","clearable":"","multiple":"","rules":[_vm.requiredRule]},model:{value:(_vm.question.answers),callback:function ($$v) {_vm.$set(_vm.question, "answers", $$v)},expression:"question.answers"}}):_vm._e(),_c('BaseSelect',{attrs:{"chips":"","clearable":"","readonly":_vm.readOnly,"item-value":"value","items":_vm.stepsOptions,"label":"الخطوة التي سيعرض ضمنها السؤال","placeholder":"الخطوة التي سيعرض ضمنها السؤال","rules":[_vm.requiredRule]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.label))])]}}]),model:{value:(_vm.question.stepNumber),callback:function ($$v) {_vm.$set(_vm.question, "stepNumber", $$v)},expression:"question.stepNumber"}}),_c('BaseTextField',{attrs:{"textarea":"","readonly":_vm.readOnly,"label":"ملاحظة السؤال","placeholder":"ملاحظة السؤال","hint":"تظهر الملاحظة تحت الحقل"},model:{value:(_vm.question.note),callback:function ($$v) {_vm.$set(_vm.question, "note", $$v)},expression:"question.note"}}),_c('BaseTextField',{attrs:{"textarea":"","readonly":_vm.readOnly,"label":"تلميح السؤال","placeholder":"تلميح السؤال","hint":"يظهر التلميح ضمن الحقل"},model:{value:(_vm.question.placeholder),callback:function ($$v) {_vm.$set(_vm.question, "placeholder", $$v)},expression:"question.placeholder"}}),_c('v-checkbox',{attrs:{"readonly":_vm.readOnly,"label":"جعل الإجابة إجبارية"},model:{value:(_vm.question.isRequired),callback:function ($$v) {_vm.$set(_vm.question, "isRequired", $$v)},expression:"question.isRequired"}}),(!_vm.readOnly)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{staticClass:"my-5",attrs:{"color":"accent","x-large":"","rounded":""},on:{"click":function($event){return _vm.emitSubmission()}}},[_vm._v(" "+_vm._s(_vm.formActionName)+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
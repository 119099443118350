<template>
    <div>
      <BaseBarChartCard
        :labelsArray="getLabelsArray(jobsStats.jobsCountPerDuration)"
        chartLabel="عدد فرص العمل"
        :dataArray="getDataArray(jobsStats.jobsCountPerDuration)"
      >
        <template v-slot:title
        >عدد فرص العمل المنشورة</template
        >
        <template v-slot:durationSelection>
        <BaseSelect
          :items="durations"
          v-model="jobsStats.publishedJobsCountDuration"
          chips
          dense
          placeholder="المدة الزمنية"
          value="value"
        >
          <template v-slot:item="{ item }"> خلال {{ item.label }} </template>
          <template v-slot:selection="{ item }">
            خلال {{ item.label }}
          </template>
        </BaseSelect>
      </template>
      </BaseBarChartCard>
    </div>
  </template>
  
  <script>
  import { useStatisticsStore } from "@/modules/home/store/StatisticsStore";
  import { mapActions, mapWritableState } from "pinia";
  import BaseBarChartCard from "../../base_statistics_cards/BaseBarChartCard.vue";
  import StatisticsDurations from "../../statics/StatisticsDurations";
  
  export default {
    components: { BaseBarChartCard },
    methods: {
      ...mapActions(useStatisticsStore, [
        "getLabelsArray",
        "getDataArray",
        "fetchJobsStatistics",
      ]),
    },
    watch: {
      "jobsStats.publishedJobsCountDuration": function () {
        this.fetchJobsStatistics()
          .then()
          .catch((err) => console.error(err));
      },
    },
    computed: {
      ...mapWritableState(useStatisticsStore, ["jobsStats"]),
    },
    data() {
      return {
        durations: StatisticsDurations,
      };
    },
  };
  </script>
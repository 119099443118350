import { defineStore } from "pinia";
import { useJobOpportunitiesStore } from "./JobOpportunitiesStore";

import BaseStore from "@/core/classes/base/BaseStore";
import JobOpportunity from "../models/JobOpportunity";
import JobOpportunitiesService from "../services/JobOpportunitiesService";

export const useJobOpportunityStore = defineStore("jobOpportunityStore", {
  state: () => ({
    job: new JobOpportunity(),
  }),
  getters: {
    allJobOpportunities() {
      const jobOpportunitiesStore = useJobOpportunitiesStore();
      return jobOpportunitiesStore.paginatedJobs.collection;
    },
  },
  actions: {
    fetchJob(id) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.getJobOpportunity(id),
        (data) => (this.job = new JobOpportunity(data.data))
      );
    },
    acceptJob(id) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.acceptJob(id),
        () => this.updateJobStatus(id, "open")
      );
    },
    rejectJob(id) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.rejectJob(id),
        () => this.updateJobStatus(id, "rejected")
      );
    },
    closeJob(id) {
      return BaseStore.promiseHandler(
        () => JobOpportunitiesService.closeJob(id),
        () => this.updateJobStatus(id, "closed")
      );
    },
    updateJobStatus(jobId, newStatus) {
      this.allJobOpportunities.map((job) => {
        if (job.id == jobId) {
          job.status = newStatus;
        }
      });
      this.job.status = newStatus;
    },
  },
});

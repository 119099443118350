import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import TrainingProgramApplication from "./TrainingProgramApplication";

export default class PaginatedTrainingProgramApplicationCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data?.map(application => {
      this.collection.push(new TrainingProgramApplication(application));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(application => {
      this.collection.push(new TrainingProgramApplication(application));
    });
  }
}

import BaseService from "@/core/classes/base/BaseService";
import {
    getExportTrainingProgramApplicationsAPI,
    getTrainingProgramApplicationAPI,
    getTrainingProgramApplicationsAPI,
    getTrainingProgramsAPI,
    getExportKeysAPI,
    updateExportKeysAPI
} from "./TrainingProgramApplications.api";
import Client from "@/core/classes/client/Client";

export default class TrainingProgramApplicationsService extends BaseService {
  static getTrainingPrograms() {
    return Client.get(getTrainingProgramsAPI).then((res) => Promise.resolve(res));
  }
  static getTrainingProgramApplications(params) {
    return Client.get(getTrainingProgramApplicationsAPI,params).then((res) => Promise.resolve(res));
  }
  static getTrainingProgramApplication(id) {
    return Client.get(getTrainingProgramApplicationAPI(id)).then((res) => Promise.resolve(res));
  }
  static getExportTrainingProgramApplications(startDate=null,endDate=null) {
    return Client.get(getExportTrainingProgramApplicationsAPI,{start_date:startDate,end_date:endDate,is_paginated:0}).then((res) => Promise.resolve(res));
  }
  static getExportKeys() {
    return Client.get(getExportKeysAPI).then((res) => Promise.resolve(res));
  }
  static updateExportKeys(data) {
    return Client.put(updateExportKeysAPI,{exportKeys:data}).then((res) => Promise.resolve(res));
  }
}
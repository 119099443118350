<template>
  <BaseAdminForm
    :adminObject="admin"
    formActionName="إضافة المشرف"
    @adminSubmitted="(submittedAdmin) => sendAdminData(submittedAdmin)"
  />
</template>

<script>
import { mapActions } from "pinia";
import { useAdminStore } from "../../store/AdminStore";

import Admin from "../../models/Admin";
import BaseAdminForm from "./BaseAdminForm.vue";

export default {
  data() {
    return {
      admin: new Admin(),
    };
  },
  methods: {
    ...mapActions(useAdminStore, ["createAdmin"]),
    sendAdminData(admin) {
      this.createAdmin(admin)
        .then(() => {
          this.$router.push("/admins");
        })
        .catch();
    },
  },
  components: { BaseAdminForm },
};
</script>

<style scoped></style>

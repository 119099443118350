<template>
    <BaseTableItemAction
      v-can:[showPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-eye"
      actionName="عرض التفاصيل"
      :to="'/job-opportunities/' +jobId+'/applicants/'+ item.id "
    >
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        showPermission: READ_JOBS_PERMISSION,
      };
    },
    computed:{
        jobId(){
            return this.$route.params.id;
        }
    }
  };
  </script>
  
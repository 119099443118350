import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import JobFieldsService from "../services/JobFieldsService";
import JobFieldKeysTransformations from "../models/JobFieldKeysTransformations";
import PaginatedJobFieldCollection from "../models/PaginatedJobFieldCollection";

export const useJobFieldsStore = defineStore("jobFieldsStore", {
  state: () => ({
    paginatedJobFields: new PaginatedJobFieldCollection(),
    jobFieldsTableOption: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getFilterSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.jobFieldsTableOption.sortBy.length != 0
          ? {
            sort_by: JobFieldKeysTransformations[this.jobFieldsTableOption.sortBy[0]],
            sort_desc: this.jobFieldsTableOption.sortDesc[0],
          }
          : null;
      return new Object({
        page: this.jobFieldsTableOption.page,
        ...sortObj,
        items_per_page: this.jobFieldsTableOption.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.jobFieldsTableOption = options;
      this.fetchJobFields();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedJobFields();
      this.fetchJobFields();
    },
    resetPaginatedJobFields() {
      this.paginatedJobFields = new PaginatedJobFieldCollection();
    },
    fetchJobFields() {
      return BaseStore.promiseHandler(
        () =>
          JobFieldsService.getPaginatedJobFields(
            this.getFilterSearchPageParameters
          ),
        (data) => {
          this.paginatedJobFields = new PaginatedJobFieldCollection(data);
        }
      );
    },

  },
});

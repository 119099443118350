import CareerAptitudeTestResult from "./CareerAptitudeTestResult";
import TrainingProgram from "./TrainingProgram";

export default class TrainingProgramApplication{
    constructor(data){
        this.id=data?.id;
        this.trainingProgram=new TrainingProgram(data?.training_program);
        this.trainingProgramName=this.trainingProgram?.title;
        this.content=data?.content;
        this.careerAptitudeTestResult= new CareerAptitudeTestResult(data?.career_aptitude_test_result)
        this.userId=data?.user?.id;
        this.userName=data?.user?.name;
        this.createdAt=data?.created_at;
    }
    formatToXLSXExportObject(){
        return new Object({
            ...this.content,
          });
    }
}
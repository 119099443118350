import keysTransformations from "./UserKeyTransformations";

export default class User {
  id;
  username;
  name;
  email;
  phoneNumber;
  studyField;
  constructor(data) {
    this.id = data?.id;
    this.username=data?.username;
    this.name = data?.name;
    this.email = data?.email;
    this.phoneNumber = data?.phone_number;
    this.studyField = data?.study_field;
    this.createdAt = data?.created_at;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }
}

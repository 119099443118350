export const getNewsAPI = "/news";
export const createNewsAPI = "/news";
export function getNewsDetailsAPI(id) {
  return "/news/" + id;
}
export function editNewsAPI(id) {
  return "/news/" + id;
}
export function deleteNewsAPI(id) {
  return "/news/" + id;
}
export function uploadVideoAPI(id) {
  return "/news/upload-video/" + id;
}

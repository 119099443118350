import { StatisticsType } from "../components/statics/StatisticsTypes";

export default class TPApplicationsStatistics {
  TPAppsCountPerTPPerDuration;
  TPAppsCountPerDuration;
  TPAppsTotalCount;
  //duration of each stat
  TPAppsCountDuration;
  TPAppsCountPerTPDuration;

  constructor(data) {
    if (data) {
      this.TPAppsCountPerTPPerDuration =
        data?.TP_apps_count_per_TP_per_duration;
      this.TPAppsCountPerDuration = data?.TP_apps_count_per_duration;
      this.TPAppsTotalCount = data?.total_tp_apps_count;
      //setting durations
      this.TPAppsCountDuration = data?.TP_apps_count_duration;
      this.TPAppsCountPerTPDuration = data?.TP_apps_count_per_TP_duration;
    } else {
      const localData = JSON.parse(
        localStorage.getItem(StatisticsType.TPsStatistics)
      );
     
      this.TPAppsCountPerTPPerDuration =
        localData?.TP_apps_count_per_TP_per_duration ?? {};
      this.TPAppsCountPerDuration = localData?.TP_apps_count_per_duration ?? {};
      this.TPAppsTotalCount = localData?.total_tp_apps_count ?? null;
      //setting durations
      this.TPAppsCountDuration = localData?.TP_apps_count_duration ?? "6_months";
      this.TPAppsCountPerTPDuration = localData?.TP_apps_count_per_TP_duration ?? "6_months";
    }
  }
}

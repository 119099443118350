import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import TrainerResume from "./TrainerResume";

export default class PaginatedTrainerResumeCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(resume => {
      this.collection.push(new TrainerResume(resume));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(resume => {
      this.collection.push(new TrainerResume(resume));
    });
  }
}

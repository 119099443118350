<template>
  <!--TODO make sure of all action buttons show conditions-->
  <BaseTableItemAction
    v-if="item.status == 'pending'"
    v-can:[acceptPermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-check"
    actionName="قبول نشر الفرصة"
    @click="openAcceptJobDialog()"
  >
    <AcceptJobConfirmationDialog
      :showCondition="showAcceptDialog"
      :item="item"
      @closed="closeAcceptJobDialog()"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
import AcceptJobConfirmationDialog from "../../dialogs/job_dialogs/AcceptJobConfirmationDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      acceptPermission: WRITE_JOBS_PERMISSION,
      showAcceptDialog: false,
    };
  },
  methods: {
    openAcceptJobDialog() {
      this.showAcceptDialog = true;
    },
    closeAcceptJobDialog() {
      this.showAcceptDialog = false;
    },
  },
  components: { AcceptJobConfirmationDialog },
};
</script>

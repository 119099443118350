import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
import { getUsersAPI, getUserAPI } from "./Users.api";

export default class UsersService extends BaseService {
  static getUsers(params) {
    return Client.get(getUsersAPI, params).then((res) => Promise.resolve(res));
  }
  static getUser(id) {
    return Client.get(getUserAPI(id)).then((res) => Promise.resolve(res));
  }
}

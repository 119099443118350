import BaseService from "@/core/classes/base/BaseService";
import {
  toggleTPApplicationQuestionActivationAPI,
  createTPApplicationQuestionAPI,
  deleteTPApplicationQuestionAPI,
  editTPApplicationQuestionAPI,
  getTPApplicationQuestionAPI,
  getTPApplicationQuestionsAPI,
  getSortedTPApplicationQuestionsAPI,
  sendSortedTPApplicationQuestionsAPI,
} from "./TrainingProgramApplicationQuestions.api";
import TrainingProgramQuestion from "../models/TrainingProgramQuestion";
import Client from "@/core/classes/client/Client";

export default class TrainingProgramApplicationQuestionsService extends BaseService {
  static getTPApplicationQuestions(params) {
    return Client.get(getTPApplicationQuestionsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static getSortedTPApplicationQuestions() {
    return Client.get(getSortedTPApplicationQuestionsAPI).then((res) =>
      Promise.resolve(res)
    );
  }
  static sendSortedTPApplicationQuestions(newSortedQuestions) {
    var newFormattedSortedQuestions = [];
    newSortedQuestions.map((question) => {
      newFormattedSortedQuestions.push(
        TrainingProgramQuestion.transformKeysForSend(question)
      );
    });
    return Client.put(sendSortedTPApplicationQuestionsAPI, {
      new_ordered_questions: newFormattedSortedQuestions,
    }).then((res) => Promise.resolve(res));
  }
  static getTPApplicationQuestion(id) {
    return Client.get(getTPApplicationQuestionAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static createTPApplicationQuestion(data) {
    return Client.post(
      createTPApplicationQuestionAPI,
      TrainingProgramQuestion.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static editTPApplicationQuestion(id, data) {
    return Client.put(
      editTPApplicationQuestionAPI(id),
      TrainingProgramQuestion.transformKeysForSend(data)
    ).then((res) => Promise.resolve(res));
  }
  static deleteTPApplicationQuestion(id) {
    return Client.delete(deleteTPApplicationQuestionAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static toggleTPApplicationQuestionActivation(id) {
    return Client.put(toggleTPApplicationQuestionActivationAPI(id), {}).then(
      (res) => Promise.resolve(res)
    );
  }
}

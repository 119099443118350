<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> إدارة فرص العمل </template>
    <template v-slot:tableActions>
      <ShowJobsFeedbackButton class="my-2" />
      <ExportJobsButton  class="mr-5 my-2"/>
      <JobFilter class="mr-5 my-2" />
    </template>
    <template v-slot:dataTable>
      <JobOpportunitiesTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import JobOpportunitiesTable from "../components/tables/JobOpportunitiesTable.vue";
import JobFilter from "../components/JobFilter.vue";
import ExportJobsButton from "../components/action_buttons/job_actions/ExportJobsButton.vue";
import ShowJobsFeedbackButton from "../components/action_buttons/job_actions/ShowJobsFeedbackButton.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          disabled: true,
        },
      ],
    };
  },
  components: { JobOpportunitiesTable, JobFilter, ExportJobsButton, ShowJobsFeedbackButton },
};
</script>

import BaseService from "@/core/classes/base/BaseService";
import {
  createJobFieldAPI,
  getJobFieldAPI,
  getJobFieldsAPI,
  deleteJobFieldAPI,
  editJobFieldAPI,
} from "./JobFields.api";

import Client from "@/core/classes/client/Client";

export default class JobFieldsService extends BaseService {
  static getPaginatedJobFields(params) {
    return Client.get(getJobFieldsAPI, { ...params, is_paginated: 1 }).then((res) => Promise.resolve(res));
  }
  static getJobField(id) {
    return Client.get(getJobFieldAPI(id)).then((res) => Promise.resolve(res));
  }
  static createJobFieldAPI(data) {
    return Client.post(createJobFieldAPI, data).then((res) => Promise.resolve(res));
  }
  static deleteJobField(id) {
    return Client.delete(deleteJobFieldAPI(id), {}).then((res) => Promise.resolve(res));
  }
  static editJobField(id,data) {
    return Client.put(editJobFieldAPI(id), data).then((res) => Promise.resolve(res));
  }
}

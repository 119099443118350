<script>
export default {
  data() {
    return {
      requiredRule: (v) => (v!=null && v!=undefined && String(v).length > 0)|| "هذا الحقل مطلوب",
      codeRule: (v) =>
        !(v && v.length != 6) || "رمز التحقق يجب أن يكون مكوناً من ستة محارف",
      nameRule: (v) =>
        !(v && v.length < 5) || "الاسم يجب أن يتكون من خمسة محارف على الأقل",
      studyFieldRule: (v) =>
        !(v && v.length < 2) || "مجال الدراسة يجب أن يتكون من محرفين على الأقل",
      confirmPasswordRule(password) {
        return (v) => !(v && v != password) || "كلمة السر غير متطابقة";
      },
      passwordRule: (v) =>
        !(v && v.length < 8) ||
        "كلمة السر يجب أن تتكون من ثمانية محارف على الأقل",
      emailRule: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "الرجاء إدخال بريد الكتروني صالح",
      phoneNumberRule: (v) =>
        !v ||
        /^([0-9]\d*\d{7,})$/.test(v) ||
        "رقم التواصل يجب أن يحتوي فقط على ارقام ولا يقل عدد عن سبعة أرقام",
      notEmptyRule: (v) =>
        v.length != 0 || "يجب أن يحتوي هذا الحقل على عنصر واحد على الأقل",
      numberOrNullRule: (v) =>
        Number.isInteger(Number(v)) || !v || "يجب أن يكون الحقل عدداً",
      numberRule: (v) =>
        Number.isInteger(Number(v)) || "يجب أن يكون الحقل عدداً",
    };
  },
};
</script>

<!-- v => (v && v.length <= 100) || this.$i18n.translate('validation.lt.string', {
'attribute': '', 'value': 100 }) -->

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedApplicants},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowApplicantDetailsButton',{attrs:{"item":item}}),_c('PassApplicantButton',{attrs:{"item":item}}),_c('RejectApplicantButton',{attrs:{"item":item}})]}},{key:"item.isOrganizationGraduate",fn:function(ref){
var item = ref.item;
return [(item.isOrganizationGraduate == 1)?_c('v-icon',[_vm._v("mdi-check")]):_c('v-icon',[_vm._v("mdi-close")])]}},{key:"item.jobResume",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.jobResume,"target":"_blank","download":""}},[_c('v-icon',[_vm._v("mdi-file")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.translateStatus(item.status))+" ")]}},{key:"item.applicant.name",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(_vm.showApplicantPermission))?_c('RouterLink',{attrs:{"to":'/users/'+item.applicant.id}},[_vm._v(" "+_vm._s(item.applicant.name)+" ")]):_c('label',[_vm._v(" "+_vm._s(item.applicant.name))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
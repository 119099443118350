<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> مسارات المنح التدريبية </template>
    <template v-slot:tableActions> <CreateTrainingProgramButton /> </template>
    <template v-slot:dataTable>
      <TrainingProgramsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import TrainingProgramsTable from "../components/TrainingProgramsTable.vue";
import CreateTrainingProgramButton from "../components/action_buttons/CreateTrainingProgramButton.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "المنح تدريبية",
          disabled: true,
        },
      ],
    };
  },
  components: { TrainingProgramsTable, CreateTrainingProgramButton },
};
</script>

<template>
  <v-switch
    v-can:[togglePermission]
    :input-value="suggestingStatus"
    label="عرض خيار الاقتراحات للمستخدمين"
    inset
    readonly
    @click="toggleActivation()"
  ></v-switch>
</template>

<script>
import { WRITE_TP_SUGGESTIONS_PERMISSION } from "@/core/statics/Permissions";
import { mapActions, mapState } from "pinia";
import { useTrainingProgramSuggestionsStore } from "../store/TrainingProgramSuggestionsStore";

export default {
  computed: {
    ...mapState(useTrainingProgramSuggestionsStore, ["suggestingStatus"]),
  },
  data() {
    return {
      togglePermission: WRITE_TP_SUGGESTIONS_PERMISSION,
    };
  },
  mounted() {
    this.fetchSuggestingStatus()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useTrainingProgramSuggestionsStore, [
      "toggleSuggestingStatus",
      "fetchSuggestingStatus",
    ]),
    toggleActivation() {
      this.toggleSuggestingStatus()
        .then()
        .catch((err) => console.error(err));
    },
  },
};
</script>

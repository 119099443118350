import { render, staticRenderFns } from "./BaseFAQForm.vue?vue&type=template&id=d92b0ffa&scoped=true&"
import script from "./BaseFAQForm.vue?vue&type=script&lang=js&"
export * from "./BaseFAQForm.vue?vue&type=script&lang=js&"
import style0 from "./BaseFAQForm.vue?vue&type=style&index=0&id=d92b0ffa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d92b0ffa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VForm,VRow})

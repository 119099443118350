<template>
  <v-form ref="form">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="9">
          <BaseTextField
            label="نص السؤال"
            placeholder="نص السؤال"
            v-model="faq.question"
            :rules="[requiredRule]"
          ></BaseTextField>

          <BaseTextArea
            textarea
            label="نص الإجابة"
            placeholder="نص الإجابة"
            v-model="faq.answer"
            :rules="[requiredRule]"
          ></BaseTextArea>
          <BaseCombobox
            v-model="faq.tagName"
            :items="[faq.tagName, ...literalTags]"
            label="التصنيف"
            placeholder="التصنيف"
            hint="ضع عنوان التصنيف الذي يتبع له السؤال ثم انقر enter"
            clearable
            :rules="[requiredRule]"
          >
          </BaseCombobox>

          <v-row align="center" justify="center">
            <v-btn
              class="my-5"
              color="accent"
              x-large
              rounded
              @click="emitSubmission()"
            >
              {{ formActionName }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { EventBus } from "@/main";
import { mapActions, mapState } from "pinia";
import { useFAQsStore } from "../../store/FAQsStore";

export default {
  props: {
    faqObject: Object,
    formActionName: String,
  },
  mounted() {
    this.fetchFAQsTags()
      .then()
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useFAQsStore, ["literalTags"]),
  },
  data() {
    return {
      faq: this.faqObject,
    };
  },
  watch: {
    faqObject: {
      immediate: true,
      handler() {
        this.faq = this.faqObject;
      },
    },
    faq: {
      deep: true,
      handler() {
        EventBus.$emit("formChanged");
      },
    },
  },
  mixins: [FormValidationRulesMixin],
  methods: {
    ...mapActions(useFAQsStore, ["fetchFAQsTags"]),
    emitSubmission() {
      if (this.$refs.form.validate()) {
        EventBus.$emit("formSubmitted");
        this.$emit("faqSubmitted", this.faq);
      }
    },
  },
};
</script>

<style scoped>
.v-text-field {
  max-width: 450px;
}
</style>

<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> أسئلة اختبار الميول المهني </template>
    <template v-slot:tableActions>
      <CreateCATQuestionButton />
    </template>
    <template v-slot:dataTable>
      <CareerAptitudeTestQuestionsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import CareerAptitudeTestQuestionsTable from "../components/CareerAptitudeTestQuestionsTable.vue";
import CreateCATQuestionButton from "../components/action_buttons/CreateCATQuestionButton.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "أسئلة اختبار الميول المهني",
          disabled: true,
        },
      ],
    };
  },
  components: { CareerAptitudeTestQuestionsTable, CreateCATQuestionButton },
};
</script>

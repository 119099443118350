import { defineStore } from "pinia";

import BaseStore from "@/core/classes/base/BaseStore";
import TrainingProgram from "../models/TrainingProgram";
import TrainingProgramApplication from "../models/TrainingProgramApplication";
import TrainingProgramApplicationsService from "../services/TrainingProgramApplicationsService";
import TrainingProgramApplicationKeyTransformations from "../models/TrainingProgramApplicationKeyTransformations";
import PaginatedTrainingProgramApplicationCollection from "../models/PaginatedTrainingProgramApplicationCollection";
import TrainingProgramApplicationExportKey from "../models/TrainingProgramApplicationExportKey";

export const useTrainingProgramApplicationsStore = defineStore(
  "trainingProgramApplicationsStore",
  {
    state: () => ({
      trainingPrograms: [],
      paginatedApplications:
        new PaginatedTrainingProgramApplicationCollection(),
      application: new TrainingProgramApplication(),
      exportApplications: [],
      applicationsTableOptions: {
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 5,
      },
      searchKey: null,
      selectedTrainingProgram: null,
      selectedEducationLevel: null,
      exportStartDate: null,
      exportEndDate: null,
      exportKeys:[],
    }),
    getters: {
      getFilterSearchPageParameters() {
        //prepare sort object in case sort applied (and transform sort cols name)
        var sortObj =
          this.applicationsTableOptions.sortBy.length != 0
            ? {
                sort_by:
                  TrainingProgramApplicationKeyTransformations[
                    this.applicationsTableOptions.sortBy[0]
                  ],
                sort_desc: this.applicationsTableOptions.sortDesc[0],
              }
            : null;
        return new Object({
          page: this.applicationsTableOptions.page,
          ...sortObj,
          items_per_page: this.applicationsTableOptions.itemsPerPage,
          search_key: this.searchKey,
          training_program: this.selectedTrainingProgram,
          education: this.selectedEducationLevel,
        });
      },
      filterApplied() {
        return this.selectedTrainingProgram || this.selectedEducationLevel;
      },
      formattedExportApplications() {
        //TODO make sure of keys
        var formattedApps = [];
        this.exportApplications.map((app) => {
          formattedApps.push(app.formatToXLSXExportObject());
        });
        return formattedApps;
      },
    },
    actions: {
      setFilters(trainingProgramFilter, educationFilter) {
        this.selectedTrainingProgram = trainingProgramFilter;
        this.selectedEducationLevel = educationFilter;
        this.resetPaginatedApplications();
        this.fetchApplications();
      },
      setTableOptionsNFetch(options) {
        this.applicationsTableOptions = options;
        this.fetchApplications();
      },
      setSearchNFetch(searchKey) {
        this.searchKey = searchKey;
        this.resetPaginatedApplications();
        this.fetchApplications();
      },
      resetPaginatedApplications() {
        this.paginatedApplications =
          new PaginatedTrainingProgramApplicationCollection();
      },
      fetchTrainingPrograms() {
        return BaseStore.promiseHandler(
          () => TrainingProgramApplicationsService.getTrainingPrograms(),
          (data) => {
            this.trainingPrograms = []; //reset training programs collection
            data.data.map((tp) => {
              this.trainingPrograms.push(new TrainingProgram(tp));
            });
          }
        );
      },
      fetchApplications() {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationsService.getTrainingProgramApplications(
              this.getFilterSearchPageParameters
            ),
          (data) => {
            this.paginatedApplications =
              new PaginatedTrainingProgramApplicationCollection(data);
          }
        );
      },
      fetchApplication(id) {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationsService.getTrainingProgramApplication(
              id
            ),
          (data) => {
            this.application = new TrainingProgramApplication(data.data);
          }
        );
      },
      fetchExportApplications() {
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationsService.getExportTrainingProgramApplications(
              this.exportStartDate,
              this.exportEndDate
            ),
          (data) => {
            this.exportApplications = []; //reset export application collection
            data.data.map((application) => {
              this.exportApplications.push(
                new TrainingProgramApplication(application)
              );
            });
          }
        );
      },
      fetchExportKeys(){
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationsService.getExportKeys(),
          (data) => {
            this.exportKeys = [];
            data.data.map((key) => {
              this.exportKeys.push(
                new TrainingProgramApplicationExportKey(key)
              );
            });
          }
        );
      },
      updateExportKeys(){
        return BaseStore.promiseHandler(
          () =>
            TrainingProgramApplicationsService.updateExportKeys(this.exportKeys),
          () => {}
        );
      }
    },
  }
);

<template>
    <BaseCRUDPageLayout :links="links">
      <template v-slot:tableName> إنشاء سؤال جديد </template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <CreateTPQuestionForm />
          </v-col>
        </v-row>
      </v-container>
    </BaseCRUDPageLayout>
  </template>
  
  <script>
  import CreateTPQuestionForm from "../components/forms/CreateTPQuestionForm.vue";
  
  export default {
    data() {
      return {
        links: [
          {
            text: "الصفحة الرئيسية",
            href: "/",
            disabled: false,
          },
          {
            text: "أسئلة التقدم على منحة تدريبية",
            href: "/training-program-application-questions",
            disabled: false,
          },
          {
            text: " إنشاء سؤال جديد",
            disabled: true,
          },
        ],
      };
    },
    components: { CreateTPQuestionForm },
  };
  </script>
  
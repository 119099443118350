import { defineStore } from "pinia";
import FAQ from "../models/FAQ";
import BaseStore from "@/core/classes/base/BaseStore";
import FAQsService from "../services/FAQsService";
import { useFAQsStore } from "./FAQsStore";

export const useFAQStore = defineStore("FAQStore", {
  state: () => ({
    faq: new FAQ(),
  }),
  getters: {
    allFAQs() {
      const FAQsStore = useFAQsStore();
      return FAQsStore.paginatedFAQs.collection;
    },
  },
  actions: {
    fetchFAQ(id) {
      return BaseStore.promiseHandler(
        () => FAQsService.getFAQ(id),
        (data) => {
          this.faq = new FAQ(data.data);
        }
      );
    },
    deleteFAQ(id) {
      return BaseStore.promiseHandler(
        () => FAQsService.deleteFAQ(id),
        () => {
          //remove job from paginated collection
          this.allFAQs.splice(
            this.allFAQs.findIndex((faq) => faq.id === id),
            1
          );
        }
      );
    },
    createFAQ(data) {
      return BaseStore.promiseHandler(
        () => FAQsService.createFAQ(data),
        (data) => {
          this.faq = new FAQ(data.data);
        }
      );
    },
    editFAQ(id, data) {
      return BaseStore.promiseHandler(
        () => FAQsService.editFAQ(id, data),
        (data) => {
          this.faq = new FAQ(data.data);
        }
      );
    },
  },
});

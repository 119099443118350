<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">تعديل نصيحة أخلاقية</p>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-form ref="form">
              <!-- TODO: fix rules -->
              <v-textarea
                :rules="[requiredRule]"
                v-model="advice"
                outlined
                persistent-placeholder
                label="النصيحة الأخلاقية"
                placeholder="أدخل نص النصيحة الأخلاقية هنا"
              ></v-textarea>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-btn @click="sendEditRequest()" class="accent rounded-pill px-10">
        تعديل
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions } from "pinia";
import { useEthicalAdviceStore } from "../../store/EthicalAdviceStore";

import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";

export default {
  mixins: [FormValidationRulesMixin],
  props: {
    item: Object,
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      advice: this.item.content,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    ...mapActions(useEthicalAdviceStore, ["editEthicalAdvice"]),
    sendEditRequest() {
      if (this.$refs.form.validate()) {
        var data = { advice: this.advice };
        this.editEthicalAdvice(this.item.id, data);
        // TODO: close before or after??
        this.closeDialog();
      }
    },
  },
};
</script>

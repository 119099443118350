<template>
  <div>
    <v-btn
      v-can:[exportPermission]
      color="primary"
      @click="openExportJobsDialog()"
    >
      <v-icon class="ml-3">mdi-export</v-icon>
      تصدير فرص العمل
    </v-btn>
    <ExportJobsDialog
      :showCondition="showExportDialog"
      @closed="closeExportJobsDialog()"
    />
  </div>
</template>

<script>
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
import ExportJobsDialog from "../../dialogs/job_dialogs/ExportJobsDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      exportPermission: READ_JOBS_PERMISSION,
      showExportDialog: false,
    };
  },
  methods: {
    openExportJobsDialog() {
      this.showExportDialog = true;
    },
    closeExportJobsDialog() {
      this.showExportDialog = false;
    },
  },
  components: { ExportJobsDialog },
};
</script>

import { StatisticsType } from "../components/statics/StatisticsTypes";

export default class UserStatistics {
  newUsersCountPerDuration ;
  usersTotalCount ;
  careerAptitudeTestResultsCountPerDuration;
  platformCreatedResumesTotalCount;
  //duration of each state
  newUsersCountDuration;
  careerAptitudeTestResultsCountDuration;
  constructor(data) {
    if (data) {
      this.newUsersCountPerDuration = data?.new_users_count_per_duration;
      this.usersTotalCount = data?.total_users_count;
      this.careerAptitudeTestResultsCountPerDuration =
        data?.career_aptitude_test_results_count_per_duration;
      this.platformCreatedResumesTotalCount =
        data?.total_platform_created_resumes;
      //setting durations
      this.newUsersCountDuration = data?.new_users_count_duration;
      this.careerAptitudeTestResultsCountDuration =
        data?.career_aptitude_test_results_count_duration;
    } else {
      const localData = JSON.parse(
        localStorage.getItem(StatisticsType.UsersStatistics)
      );
      this.newUsersCountPerDuration = localData?.new_users_count_per_duration ?? {};
      this.usersTotalCount = localData?.total_users_count ?? null;
      this.careerAptitudeTestResultsCountPerDuration =
        localData?.career_aptitude_test_results_count_per_duration ?? {};
      this.platformCreatedResumesTotalCount =
        localData?.total_platform_created_resumes ?? null;
      //setting durations
      this.newUsersCountDuration = localData?.new_users_count_duration ?? "6_months";
      this.careerAptitudeTestResultsCountDuration =
        localData?.career_aptitude_test_results_count_duration ?? "6_months";
    }
  }
}

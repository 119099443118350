<template>
  <BaseTPQuestionForm
    :questionObject="question"
    formActionName="إنشاء السؤال"
    @questionSubmitted="
      (submittedQuestion) => sendQuestionData(submittedQuestion)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import TrainingProgramQuestion from "../../models/TrainingProgramQuestion";
import { useTrainingProgramQuestionStore } from "../../store/TrainingProgramApplicationQuestionStore";
import BaseTPQuestionForm from "./BaseTPQuestionForm.vue";

export default {
  data() {
    return {
      question: new TrainingProgramQuestion(),
    };
  },
  methods: {
    ...mapActions(useTrainingProgramQuestionStore, ["createQuestion"]),
    sendQuestionData(question) {
      this.createQuestion(question)
        .then(() => {
          this.$router.push("/training-program-application-questions");
        })
        .catch();
    },
  },
  components: { BaseTPQuestionForm },
};
</script>

<style scoped></style>

<template>
  <v-card max-width="500" class="my-5 mx-3 elevation-6">
    <v-card-text>
      <h3 class="text-center primary--text">
        <v-container>
          <v-row>
            <v-col cols="12" md="7">
              <slot name="title"></slot>
            </v-col>
            <v-col cols="12" md="5">
              <slot name="durationSelection"></slot>
            </v-col>
          </v-row>
        </v-container>
      </h3>
      <LineChart
        id="my-line-chart-id"
        :chartOptions="chartOptions"
        :chartData="chartData"
        :width="width"
        :height="height"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { Line } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  CategoryScale,
  LinearScale,
  LineElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default {
  components: { LineChart: Line },
  props: {
    labelsArray: Array,
    chartLabel: String,
    dataArray: Array,
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.labelsArray,
        datasets: [
          {
            label: this.chartLabel,
            data: this.dataArray,
            backgroundColor: this.generateColorArray(),
          },
        ],
      };
    },
  },
  methods: {
    generateColorArray() {
      var colors = [];
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      for (var item in this.dataArray) {
        item;
        colors.push("rgb(" + r + "," + g + "," + b + ")");
      }
      return colors;
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped></style>

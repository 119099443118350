<template>
  <div v-can:[createPermission]>
    <v-btn @click="openCreateDialog()" color="accent"
      ><v-icon>mdi-plus</v-icon> إنشاء سؤال جديد
    </v-btn>
    <BaseCATQuestionFormDialog
      formActionName="إنشاء سؤال جديد"
      :showCondition="showCreateDialog"
      :questionObject="question"
      @closed="closeCreateDialog"
      @questionSubmitted="
        (submittedQuestion) => sendQuestionData(submittedQuestion)
      "
    />
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useCareerAptitudeTestQuestionStore } from "../../store/CareerAptitudeTestQuestionStore";
import { WRITE_CAREER_TEST_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";

import CareerAptitudeTestQuestion from "../../models/CareerAptitudeTestQuestion";
import BaseCATQuestionFormDialog from "../dialogs/BaseCATQuestionFormDialog.vue";

export default {
  data() {
    return {
      createPermission: WRITE_CAREER_TEST_QUESTIONS_PERMISSION,
      question: new CareerAptitudeTestQuestion(),
      showCreateDialog: false,
    };
  },
  methods: {
    ...mapActions(useCareerAptitudeTestQuestionStore, ["createQuestion"]),
    sendQuestionData(questionData) {
      this.createQuestion(questionData)
        .then(() => {
          this.closeCreateDialog();
        })
        .catch();
    },
    openCreateDialog() {
      this.showCreateDialog = true;
    },
    closeCreateDialog() {
      this.showCreateDialog = false;
    },
  },
  components: { BaseCATQuestionFormDialog },
};
</script>

<style></style>

<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedApplications"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowApplicationButton :item="item" />
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ item.content['الاسم الثلاثي'] }}
    </template>
    <template v-slot:[`item.phoneNumber`]="{ item }">
      {{ item.content['رقم الجوال'] }}
    </template> 
    <template v-slot:[`item.testResult`]="{ item }">
      {{ item.careerAptitudeTestResult.toString()}} 
    </template> 
    <template v-slot:[`item.userName`]="{ item }">
      <RouterLink v-if="hasPermission(showUserPermission) && item.userName" :to="'/users/'+item.userId">
        {{ item.userName?item.userName:'لا يوجد' }}
      </RouterLink>
      <label v-else>  {{ item.userName?item.userName:'لا يوجد' }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useTrainingProgramApplicationsStore } from "../store/TrainingProgramApplicationsStore";
import ShowApplicationButton from "./action_buttons/ShowApplicationButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_USERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins:[PermissionManagerMixin],
  computed: {
    ...mapState(useTrainingProgramApplicationsStore, ["paginatedApplications"]),
    ...mapWritableState(useTrainingProgramApplicationsStore,["searchKey"]),
  },
  data() {
    return {
      showUserPermission:READ_USERS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "اسم المستخدم",sortable: false, align: "start", value: "userName" },
        { text: "الاسم الثلاثي", value: "name" },
        { text: "رقم الجوال", value: "phoneNumber" },
        { text: "البرنامج التدريبي", value: "trainingProgramName" },
        { text: "نتيجة اختبار الميول المهني", sortable: false, value: "testResult" },
        { text: "تاريخ الإرسال", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  methods: {
    ...mapActions(useTrainingProgramApplicationsStore, [
      "fetchApplications",
      "setTableOptionsNFetch",
      "setSearchNFetch",
    ]),
  },
  mounted() {
    this.fetchApplications()
      .then()
      .catch((err) => console.error(err));
  },
  components: {
    ShowApplicationButton,
    RouterLink
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedApplicantsFeedback},on:{"debouncedSearch":function (searchKey) { return _vm.setApplicantsFeedbackSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setApplicantsFeedbackTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":'/job-opportunities/' + item.job.id + '/applicants/'+item.id}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.job.title",fn:function(ref){
var item = ref.item;
return [_c('RouterLink',{attrs:{"to":'/job-opportunities/' + item.job.id}},[_vm._v(" "+_vm._s(item.job.title)+" ")])]}},{key:"item.applicant.name",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(_vm.showApplicantPermission))?_c('RouterLink',{attrs:{"to":'/users/'+item.applicant.id}},[_vm._v(" "+_vm._s(item.applicant.name)+" ")]):_c('label',[_vm._v(" "+_vm._s(item.applicant.name))])]}},{key:"item.job.publisher.name",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(_vm.showPublisherPermission))?_c('RouterLink',{attrs:{"to":'/job-publishers/'+item.job.publisher.id}},[_vm._v(" "+_vm._s(item.job.publisher.name)+" ")]):_c('label',[_vm._v(" "+_vm._s(item.job.publisher.name))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
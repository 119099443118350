var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedFAQs,"show-expand":true},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('EditFAQButton',{attrs:{"item":item}}),_c('DeleteFAQButton',{attrs:{"item":item}})]}},{key:"expansion",fn:function(ref){
var item = ref.item;
return [_vm._v(" الإجابة:"+_vm._s(item.answer)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tagName)+" ")]}},{key:"item.adderName",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(_vm.showAdminPermission))?_c('RouterLink',{attrs:{"to":'/admins/'+item.adderId}},[_vm._v(" "+_vm._s(item.adderName)+" ")]):_c('label',[_vm._v(" "+_vm._s(item.adderName))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
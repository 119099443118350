export default[
    {
      label:"أسبوع",
      value:"week",
    },
    {
      label:"3 أشهر",
      value:"3_months",
    },
    {
      label:"6 أشهر",
      value:"6_months",
    },
    {
      label:"سنة",
      value:"year",
    },
  ]
<template>
  <v-switch
    v-can:[togglePermission]
    :input-value="item.isRestricted"
    inset
    readonly
    @click="toggleBlocking()"
  ></v-switch>
</template>

<script>
import { mapActions } from "pinia";
import { useJobPublisherStore } from "../../store/JobPublisherStore";
import { WRITE_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      togglePermission: WRITE_JOB_PUBLISHERS_PERMISSION,
    };
  },
  methods: {
    ...mapActions(useJobPublisherStore, ["toggleJobPublisherBlockStatus"]),
    toggleBlocking() {
      this.toggleJobPublisherBlockStatus(this.item.id)
        .then()
        .catch((err) => console.error(err));
    },
  },
};
</script>

<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedQuestions"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowTPApplicationQuestionButton :item="item" />
      <EditTPApplicationQuestionButton :item="item" />
    </template>
    <template v-slot:[`item.type`]="{ item }">
      {{ translateProp(item.type) }}
    </template>
    <template v-slot:[`item.isActivated`]="{ item }">
      <ToggleQuestionActivation v-if="hasPermission(toggleActivationPermission)" :item="item" />
      <v-icon color="primary" v-else-if="item.isActivated">mdi-check-outline</v-icon>
      <v-icon color="primary" v-else>mdi-close</v-icon>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useTrainingProgramQuestionsStore } from "../store/TrainingProgramApplicationQuestionsStore";
import EditTPApplicationQuestionButton from "./action_buttons/EditTPApplicationQuestionButton.vue";
import ShowTPApplicationQuestionButton from "./action_buttons/ShowTPApplicationQuestionButton.vue";
import ToggleQuestionActivation from "./action_buttons/ToggleQuestionActivation.vue";
import TrainingProgramQuestion from "../models/TrainingProgramQuestion";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { WRITE_TP_APPLICATION_QUESTIONS_PERMISSION } from "@/core/statics/Permissions";


export default {
  mixins:[PermissionManagerMixin],
  computed: {
    ...mapState(useTrainingProgramQuestionsStore, ["paginatedQuestions"]),
    ...mapWritableState(useTrainingProgramQuestionsStore,["searchKey"]),
  },
  data() {
    return {
      toggleActivationPermission:WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        { text: "نص السؤال", value: "label" },
        { text: "نمط السؤال", value: "type" },
        { text: "حالة الإظهار", value: "isActivated" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchQuestions()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useTrainingProgramQuestionsStore, [
      "fetchQuestions",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
    translateProp(prop){
      return TrainingProgramQuestion.trans(prop);
    }
  },
  components: {
    EditTPApplicationQuestionButton,
    ShowTPApplicationQuestionButton,
    ToggleQuestionActivation,
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>

import { render, staticRenderFns } from "./ShowTrainingProgramButton.vue?vue&type=template&id=b2a83f1a&"
import script from "./ShowTrainingProgramButton.vue?vue&type=script&lang=js&"
export * from "./ShowTrainingProgramButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
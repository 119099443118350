import { READ_USERS_PERMISSION } from "@/core/statics/Permissions";

import UserLayout from "@/core/layouts/UserLayout.vue";
import UsersPage from "../pages/UsersPage.vue";
import UserPage from "../pages/UserPage.vue";

export default {
  path: "/users",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "users",
      component: UsersPage,
      meta: {
        title: "مستخدمي المنصة",
        requiredPermission: READ_USERS_PERMISSION,
      },
    },
    {
      path: "/users/:id",
      name: "user_details",
      component: UserPage,
      meta: {
        title: "معلومات المستخدم",
        requiredPermission: READ_USERS_PERMISSION,
      },
    },
  ],
};

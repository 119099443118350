<template>
  <BaseTableItemAction
    v-can:[deletePermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-delete"
    actionName="حذف"
    @click="openDeleteDialog()"
  >
    <DeleteJobFieldDialog
      :showCondition="showDeleteDialog"
      :item="item"
      @closed="closeDeleteDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_JOBS_PERMISSION } from "@/core/statics/Permissions";
import DeleteJobFieldDialog from "../dialogs/DeleteJobFieldDialog.vue";

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      deletePermission: WRITE_JOBS_PERMISSION,
      showDeleteDialog: false,
    };
  },
  methods: {
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
  },
  components: { DeleteJobFieldDialog },
};
</script>

<style></style>

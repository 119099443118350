<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      تصدير الطلبات <v-icon>mdi-export</v-icon>
    </template>
    <template v-slot:body>
      <p class="primary--text">
        بإمكانك تحديد تاريخ بداية ونهاية الطلبات اللازم تصديرها أو بإمكانك ترك
        الحقلين فارغين لتصدير جميع الطلبات
      </p>
      <!--Export start date-->
      <v-menu
        ref="menu1"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            label="تاريخ بداية التصدير"
            persistent-hint
            prepend-icon="mdi-calendar"
            clearable
            v-model="exportStartDate"
          ></v-text-field>
        </template>
        <v-date-picker v-model="exportStartDate" no-title></v-date-picker>
      </v-menu>
      <!--Export end date-->
      <v-menu
        ref="menu1"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-bind="attrs"
            v-on="on"
            label="تاريخ نهاية التصدير"
            persistent-hint
            prepend-icon="mdi-calendar"
            clearable
            v-model="exportEndDate"
          ></v-text-field>
        </template>
        <v-date-picker v-model="exportEndDate" no-title></v-date-picker>
      </v-menu>
    </template>
    <template v-slot:actions>
      <v-btn
        class="px-5"
        color="accent"
        rounded
        @click="fetchApplicationsNExport()"
        >تصدير الطلبات</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useTrainingProgramApplicationsStore } from "../../store/TrainingProgramApplicationsStore";
import XlsxFileExportMixin from "@/core/mixins/XlsxFileExportMixin.vue";

export default {
  mixins:[XlsxFileExportMixin],
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
    };
  },
  computed: {
    ...mapWritableState(useTrainingProgramApplicationsStore, [
      "exportEndDate",
      "exportStartDate",
    ]),
    ...mapState(useTrainingProgramApplicationsStore, ["formattedExportApplications"]),
  },
  methods: {
    ...mapActions(useTrainingProgramApplicationsStore, [
      "fetchExportApplications",
    ]),
    closeDialog() {
      this.show = false;
      this.exportStartDate=null;
      this.exportEndDate=null;
      this.$emit("closed");
    },
    fetchApplicationsNExport() {
      this.fetchExportApplications()
        .then(() => {
          this.downloadExportedXlsxFile(this.formattedExportApplications,"طلبات_المنح_التدريبية");//from xlsx mixin
          this.closeDialog();
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

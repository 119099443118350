<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName>
      الملاحظات المرسلة على فرص العمل والمتقدمين
    </template>
    <template v-slot:dataTable>
      <v-tabs
        class="mt-5"
        v-model="tab"
        fixed-tabs
        show-arrows
        background-color="transparent"
        centered
      >
        <v-tab to="#applicants-feedback">
          ملاحظات المتقدمين على فرص العمل
        </v-tab>
        <v-tab to="#publishers-feedback">
          ملاحظات وتقييمات الناشرين على المتقدمين
        </v-tab>
      </v-tabs>

      <v-tabs-items class="transparent" v-model="tab">
        <v-tab-item value="applicants-feedback">
          <ApplicantsFeedbackTable />
        </v-tab-item>
        <v-tab-item value="publishers-feedback">
          <PublishersFeedbackTable />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import ApplicantsFeedbackTable from "../components/tables/ApplicantsFeedbackTable.vue";
import PublishersFeedbackTable from "../components/tables/PublishersFeedbackTable.vue";

export default {
  components: {
    ApplicantsFeedbackTable,
    PublishersFeedbackTable,
  },
  data() {
    return {
      tab: null,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "إدارة فرص العمل",
          href: "/job-opportunities",
          disabled: false,
        },
        {
          text: "الملاحظات المرسلة",
          disabled: true,
        },
      ],
    };
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseDataTable',{attrs:{"headers":_vm.headers,"paginatedItems":_vm.paginatedApplications},on:{"debouncedSearch":function (searchKey) { return _vm.setSearchNFetch(searchKey); },"optionsChanged":function (options) { return _vm.setTableOptionsNFetch(options); }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('ShowApplicationButton',{attrs:{"item":item}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.content['الاسم الثلاثي'])+" ")]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.content['رقم الجوال'])+" ")]}},{key:"item.testResult",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.careerAptitudeTestResult.toString())+" ")]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [(_vm.hasPermission(_vm.showUserPermission) && item.userName)?_c('RouterLink',{attrs:{"to":'/users/'+item.userId}},[_vm._v(" "+_vm._s(item.userName?item.userName:'لا يوجد')+" ")]):_c('label',[_vm._v(" "+_vm._s(item.userName?item.userName:'لا يوجد'))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }
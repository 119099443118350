<template>
    <BaseDataTable
      :headers="headers"
      :paginatedItems="paginatedSuggestions"
      @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
      @optionsChanged="(options) => setTableOptionsNFetch(options)"
    >
      <template v-slot:[`item.info`]="{ item }">
        الاسم:{{ item.suggesterName }} <br>
        رقم التواصل:{{ item.suggesterNumber }} <br>
        مجال الدراسة:{{ item.suggesterStudyField }} <br>
      </template>
    </BaseDataTable>
  </template>
  
  <script>
  import { mapActions, mapState, mapWritableState } from "pinia";
  import { useTrainingProgramSuggestionsStore } from "../store/TrainingProgramSuggestionsStore";
 
  export default {
    computed: {
      ...mapState(useTrainingProgramSuggestionsStore, ["paginatedSuggestions"]),
      ...mapWritableState(useTrainingProgramSuggestionsStore,["searchKey"])
    },
    data() {
      return {
        headers: [
          { text: "الرقم", align: "start", value: "id" },
          { text: "معلومات المقترح", value: "info" },
          { text: "المهنة المتعلقة بالاقتراح", value: "relatedProfession" },
          { text: "توصيف الاقتراح",width:400, value: "description" },
          { text: "تاريخ الإرسال", value: "createdAt" },
        ],
      };
    },
    mounted() {
      this.fetchSuggestions()
        .then()
        .catch((err) => console.error(err));
    },
    methods: {
      ...mapActions(useTrainingProgramSuggestionsStore, [
        "fetchSuggestions",
        "setSearchNFetch",
        "setTableOptionsNFetch",
      ]),
    },
    destroyed(){
    this.searchKey=null;
  },
  };
  </script>
  
<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> النصائح الأخلاقية </template>
    <template v-slot:tableActions>
      <ScheduleEthicalAdvicesButton />
      <CreateEthicalAdviceButton />
    </template>
    <template v-slot:dataTable>
      <EthicalAdvicesTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import EthicalAdvicesTable from "../components/EthicalAdvicesTable.vue";
import CreateEthicalAdviceButton from "../components/action_buttons/CreateEthicalAdviceButton.vue";
import ScheduleEthicalAdvicesButton from "../components/action_buttons/ScheduleEthicalAdvicesButton.vue";

export default {
  components: {
    EthicalAdvicesTable,
    CreateEthicalAdviceButton,
    ScheduleEthicalAdvicesButton,
  },
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "النصائح الأخلاقية",
          disabled: true,
        },
      ],
    };
  },
};
</script>

<style></style>

export default [
  "غير متعلم",
  "ابتدائية",
  "إعدادية",
  "ثانوية(أدبي)",
  "ثانوية(تجاري)",
  "ثانوية(مهني)",
  "ثانوية(فني)",
  "ثانوية(علمي)",
  "م.متوسط",
  "جامعي",
  "دراسات عليا",
];

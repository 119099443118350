<template>
  <BaseTrainingProgramForm
    :trainingProgramObject="trainingProgram"
    formActionName="إنشاء المسار"
    @trainingProgramSubmitted="
      (submittedTB, video) => sendTrainingProgramData(submittedTB, video)
    "
  />
</template>

<script>
import { mapActions } from "pinia";
import { useTrainingProgramStore } from "../../store/TrainingProgramStore";

import TrainingProgram from "../../models/TrainingProgram";
import BaseTrainingProgramForm from "./BaseTrainingProgramForm.vue";

export default {
  data() {
    return {
      trainingProgram: new TrainingProgram(),
    };
  },
  methods: {
    ...mapActions(useTrainingProgramStore, ["createTrainingProgram"]),
    sendTrainingProgramData(tp, video) {
      this.createTrainingProgram(tp, video).then().catch();
    },
  },
  components: { BaseTrainingProgramForm },
};
</script>

<style scoped></style>

export default[
{
    label:'قيد المعالجة',
    value:'pending'
},
{
    label:'مفتوحة',
    value:'open',
},
{
    label:'مغلقة',
    value:'closed'
},
{
    label:'مرفوضة',
    value:'rejected'
}
];
<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <UserDetails :user="user" />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "../store/UserStore";

import UserDetails from "../components/UserDetails.vue";

export default {
  mounted() {
    var userId = this.$route.params.id;
    this.fetchUser(userId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useUserStore, ["user"]),
  },
  methods: {
    ...mapActions(useUserStore, ["fetchUser"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مستخدمي المنصة",
          href: "/users",
          disabled: false,
        },
        {
          text: "معلومات المستخدم رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { UserDetails },
};
</script>

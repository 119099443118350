<template>
    <BaseTPQuestionForm
    :questionObject="question"
    formActionName="تعديل السؤال"
    @questionSubmitted="
      (submittedQuestion) => sendQuestionData(submittedQuestion)
    "
    />
  </template>
  
  <script>
  import { mapActions } from "pinia";
  import { useTrainingProgramQuestionStore } from "../../store/TrainingProgramApplicationQuestionStore";
  import BaseTPQuestionForm from "./BaseTPQuestionForm.vue";
  
  export default {
    props:{
        question:Object
    },
    methods: {
      ...mapActions(useTrainingProgramQuestionStore, ["editQuestion"]),
      sendQuestionData(submittedQuestion) {
        this.editQuestion(this.question.id,submittedQuestion)
          .then(() => {
            this.$router.push("/training-program-application-questions");
          })
          .catch();
      },
    },
    components: { BaseTPQuestionForm },
  };
  </script>
  
  <style scoped></style>
  
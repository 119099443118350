<template>
  <v-app-bar class="border" color="light" height="40" flat fixed>
    <!--drawer button toggler-->
    <v-app-bar-nav-icon @click="toggleDrawer()"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <NotificationMenu />
  </v-app-bar>
</template>

<script>
import { useGlobalStore } from "@/store/GlobalStore";
import { mapActions } from "pinia";
import NotificationMenu from "@/modules/notifications/components/NotificationMenu";

export default {
  components: {
    NotificationMenu,
  },
  methods: {
    ...mapActions(useGlobalStore, ["changeDrawerVisibility"]),
    toggleDrawer() {
      this.changeDrawerVisibility();
    },
  },
};
</script>

<style scoped></style>

import { jobApplicantTranslations } from "./static_enum_translations/JobEnums";

import Applicant from "./Applicant";
import JobOpportunity from "./JobOpportunity";
import keysTransformations from "./key_transformations/ApplicationKeyTransformations";
export default class JobApplication {
  constructor(data) {
    this.id = data?.id;
    this.status = data?.status;
    this.resumeId = data?.resume_id;
    this.rate = data?.rate;
    this.rateContent = data?.rate_content;
    this.rateAt = data?.rate_at;
    this.jobFeedback = data?.job_feedback;
    this.jobFeedbackAt = data?.job_feedback_at;
    this.isOrganizationGraduate = data?.is_organization_graduate;
    this.jobResume = process.env.VUE_APP_BASE_URL + data?.resume?.path;
    this.createdAt = data?.created_at;
    //application's applicant
    this.applicant = new Applicant(data?.user);
    //application's applicant job
    this.job = new JobOpportunity(data?.job);
  }
  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }

  static trans(prop) {
    return jobApplicantTranslations[prop];
  }
}

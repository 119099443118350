import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import TrainingProgramSuggestion from "./TrainingProgramSuggestion";

export default class PaginatedTrainingProgramSuggestionCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(suggestion => {
      this.collection.push(new TrainingProgramSuggestion(suggestion));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(suggestion => {
      this.collection.push(new TrainingProgramSuggestion(suggestion));
    });
  }
}

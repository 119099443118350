import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
import Resumable from "@/core/classes/resumable/Resumable";

import {
  getNewsAPI,
  editNewsAPI,
  deleteNewsAPI,
  getNewsDetailsAPI,
  createNewsAPI,
  uploadVideoAPI,
} from "./News.api";

export default class NewsServices extends BaseService {
  static getNewsDetails(id) {
    return Client.get(getNewsDetailsAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static getPaginatedNews(params) {
    return Client.get(getNewsAPI, params).then((res) => Promise.resolve(res));
  }
  static deletedNews(id) {
    return Client.delete(deleteNewsAPI(id)).then((res) => Promise.resolve(res));
  }
  static createNews(data) {
    return Client.post(createNewsAPI, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static editNews(id, data) {
    return Client.put(editNewsAPI(id), data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    }).then((res) => Promise.resolve(res));
  }
  static uploadVideos(id, data) {
    Resumable.opts.target =process.env.VUE_APP_API_BASE_URL + uploadVideoAPI(id);
    Resumable.addFiles(data);
  }
}

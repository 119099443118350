<template>
  <v-textarea
    v-on="$listeners"
    v-bind="$attrs"
    outlined
    persistent-placeholder
  ></v-textarea>
</template>

<script>
export default {
  name: "BaseTextArea",
};
</script>

<template>
  <div>
    <!-- TODO: replace span with h2?? -->
    <div class="my-5">
      <span class="primary--text">اسم المسار:</span>
      {{ trainingProgram.name }}
    </div>
    <div v-if="trainingProgram.programConditions" class="my-5">
      <span class="primary--text">شروط المسار:</span>
      {{ trainingProgram.programConditions }} 
    </div>
    <div class="my-5">
      <span class="primary--text">حالة المسار:</span>
      {{ trainingProgram.isEnabled == 0 ? "غير فعال" : "فعال" }}
    </div>
    <div class="my-5 text-center primary--text">الفيديو التعريفي</div>
    <v-row justify="center">
      <video class="media" controls>
        <source :src="trainingProgram.introductoryVideoPath" />
      </video>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    trainingProgram: Object,
  },
};
</script>

<style scoped>
.media {
  border: 4px solid var(--v-primary-base);
  border-radius: 20px;
  max-width: 1000px;
}
</style>

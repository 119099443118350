//this object lists the JobOpportunity object keys and returns the job keys according to server side
export default {
  id: "id",
  question: "question",
  answer: "answer",
  adderName: "admins.name",
  tagName: "category_name",
  category: "categories.name",
  createdAt: "created_at",
};

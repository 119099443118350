import Notification from "../Notification";

export default class NewJobReopenRequestNotification extends Notification{
    constructor(data){
        super(data);
        this.jobId=data.data.job_id;
        this.jobTitle=data.data.job_title;
    }
    getTitle(){
        return 'طلب تمديد فرصة عمل'
    }
    getBody(){
        return `لقد تم طلب تمديد فرصة العمل ذات الرقم : #${this.jobId} `
    }
    getActionLink(){
        return `/job-opportunities/${this.jobId}/status-change-requests`
    }
}
import UserLayout from "@/core/layouts/UserLayout.vue";
import ProfilePageVue from "../pages/ProfilePage.vue";

export default {
  path: "/profile",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "profile",
      component: ProfilePageVue,
      meta: { title: "الملف الشخصي"}
    }
  ]
};

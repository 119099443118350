import { READ_PROJECTS_PERMISSION } from "@/core/statics/Permissions";
import { WRITE_PROJECTS_PERMISSION } from "@/core/statics/Permissions";

import ProjectPage from "../pages/ProjectPage.vue";
import ProjectsPage from "../pages/ProjectsPage.vue";
import UserLayout from "@/core/layouts/UserLayout.vue";
import EditProjectPage from "../pages/EditProjectPage";
import CreateProjectPage from "../pages/CreateProjectPage";
import BaseFormPageWrapper from "@/core/base/wrappers/BaseFormPageWrapper.vue";

export default {
  path: "/projects",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "projects",
      component: ProjectsPage,
      meta: {
        title: "المشاريع المميزة",
        requiredPermission: READ_PROJECTS_PERMISSION,
      },
    },
    {
      component: BaseFormPageWrapper,
      path: "/projects/create",
      children: [
        {
          path: "",
          name: "create_project",
          component: CreateProjectPage,
          meta: {
            title: "إضافة مشروع",
            requiredPermission: WRITE_PROJECTS_PERMISSION,
          },
        },
      ],
    },
    {
      component: BaseFormPageWrapper,
      path: "/projects/edit/:id",
      children: [
        {
          path: "",
          name: "edit_project",
          component: EditProjectPage,
          meta: {
            title: "تعديل مشروع",
            requiredPermission: WRITE_PROJECTS_PERMISSION,
          },
        },
      ],
    },
    {
      path: "/projects/:id",
      name: "project",
      component: ProjectPage,
      meta: {
        title: "تفاصيل مشروع مميز",
        requiredPermission: READ_PROJECTS_PERMISSION,
      },
    },
  ],
};

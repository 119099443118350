<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title> تصفية الطلبات <v-icon>mdi-filter</v-icon></template>
    <template v-slot:body>
      <!--Education options-->
      <v-select
        v-model="educationLevelFilter"
        rounded
        dense
        chips
        clearable
        :items="educationLevelsOptions"
        label="الشهادة العلمية"
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item }}</v-chip>
        </template>
      </v-select>
      <!--Training programs options-->
      <v-select
        v-model="trainingProgramFilter"
        rounded
        dense
        chips
        clearable
        item-value="id"
        :items="trainingPrograms"
        label="البرنامج التدريبي"
        outlined
      >
        <template v-slot:item="{ item }">
          {{ item.title }}
        </template>
        <template v-slot:selection="{ item }">
          <v-chip>{{ item.title }}</v-chip>
        </template>
      </v-select>
    </template>
    <template v-slot:actions>
      <v-btn outlined text rounded @click="resetFilters()"
        >إزالة عوامل التصفية</v-btn
      >
      <v-btn color="accent" rounded @click="setFilterState()"
        >تطبيق عوامل التصفية</v-btn
      >
    </template>
  </BaseDialog>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useTrainingProgramApplicationsStore } from "../../store/TrainingProgramApplicationsStore";
import EducationLevelsOptions from "../statics/EducationLevelOptions";
export default {
  props: {
    showCondition: Boolean,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      educationLevelsOptions: EducationLevelsOptions,
      educationLevelFilter: null,
      trainingProgramFilter: null,
    };
  },
  computed: {
    ...mapState(useTrainingProgramApplicationsStore, ["trainingPrograms","selectedEducationLevel","selectedTrainingProgram"]),
  },
  mounted() {
    this.fetchTrainingPrograms()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useTrainingProgramApplicationsStore, [
      "fetchTrainingPrograms",
      "setFilters",
    ]),
    closeDialog() {
      this.show = false;
      this.mapStoreFilterWithState();
      this.$emit("closed");
    },
    mapStoreFilterWithState(){
      this.educationLevelFilter = this.selectedEducationLevel;
      this.trainingProgramFilter = this.selectedTrainingProgram;
    },
    resetFilters() {
      this.educationLevelFilter = null;
      this.trainingProgramFilter = null;
      this.setFilterState();
    },
    setFilterState() {
      this.setFilters(this.trainingProgramFilter, this.educationLevelFilter);
      this.closeDialog();
    },
  },
};
</script>

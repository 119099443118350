import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import JobStatusChangeRequest from "../JobStatusChangeRequest";

export default class PaginatedJobStatusChangeRequestCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map(req => {
      this.collection.push(new JobStatusChangeRequest(req));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map(req => {
      this.collection.push(new JobStatusChangeRequest(req));
    });
  }
}

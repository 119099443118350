import UserLayout from "@/core/layouts/UserLayout.vue";
import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
import JobFieldsPage from "../pages/JobFieldsPage.vue";


export default {
  path: "/job-fields",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "job_fields",
      component: JobFieldsPage,
      meta: {
        title: "مجالات فرص العمل",
        requiredPermission: READ_JOBS_PERMISSION, // TODO: check the required permission for managing the job fields
      },
    },
  ],
};

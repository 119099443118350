import {
  jobTranslations,
  requiredExperienceLevelCodes,
} from "./static_enum_translations/JobEnums";
import keysTransformations from "./key_transformations/JobKeysTransformations";
import JobPublisher from "./JobPublisher";
import Field from "./Field";

export default class JobOpportunity {
  constructor(data) {
    this.id = data?.id;
    this.title = data?.title;
    this.createdAt = data?.created_at;
    this.applicantsCount = data?.applications_count;
    this.fields = [];
    this.fieldsIds=[];
    data?.fields?.map((field)=>{
      this.fields.push(new Field(field));
      this.fieldsIds.push(field.id);
    });    this.requiredExperience = data?.required_experience_level;
    this.minSalary = data?.salary_min;
    this.maxSalary = data?.salary_max;
    this.workLocationType = data?.work_location_type;
    this.workLocation = data?.work_location;
    this.shiftType = data?.shift_type;
    this.publishedAt = data?.accepted_at;
    //details
    this.workHours = data?.details?.work_hours;
    this.minAge = data?.details?.age_min;
    this.maxAge = data?.details?.age_max;
    this.requiredEducation = data?.details?.certificate;
    this.requiredEducationLevel = data?.details?.certificate_required_level;
    this.requiredMilitaryServiceStatus =
      data?.details?.military_service_termination;
    this.requiredGender = data?.details?.gender;
    this.requiredSkills = data?.details?.skills_required;
    this.extraRequirements = data?.details?.extra_requirements;
    this.responsibilities = data?.details?.responsibilities;
    //settings
    this.status = data?.settings?.status;
    this.isSalaryShown = data ? data.settings?.is_salary_showed : false;
    //publisher
    this.publisher = new JobPublisher(data?.publisher);
    //computed
    this.requiredExperienceLevel =
      requiredExperienceLevelCodes[this.requiredExperience]; //code mapping
    this.ageIsUndetermined = !this.minAge && !this.maxAge;
    this.hasStatusChangeRequests = data?.actions_requests?.length > 0;
    this.publisherName = this.publisher?.name;
    //init closing/renewal reasons
    this.closingReasons = data?.close_requests;
    this.renewalReasons = data?.reopen_requests;
  }

  static transformKeysForSend(obj) {
    var transformedObj = {};
    for (let key in obj) {
      transformedObj[keysTransformations[key]] = obj[key];
    }
    return transformedObj;
  }

  pluckFieldNames(){
    var names=[];
    this.fields.map((field)=>{
      names.push(field.name);
    })
    return names;
  }

  static trans(prop) {
    return jobTranslations[prop];
  }

  formatToXLSXExportObject() {
    var transformedObj = {};
    transformedObj["الرقم"] = this.id;
    transformedObj["المسمى الوظيفي"] = this.title;
    transformedObj["اسم الناشر"] = this.publisherName;
    transformedObj["تاريخ الإنشاء"] = this.createdAt;
    transformedObj["المجال"] = this.pluckFieldNames().toString();
    transformedObj["الخبرة المطلوبة"] = this.requiredExperienceLevel;
    transformedObj["الحد الأدنى للراتب"] = this.minSalary;
    transformedObj["الحد الأعلى للراتب"] = this.maxSalary;
    transformedObj["نوع موقع العمل"] = JobOpportunity.trans(
      this.workLocationType
    );
    transformedObj[" موقع العمل"] = this.workLocation;
    transformedObj["نوع الدوام"] = JobOpportunity.trans(this.shiftType);
    transformedObj["عدد ساعات الدوام"] = this.workHours;
    transformedObj["الحد الأدنى للعمر"] = this.minAge;
    transformedObj["الحد الأعلى للعمر"] = this.maxAge;
    transformedObj["الشهادة المطلوبة"] = this.requiredEducation;
    transformedObj["تفضيل وجود الشهادة"] = JobOpportunity.trans(
      this.requiredEducationLevel
    );
    transformedObj["حالة إنهاء خدمة العلم"] = JobOpportunity.trans(
      this.requiredMilitaryServiceStatus
    );
    transformedObj["الجنس المطلوب"] = JobOpportunity.trans(this.requiredGender);
    transformedObj["المهارات المطلوبة"] = this.requiredSkills.toString();
    transformedObj["المسؤوليات"] = this.responsibilities;
    transformedObj["متطلبات إضافية"] = this.extraRequirements;
    transformedObj["أسباب الإغلاق"] = this.closingReasons.toString();
    transformedObj["أسباب التمديد"] = this.renewalReasons.toString();
    return transformedObj;
  }
}

<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName>تنسيق تصدير طلبات المنح التدريبية </template>

    <v-container v-if="!fetchingData">
      <v-row justify="space-around">
        <!--Keys items-->
        <v-col cols="12" md="6">
          <p class="secondary--text">
            يمكنك تغيير اسم تصدير الحقول عند التصدير، وكما يمكنك سحب الحقول
            لتغيير ترتيب الحقول عند التصدير....
          </p>
          <v-form ref="form">
            <BaseDraggable v-model="exportKeys">
              <ExportKeyItem
                v-for="(exportKey, index) in exportKeys"
                :key="index"
                :index="index"
              ></ExportKeyItem>
            </BaseDraggable>
          </v-form>
        </v-col>
        <!--Actions-->
        <v-col cols="12" md="5">
          <v-btn
            class="my-2 d-block"
            color="accent"
            @click="sendNewExportKeys()"
          >
            حفظ التنسيق</v-btn
          >
          <v-btn class="my-2" color="light" @click="redirectBack()"
            >إلغاء
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import ExportKeyItem from "../components/ExportKeyItem.vue";
import { useTrainingProgramApplicationsStore } from "../store/TrainingProgramApplicationsStore";
import { Notify } from "@/core/classes/notify/Notify";
import { EventBus } from "@/main";

export default {
  watch: {
    exportKeys: {
      deep: true,
      handler() {
        this.exportKeys.forEach((exportKey, index) => {
          EventBus.$emit("formChanged");
          exportKey.order = index + 1;
        });
      },
    },
  },
  mounted() {
    this.fetchExportKeys()
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapWritableState(useTrainingProgramApplicationsStore, ["exportKeys"]),
  },
  methods: {
    ...mapActions(useTrainingProgramApplicationsStore, [
      "fetchExportKeys",
      "updateExportKeys",
    ]),
    redirectBack() {
      EventBus.$emit("formSubmitted");
      this.$router.go(-1);
    },
    sendNewExportKeys() {
      if (this.$refs.form.validate()) {
        this.updateExportKeys()
          .then(() => {
            this.redirectBack();
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        Notify.showMessage("error", "تأكد من تعبئة جميع الحقول");
      }
    },
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "طلبات المنح التدريبية",
          href: "/training-program-applications",
          disabled: false,
        },
        {
          text: "تنسيق تصدير طلبات المنح التدريبية",
          disabled: true,
        },
      ],
    };
  },
  components: { ExportKeyItem },
};
</script>

export default {
  id: "id",
  adderId: "adder_id",
  trainingProgramId: "training_program_id",
  accomplisherName: "accomplisher_name",
  title: "title",
  description: "description",
  accomplishmentDate: "accomplishment_date",
  adderName: "admins.name",
};

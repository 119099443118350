<template>
  <BaseDialog :showCondition="show" @closed="closeDialog()">
    <template v-slot:title>
      <p class="primary--text">{{ formActionName }}</p>
    </template>
    <template v-slot:body>
      <v-container>
        <v-form ref="form">
          <BaseTextArea
            :rules="[requiredRule]"
            v-model="question.question"

            label="نص السؤال"
            placeholder="نص السؤال"
          />
        </v-form>
        <BaseSelect
          :rules="[requiredRule]"
          v-model="question.type"
          :items="types"
          :value="questionObject.type"
          clearable
          persistent-placeholder
          item-value="symbol"
          label="النمط"
          placeholder="اختر النمط الموافق للسؤال"
        >
          <template v-slot:item="{ item }">
            {{ item.symbol }} - {{ item.type }}
          </template>
          <template v-slot:selection="{ item }">
            {{ item.symbol }} - {{ item.type }}
          </template>
        </BaseSelect>
      </v-container>
    </template>
    <template v-slot:actions>
      <v-btn @click="emitSubmission()" class="accent rounded-pill px-10">
        {{ formActionName }}
      </v-btn>
    </template>
  </BaseDialog>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { questionsTypes } from "../statics/QuestionsTypes";

export default {
  mixins: [FormValidationRulesMixin],
  props: {
    showCondition: Boolean,
    questionObject: Object,
    formActionName: String,
  },
  watch: {
    showCondition: {
      immediate: true,
      handler() {
        this.show = this.showCondition;
      },
    },
    questionObject: {
      immediate: true,
      handler() {
        this.question = this.questionObject;
      },
    },
  },
  data() {
    return {
      show: this.showCondition,
      question: this.questionObject,
      types: questionsTypes,
    };
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.$emit("closed");
    },
    emitSubmission() {
      if (this.$refs.form.validate()) {
        this.$emit("questionSubmitted", this.question);
      }
    },
  },
};
</script>

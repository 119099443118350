import { defineStore } from "pinia";
import { useJobPublishersStore } from "./JobPublishersStore";

import JobPublisher from "../models/JobPublisher";
import BaseStore from "@/core/classes/base/BaseStore";
import JobPublishersService from "../services/JobPublishersService";

export const useJobPublisherStore = defineStore("jobPublisherStore", {
  state: () => ({
    jobPublisher: new JobPublisher(),
  }),
  getters: {
    allPublishers() {
      const jobPublishersStore = useJobPublishersStore();
      return jobPublishersStore.paginatedPublishers.collection;
    },
  },
  actions: {
    fetchPublisher(id) {
      return BaseStore.promiseHandler(
        () => JobPublishersService.getJobPublisher(id),
        (data) => {
          this.jobPublisher = new JobPublisher(data.data);
        }
      );
    },
    toggleJobPublisherBlockStatus(id) {
      return BaseStore.promiseHandler(
        () => JobPublishersService.toggleJobPublisherBlockStatus(id),
        () => {
          this.allPublishers.map((publisher) => {
            if (publisher.id == id) {
              publisher.isRestricted = !publisher.isRestricted;
            }
          });
        }
      );
    },
  },
});

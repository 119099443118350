<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedApplicantsFeedback"
    @debouncedSearch="
      (searchKey) => setApplicantsFeedbackSearchNFetch(searchKey)
    "
    @optionsChanged="
      (options) => setApplicantsFeedbackTableOptionsNFetch(options)
    "
  >
    <template v-slot:[`item.id`]="{ item }">
      <RouterLink :to="'/job-opportunities/' + item.job.id + '/applicants/'+item.id">
        {{ item.id }}
      </RouterLink>
    </template>
    <template v-slot:[`item.job.title`]="{ item }">
      <RouterLink :to="'/job-opportunities/' + item.job.id">
        {{ item.job.title }}
      </RouterLink>
    </template>
    <template v-slot:[`item.applicant.name`]="{ item }">
      <RouterLink v-if="hasPermission(showApplicantPermission)" :to="'/users/'+item.applicant.id">
        {{ item.applicant.name }}
      </RouterLink>
      <label v-else>  {{ item.applicant.name }}</label>
    </template>
    <template v-slot:[`item.job.publisher.name`]="{ item }">
      <RouterLink v-if="hasPermission(showPublisherPermission)" :to="'/job-publishers/'+item.job.publisher.id">
        {{ item.job.publisher.name }}
      </RouterLink>
      <label v-else> {{ item.job.publisher.name }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useJobOpportunitiesFeedbackStore } from "../../store/JobOpportunitiesFeedbackStore";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_USERS_PERMISSION,READ_JOB_PUBLISHERS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins:[PermissionManagerMixin],
  components:{
    RouterLink
  },
  computed: {
    ...mapState(useJobOpportunitiesFeedbackStore, [
      "paginatedApplicantsFeedback",
    ]),
    ...mapWritableState(useJobOpportunitiesFeedbackStore,["applicantsFeedbackSearchKey"])
  },
  data() {
    return {
      showPublisherPermission:READ_JOB_PUBLISHERS_PERMISSION,
      showApplicantPermission:READ_USERS_PERMISSION,
      headers: [
        { text: "رقم طلب التقدم ", align: "start", value: "id" },
        { text: "مسمى فرصة العمل", value: "job.title" },
        { text: "اسم ناشر فرصة العمل", value: "job.publisher.name" },
        { text: "المتقدم على فرصة العمل", value: "applicant.name" },
        { text: "محتوى الملاحظة ", value: "jobFeedback" },
        { text: "تاريخ الإرسال", value: "jobFeedbackAt" },
      ],
    };
  },
  mounted() {
    this.fetchApplicantsFeedback();
  },
  methods: {
    ...mapActions(useJobOpportunitiesFeedbackStore, [
      "fetchApplicantsFeedback",
      "setApplicantsFeedbackSearchNFetch",
      "setApplicantsFeedbackTableOptionsNFetch",
    ]),
  },
  destroyed(){
    this.applicantsFeedbackSearchKey=null;
  }
};
</script>

import Media from "@/core/classes/models/Media";

export default class Project {
  id;
  adderId;
  trainingProgramId;
  trainingProgramName;
  title;
  accomplisherName;
  description;
  accomplishmentDate;
  adderName;
  githubLink;
  behanceLink;
  createdAt;
  medias = [];
  constructor(data) {
    this.id = data?.id;
    this.adderId = data?.adder_id;
    this.trainingProgramId = data?.training_program_id;
    this.trainingProgramName= data?.training_program?.title;
    this.title = data?.title;
    this.accomplisherName = data?.accomplisher_name;
    this.description = data?.description;
    this.accomplishmentDate = data?.accomplishment_date;
    this.adderName = data?.added_by?.name;
    this.githubLink = data?.github_link;
    this.behanceLink = data?.behance_link;
    this.createdAt=data?.created_at;
    data?.medias?.map((media) => this.medias.push(new Media(media)));
  }
}

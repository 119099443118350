import { defineStore } from "pinia";

import Tag from "../models/Tag";
import FAQsService from "../services/FAQsService";
import BaseStore from "@/core/classes/base/BaseStore";
import FAQKeyTransformations from "../models/FAQKeyTransformations";
import PaginatedFAQCollection from "../models/PaginatedFAQCollection";

export const useFAQsStore = defineStore("FAQsStore", {
  state: () => ({
    tags: [],
    paginatedFAQs: new PaginatedFAQCollection(),
    faqTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    selectedFAQCategory: null,
  }),
  getters: {
    getFilterSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.faqTableOptions.sortBy.length != 0
          ? {
              sort_by: FAQKeyTransformations[this.faqTableOptions.sortBy[0]],
              sort_desc: this.faqTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.faqTableOptions.page,
        ...sortObj,
        items_per_page: this.faqTableOptions.itemsPerPage,
        category: this.selectedFAQCategory,
        search_key: this.searchKey,
      });
    },
    literalTags() {
      var literalTags = [];
      this.tags.map((tag) => {
        literalTags.push(tag.name);
      });
      return literalTags;
    },
  },
  actions: {
    setFilter(categoryFilter) {
      this.selectedFAQCategory = categoryFilter;
      this.resetPaginatedFAQs();
      this.fetchFAQs();
    },
    setTableOptionsNFetch(options) {
      this.faqTableOptions = options;
      this.fetchFAQs();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedFAQs();
      this.fetchFAQs();
    },
    resetPaginatedFAQs() {
      this.paginatedFAQs = new PaginatedFAQCollection();
    },
    fetchFAQsTags() {
      return BaseStore.promiseHandler(
        () => FAQsService.getFAQsTags(),
        (data) => {
          this.tags = []; //reset tags collection
          data.data.map((tag) => {
            this.tags.push(new Tag(tag));
          });
        }
      );
    },
    fetchFAQs() {
      return BaseStore.promiseHandler(
        () => FAQsService.getPaginatedFAQs(this.getFilterSearchPageParameters),
        (data) => {
          this.paginatedFAQs = new PaginatedFAQCollection(data);
        }
      );
    },
  },
});

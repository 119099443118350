<template>
    <BaseTableItemAction
      v-if="item.hasStatusChangeRequests"
      v-can:[showPermission]
      v-on="$listeners"
      v-bind="$attrs"
      icon="mdi-list-status"
      actionName="عرض أسباب التمديد والإغلاق"
      :to="'/job-opportunities/' + item.id + '/status-change-requests'"
    >
      <slot></slot>
    </BaseTableItemAction>
  </template>
  
  <script>
  import { READ_JOBS_PERMISSION } from "@/core/statics/Permissions";
  
  export default {
    props: {
      item: Object,
    },
    data() {
      return {
        showPermission: READ_JOBS_PERMISSION,
      };
    },
  };
  </script>
  
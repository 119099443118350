import BaseService from "@/core/classes/base/BaseService";
import Client from "@/core/classes/client/Client";
import {
  createCareerAptitudeQuestionAPI,
  getCareerAptitudeQuestionsAPI,
  editCareerAptitudeQuestionAPI,
  deleteCareerAptitudeQuestionAPI,
} from "./CareerAptitudeTestQuestions.api";

export default class CareerAptitudeTestQuestionsService extends BaseService {
  static getCareerAptitudeQuestions(params) {
    return Client.get(getCareerAptitudeQuestionsAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static createCareerAptitudeQuestion(data) {
    return Client.post(createCareerAptitudeQuestionAPI, data).then((res) =>
      Promise.resolve(res)
    );
  }
  static editCareerAptitudeQuestion(id, data) {
    return Client.put(editCareerAptitudeQuestionAPI(id), data).then((res) =>
      Promise.resolve(res)
    );
  }
  static deleteCareerAptitudeQuestion(id) {
    return Client.delete(deleteCareerAptitudeQuestionAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
}

import{
    READ_ADMINS_PERMISSION,READ_CAREER_TEST_QUESTIONS_PERMISSION,READ_ETHICAL_ADVICES_PERMISSION,
    READ_FAQS_PERMISSION,READ_JOBS_PERMISSION,READ_JOB_PUBLISHERS_PERMISSION,READ_NEWS_PERMISSION,
    READ_PLATFORM_FEEDBACK_PERMISSION,READ_PROJECTS_PERMISSION,READ_TPS_PERMISSION,
    READ_TP_APPLICATIONS_PERMISSION,READ_TP_APPLICATION_QUESTIONS_PERMISSION,READ_TP_SUGGESTIONS_PERMISSION,
    READ_TRAINERS_RESUMES_PERMISSION,
    READ_USERS_PERMISSION
}from '@/core/statics/Permissions'
export const platformContentTabs=[
    {
        title:'الأخبار',
        link:'/news',
        requiredPermission:READ_NEWS_PERMISSION
    },
    {
        title:'المشاريع المميزة',
        link:'/projects',
        requiredPermission:READ_PROJECTS_PERMISSION
    },
    {
        title:'النصائح الأخلاقية',
        link:'/ethical-advices',
        requiredPermission:READ_ETHICAL_ADVICES_PERMISSION
    },
    {
        title:'الأسئلة الشائعة',
        link:'/faqs',
        requiredPermission:READ_FAQS_PERMISSION
    },
    {
        title:'آراء المنصة',
        link:'/platform-feedback',
        requiredPermission:READ_PLATFORM_FEEDBACK_PERMISSION
    },
];
export const platformContentTabsPermission=[
    READ_NEWS_PERMISSION,
    READ_PROJECTS_PERMISSION,
    READ_ETHICAL_ADVICES_PERMISSION,
    READ_FAQS_PERMISSION,
    READ_PLATFORM_FEEDBACK_PERMISSION
];
export const trainingProgramsTabs=[
    {
        title:'مسارات المنح التدريبية',
        link:'/training-programs',
        requiredPermission:READ_TPS_PERMISSION
    },
    {
        title:'طلبات التقدم لمنحة تدريبية',
        link:'/training-program-applications',
        requiredPermission:READ_TP_APPLICATIONS_PERMISSION
    },
    {
        title:'أسئلة التقدم على منحة تدريبية',
        link:'/training-program-application-questions',
        requiredPermission:READ_TP_APPLICATION_QUESTIONS_PERMISSION
    },
    {
        title:'اقتراحات المنح التدريبية',
        link:'/training-program-suggestions',
        requiredPermission:READ_TP_SUGGESTIONS_PERMISSION
    },
    {
        title:'السير الذاتية للمدربين',
        link:'/trainer-resumes',
        requiredPermission:READ_TRAINERS_RESUMES_PERMISSION
    },
];
export const trainingProgramsTabsPermissions=[
    READ_TPS_PERMISSION,
    READ_TP_APPLICATIONS_PERMISSION,
    READ_TP_APPLICATION_QUESTIONS_PERMISSION,
    READ_TP_SUGGESTIONS_PERMISSION,
    READ_TRAINERS_RESUMES_PERMISSION
];
export const aptitudeTestTabs=[
    {
        title:'أسئلة اختبار الميول المهني',
        link:'/career-test-questions',
        requiredPermission:READ_CAREER_TEST_QUESTIONS_PERMISSION
    },
];
export const aptitudeTestTabsPermissions=[
    READ_CAREER_TEST_QUESTIONS_PERMISSION
];
export const jobsTabs=[
    {
        title:'إدارة فرص العمل',
        link:'/job-opportunities',
        requiredPermission:READ_JOBS_PERMISSION
    },
    {
        title:'إدارة مجالات فرص العمل',
        link:'/job-fields',
        requiredPermission:READ_JOBS_PERMISSION // TODO: check the required permission for managing the job fields
    },
];
export const jobsTabsPermissions=[
    READ_JOBS_PERMISSION
];
export const usersTabs=[
    {
        title:'مشرفي المنصة',
        link:'/admins',
        requiredPermission:READ_ADMINS_PERMISSION
    },
    {
        title:'ناشري فرص العمل',
        link:'/job-publishers',
        requiredPermission:READ_JOB_PUBLISHERS_PERMISSION
    },
    {
        title:'مستخدمي المنصة',
        link:'/users',
        requiredPermission:READ_USERS_PERMISSION
    },
];
export const usersTabsPermissions=[
    READ_ADMINS_PERMISSION,
    READ_JOB_PUBLISHERS_PERMISSION,
    READ_USERS_PERMISSION
];
   

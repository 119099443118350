<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل سؤال شائع </template>
    <v-container>
      <v-row>
        <v-col v-if="!fetchingData" cols="12">
          <EditFAQForm :faq="faq" />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState ,mapActions} from "pinia";
import EditFAQForm from "../components/forms/EditFAQForm.vue";
import { useFAQStore } from "../store/FAQStore";

export default {
  mounted() {
    var faqId = this.$route.params.id;
    this.fetchFAQ(faqId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useFAQStore, ["faq"]),
  },
  methods: {
    ...mapActions(useFAQStore, ["fetchFAQ"]),
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الأسئلة الشائعة",
          href: "/faqs",
          disabled: false,
        },
        {
          text: "تعديل السؤال رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { EditFAQForm },
};
</script>

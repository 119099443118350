import UserLayout from "@/core/layouts/UserLayout.vue";
import { READ_TRAINERS_RESUMES_PERMISSION} from "@/core/statics/Permissions";
import TrainerResumesPage from "../pages/TrainerResumesPage.vue";

export default {
  path: "/trainer-resumes",
  component: UserLayout,
  children: [
    {
      path: "",
      name: "trainer_resumes",
      component: TrainerResumesPage,
      meta: {
        title: "السير الذاتية للمدربين",
        requiredPermission: READ_TRAINERS_RESUMES_PERMISSION,
      },
    },
  ],
};

import BaseStore from "@/core/classes/base/BaseStore";
import EAKeyTransformations from "../models/EAKeyTransformations";
import EthicalAdvicesService from "../services/EthicalAdvicesServices";
import PaginatedEthicalAdvicesCollection from "../models/PaginatedEthicalAdvicesCollection";

import { defineStore } from "pinia";

export const useEthicalAdvicesStore = defineStore("ethicalAdvicesStore", {
  state: () => ({
    paginatedEthicalAdvices: new PaginatedEthicalAdvicesCollection(),
    ethicalAdvicesTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
    suggestingStatus: true,
    allAdvices: [],
    schedulingCalendar: {},
  }),
  getters: {
    getSearchPageParameters() {
      //prepare sort object in case sort applied (and transform sort cols name)
      var sortObj =
        this.ethicalAdvicesTableOptions.sortBy.length != 0
          ? {
              sort_by:
                EAKeyTransformations[this.ethicalAdvicesTableOptions.sortBy[0]],
              sort_desc: this.ethicalAdvicesTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        is_pagination: 1,
        page: this.ethicalAdvicesTableOptions.page,
        ...sortObj,
        items_per_page: this.ethicalAdvicesTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.ethicalAdvicesTableOptions = options;
      this.fetchEthicalAdvices();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedEthicalAdvices();
      this.fetchEthicalAdvices();
    },
    resetPaginatedEthicalAdvices() {
      this.paginatedEthicalAdvices = new PaginatedEthicalAdvicesCollection();
    },
    fetchEthicalAdvices() {
      return BaseStore.promiseHandler(
        () =>
          EthicalAdvicesService.getEthicalAdvices(this.getSearchPageParameters),
        (data) => {
          this.paginatedEthicalAdvices = new PaginatedEthicalAdvicesCollection(
            data
          );
        }
      );
    },
    fetchAllEthicalAdvices() {
      return BaseStore.promiseHandler(
        () => EthicalAdvicesService.getEthicalAdvices({ is_paginated: 0 }),
        (data) => {
          this.allAdvices = data?.data;
        }
      );
    },
    fetchEthicalAdvicesSchedulingCalendar() {
      return BaseStore.promiseHandler(
        () => EthicalAdvicesService.getEthicalAdvicesSchedulingCalendar(),
        (data) => {
          this.schedulingCalendar = data.data;
        }
      );
    },
    sendEthicalAdvicesSchedulingCalendar(data) {
      return BaseStore.promiseHandler(() =>
        EthicalAdvicesService.sendEthicalAdvicesSchedulingCalendar(data)
      );
    },
  },
});

import { defineStore } from "pinia";
import { usePaginatedNewsStore } from "./PaginatedNewsStore";

import News from "../models/News";
import NewsServices from "../services/NewsServices";
import BaseStore from "@/core/classes/base/BaseStore";

export const useNewsStore = defineStore("newsStore", {
  state: () => ({
    news: new News(),
  }),
  getters: {
    allNews() {
      const paginatedNewsStore = usePaginatedNewsStore();
      return paginatedNewsStore.paginatedNews.collection;
    },
  },
  actions: {
    getNewsDetails(id) {
      return BaseStore.promiseHandler(
        () => NewsServices.getNewsDetails(id),
        (data) => {
          this.news = new News(data?.data);
        }
      );
    },
    deleteNews(id) {
      return BaseStore.promiseHandler(
        () => NewsServices.deletedNews(id),
        () => {
          this.allNews.splice(
            this.allNews.findIndex((p) => p.id === id),
            1
          );
        }
      );
    },
    createNews(data, videos) {
      return BaseStore.promiseHandler(
        () => NewsServices.createNews(data),
        (data) => {
          this.uploadVideos(data?.data?.id, videos);
        }
      );
    },
    editNews(id, data, videos) {
      return BaseStore.promiseHandler(
        () => NewsServices.editNews(id, data),
        () => {
          if (videos.length) {
            this.uploadVideos(id, videos);
          }
        }
      );
    },
    uploadVideos(id, videos) {
      NewsServices.uploadVideos(id, videos);
    },
  },
});

<template>
  <v-menu
    transition="slide-y-transition"
    bottom
    offset-y
    :close-on-content-click="false"
  >
    <!--notification bell btn-->
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom :open-delay="200">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-bind="attrs" v-on="{ ...menu, ...tooltip }">
            <v-badge
              overlap
              color="accent"
              v-if="unreadNotificationCount!=0"
            >
            <template v-slot:badge>
              {{ unreadNotificationCount }}
            </template>
              <v-icon color="primary">mdi-bell-outline</v-icon>
            </v-badge>
            <v-icon v-else color="primary">mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <span>الإشعارات</span>
      </v-tooltip>
    </template>

    <v-list outlined min-width="300" max-width="440">
      <!--header-->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <h2 class="secondary--text">الإشعارات</h2>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!--notification items-->
      <template v-for="(notification, index) in notifications.collection">
        <NotificationItem :notification="notification" :key="index" />
        <v-divider :key="'d' + index"></v-divider>
      </template>
      <!--pagination buttons-->
      <div>
        <PaginationPreviousButton
          :paginatedCollection="notifications"
          :onclick="fetchPreviousPage"
        />
        <PaginationNextButton
          :paginatedCollection="notifications"
          :onclick="fetchNextPage"
        />
      </div>

      <!--read all notification action -->
      <v-btn
        text
        class="secondary--text"
        v-if="notifications.collection.length > 0"
        @click="markAllNotificationsAsRead()"
      >
        <v-icon class="ml-2">mdi-check-all</v-icon>
        تحديد الجميع كمقروء
      </v-btn>
      <!--empty list case-->
      <v-list-item v-if="notifications.collection.length == 0">
        <v-list-item-content>
          <v-list-item-title> لا يوجد إشعارات لعرضها </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useNotificationsStore } from "../stores/NotificationsStore";
import NotificationItem from "./NotificationItem.vue";
import PaginationNextButton from "@/core/components/pagination/PaginationNextButton.vue";
import PaginationPreviousButton from "@/core/components/pagination/PaginationPreviousButton.vue";

export default {
  components: {
    NotificationItem,
    PaginationNextButton,
    PaginationPreviousButton,
  },
  mounted() {
    this.fetchNotifications()
      .then()
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    ...mapActions(useNotificationsStore, [
      "fetchNotifications",
      "fetchNextPage",
      "fetchPreviousPage",
      "markAllNotificationsAsRead",
    ]),
  },
  computed: {
    ...mapState(useNotificationsStore, ["notifications","unreadNotificationCount"]),
  },
  data: () => ({}),
};
</script>

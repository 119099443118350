export const READ_NEWS_PERMISSION = "news-r";
export const WRITE_NEWS_PERMISSION = "news-w";

export const READ_PROJECTS_PERMISSION = "projects-r";
export const WRITE_PROJECTS_PERMISSION = "projects-w";

export const READ_ETHICAL_ADVICES_PERMISSION = "ethical-advices-r";
export const WRITE_ETHICAL_ADVICES_PERMISSION = "ethical-advices-w";

export const READ_FAQS_PERMISSION = "faqs-r";
export const WRITE_FAQS_PERMISSION = "faqs-w";

export const READ_PLATFORM_FEEDBACK_PERMISSION = "platform-feedback-r";
export const WRITE_PLATFORM_FEEDBACK_PERMISSION = "platform-feedback-w";

export const READ_JOBS_PERMISSION = "jobs-r";
export const WRITE_JOBS_PERMISSION = "jobs-w";

export const READ_TPS_PERMISSION = "tps-r";
export const WRITE_TPS_PERMISSION = "tps-w";

export const READ_TP_SUGGESTIONS_PERMISSION = "tp-suggestions-r";
export const WRITE_TP_SUGGESTIONS_PERMISSION = "tp-suggestions-w";

export const READ_TP_APPLICATION_QUESTIONS_PERMISSION =
  "tp-application-questions-r";
export const WRITE_TP_APPLICATION_QUESTIONS_PERMISSION =
  "tp-application-questions-w";

export const READ_TP_APPLICATIONS_PERMISSION = "tp-applications-r";
export const WRITE_TP_APPLICATIONS_PERMISSION = "tp-applications-w";

export const READ_TRAINERS_RESUMES_PERMISSION = "trainers-resumes-r";
export const WRITE_TRAINERS_RESUMES_PERMISSION = "trainers-resumes-w";

export const READ_CAREER_TEST_QUESTIONS_PERMISSION = "career-test-questions-r";
export const WRITE_CAREER_TEST_QUESTIONS_PERMISSION = "career-test-questions-w";

export const READ_JOB_PUBLISHERS_PERMISSION = "job-publishers-r";
export const WRITE_JOB_PUBLISHERS_PERMISSION = "job-publishers-w";

export const READ_USERS_PERMISSION = "users-r";
export const WRITE_USERS_PERMISSION = "users-w";

export const READ_ADMINS_PERMISSION = "admins-r";
export const WRITE_ADMINS_PERMISSION = "admins-w";

export const PERMISSIONS = [
  READ_NEWS_PERMISSION,
  WRITE_NEWS_PERMISSION,
  READ_PROJECTS_PERMISSION,
  WRITE_PROJECTS_PERMISSION,
  READ_PLATFORM_FEEDBACK_PERMISSION,
  WRITE_PLATFORM_FEEDBACK_PERMISSION,
  READ_ETHICAL_ADVICES_PERMISSION,
  WRITE_ETHICAL_ADVICES_PERMISSION,
  READ_FAQS_PERMISSION,
  WRITE_FAQS_PERMISSION,
  READ_JOB_PUBLISHERS_PERMISSION,
  WRITE_JOB_PUBLISHERS_PERMISSION,
  READ_JOBS_PERMISSION,
  WRITE_JOBS_PERMISSION,
  READ_TPS_PERMISSION,
  WRITE_TPS_PERMISSION,
  READ_TP_APPLICATION_QUESTIONS_PERMISSION,
  WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
  READ_TP_APPLICATIONS_PERMISSION,
  WRITE_TP_APPLICATIONS_PERMISSION,
  READ_TRAINERS_RESUMES_PERMISSION,
  WRITE_TRAINERS_RESUMES_PERMISSION,
  READ_CAREER_TEST_QUESTIONS_PERMISSION,
  WRITE_CAREER_TEST_QUESTIONS_PERMISSION,
  READ_USERS_PERMISSION,
  WRITE_USERS_PERMISSION,
  READ_ADMINS_PERMISSION,
  WRITE_ADMINS_PERMISSION,
];

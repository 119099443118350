import { defineStore } from "pinia";

import User from "../models/User";
import UsersService from "../services/UsersService";
import BaseStore from "@/core/classes/base/BaseStore";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: new User(),
  }),
  actions: {
    fetchUser(id) {
      return BaseStore.promiseHandler(
        () => UsersService.getUser(id),
        (data) => {
          this.user = new User(data.data);
        }
      );
    },
  },
});

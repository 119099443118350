<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> اقتراحات المنح التدريبية الواردة </template>
    <template v-slot:tableActions>
      <ToggleSuggestionsAbilityButton />
    </template>
    <template v-slot:dataTable>
      <TrainingProgramSuggestionsTable />
    </template>
  </BaseCRUDPageLayout>
</template>

<script>
import ToggleSuggestionsAbilityButton from "../components/ToggleSuggestionsAbilityButton.vue";
import TrainingProgramSuggestionsTable from "../components/TrainingProgramSuggestionsTable.vue";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "اقتراحات المنح التدريبية",
          disabled: true,
        },
      ],
    };
  },
  components: {
    TrainingProgramSuggestionsTable,
    ToggleSuggestionsAbilityButton,
  },
};
</script>

<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedUsers"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ShowUserButton :item="item" />
    </template>
     <template v-slot:[`item.email`]="{ item }">
      {{ item.email?item.email:'لا يوجد' }}
     </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useUsersStore } from "../store/UsersStore";

import ShowUserButton from "./action_buttons/ShowUserButton.vue";

export default {
  computed: {
    ...mapState(useUsersStore, ["paginatedUsers"]),
    ...mapWritableState(useUsersStore,["searchKey"]),
  },
  data() {
    return {
      headers: [
        { text: "الرقم", align: "start", value: "id" },
        //{ text: "الاسم المميز للحساب",  value: "username" },
        { text: "الاسم", value: "name" },
        { text: "البريد الالكتروني", value: "email" },
        { text: "رقم الهاتف", value: "phoneNumber" },
        { text: "مجال الدراسة", value: "studyField" },
        { text: "تاريخ الانضمام", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchUsers()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useUsersStore, [
      "fetchUsers",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  components: {ShowUserButton},
  destroyed(){
    this.searchKey=null;
  },
};
</script>

import { defineStore } from "pinia";

import UsersService from "../services/UsersService";
import BaseStore from "@/core/classes/base/BaseStore";
import UserKeyTransformations from "../models/UserKeyTransformations";
import PaginatedUserCollection from "../models/PaginatedUserCollection";

export const useUsersStore = defineStore("usersStore", {
  state: () => ({
    paginatedUsers: new PaginatedUserCollection(),
    usersTableOptions: {
      sortBy: [],
      sortDesc: [],
      page: 1,
      itemsPerPage: 5,
    },
    searchKey: null,
  }),
  getters: {
    getSearchPageParameters() {
      var sortObj =
        this.usersTableOptions.sortBy.length != 0
          ? {
              sort_by: UserKeyTransformations[this.usersTableOptions.sortBy[0]],
              sort_desc: this.usersTableOptions.sortDesc[0],
            }
          : null;
      return new Object({
        page: this.usersTableOptions.page,
        ...sortObj,
        items_per_page: this.usersTableOptions.itemsPerPage,
        search_key: this.searchKey,
      });
    },
  },
  actions: {
    setTableOptionsNFetch(options) {
      this.usersTableOptions = options;
      this.fetchUsers();
    },
    setSearchNFetch(searchKey) {
      this.searchKey = searchKey;
      this.resetPaginatedUsers();
      this.fetchUsers();
    },
    resetPaginatedUsers() {
      this.paginatedUsers = new PaginatedUserCollection();
    },
    fetchUsers() {
      return BaseStore.promiseHandler(
        () => UsersService.getUsers(this.getSearchPageParameters),
        (data) => {
          this.paginatedUsers = new PaginatedUserCollection(data);
        }
      );
    },
  },
});

<template>
  <v-container class="all-vh" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="4" sm="10" align="center">
        <v-col cols="12" md="6">
          <v-img
            class="d-none d-md-block"
            contain
            src="@/assets/logos/vector-logo.png"
          ></v-img>
          <h1 class="primary--text">لوحة الإشراف</h1>
        </v-col>
      </v-col>
      <v-col cols="12" md="5" sm="10">
        <v-card rounded="xl" class="pa-3">
          <h1 class="secondary--text text-center">تسجيل الدخول</h1>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col align="center" md="9" cols="12">
                  <v-form ref="form">
                    <BaseTextField
                      label="البريد الالكتروني"
                      placeholder="البريد الالكتروني"
                      persistent-placeholder
                      prepend-inner-icon="mdi-email"
                      type="email"
                      v-model="email"
                      :rules="[requiredRule, emailRule]"
                      @keyup.enter="postLogin()"
                    >
                    </BaseTextField>
                    <BasePasswordField
                      label="كلمة السر"
                      placeholder="كلمة السر"
                      persistent-placeholder
                      prepend-inner-icon="mdi-key"
                      v-model="password"
                      @keyup.enter="postLogin()"
                      :rules="[requiredRule, passwordRule]"
                    >
                    </BasePasswordField>
                    <v-btn
                      color="lightSecondary"
                      class="px-7"
                      rounded
                      @click="postLogin()"
                      >تسجيل الدخول</v-btn
                    >
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormValidationRulesMixin from "@/core/mixins/FormValidationRulesMixin.vue";
import { mapActions } from "pinia";
import { useAuthStore } from "../store";

export default {
  mixins: [FormValidationRulesMixin],
  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["login"]),
    postLogin() {
      if (this.$refs.form.validate()) {
        var loginData = { email: this.email, password: this.password };
        this.login(loginData).then(() => this.$router.push("/"));
      }
    },
  },
};
</script>

<style scoped>
.all-vh {
  min-height: 100vh;
}
</style>

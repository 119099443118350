import { render, staticRenderFns } from "./ApplicantDetails.vue?vue&type=template&id=61d58d1a&scoped=true&"
import script from "./ApplicantDetails.vue?vue&type=script&lang=js&"
export * from "./ApplicantDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d58d1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VBtn,VCheckbox,VChip,VIcon,VRating})

import BaseService from "@/core/classes/base/BaseService";
import {
  loginAPI,
  logoutAPI,
  profileAPI,
  changePasswordAPI
} from "./Auth.api";
import Mapper from "@/core/classes/mapper/Mapper";
import Client from "@/core/classes/client/Client";

export default class AuthService extends BaseService {
  static login(data) {
    return Client.post(loginAPI, Mapper.camelToUnderscore(data)).then((res) =>
      Promise.resolve(res)
    );
  }
  static logout() {
    return Client.delete(logoutAPI).then((res) => Promise.resolve(res));
  }
  static getProfile(){
    return Client.get(profileAPI).then((res) => Promise.resolve(res));
  }
  static changPassword(data) {
    return Client.put(changePasswordAPI, Mapper.camelToUnderscore(data)).then((res) =>
      Promise.resolve(res)
    );
  }
}

<template>
  <BaseCRUDPageLayout :links="links">
    <v-container v-if="!fetchingData">
      <v-row>
        <v-col cols="12" sm="10" order="2" order-sm="1">
          <AdminDetails :admin="admin"
        /></v-col>
        <v-col align="start" cols="12" sm="2" order="1" order-sm="2">
          <EditAdminButton
            v-can="[writePermission]"
            :item="admin"
            :isIcon="false"
            class="mb-3"
            color="accent"
            dark
          />
          <DeleteAdminButton
            v-can="[writePermission]"
            :item="admin"
            :isIcon="false"
            :goBackWhenItsFinished="true"
            class="mb-3"
            color="accent"
            dark
          />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { useAdminStore } from "../store/AdminStore";
import { WRITE_ADMINS_PERMISSION } from "@/core/statics/Permissions";

import AdminDetails from "../components/AdminDetails.vue";
import EditAdminButton from "../components/action_buttons/EditAdminButton.vue";
import DeleteAdminButton from "../components/action_buttons/DeleteAdminButton.vue";

export default {
  mounted() {
    var adminId = this.$route.params.id;
    this.fetchAdmin(adminId)
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
  computed: {
    ...mapState(useAdminStore, ["admin"]),
  },
  methods: {
    ...mapActions(useAdminStore, ["fetchAdmin"]),
  },
  data() {
    return {
      writePermission: WRITE_ADMINS_PERMISSION,
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "مشرفي المنصة",
          href: "/admins",
          disabled: false,
        },
        {
          text: "معلومات المشرف رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  components: { AdminDetails, EditAdminButton, DeleteAdminButton },
};
</script>

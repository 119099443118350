<template>
  <BaseCRUDPageLayout :links="links">
    <template v-slot:tableName> تعديل خبر </template>
    <v-container>
      <v-row>
        <v-col cols="12">
          <EditNewsForm />
        </v-col>
      </v-row>
    </v-container>
  </BaseCRUDPageLayout>
</template>

<script>
import EditNewsForm from "../components/forms/EditNewsForm.vue";

import { mapActions } from "pinia";
import { useNewsStore } from "../store/NewsStore";

export default {
  data() {
    return {
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "الأخبار",
          href: "/news",
          disabled: false,
        },
        {
          text: "تعديل الخبر رقم " + this.$route.params.id,
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions(useNewsStore, ["getNewsDetails"]),
  },
  components: { EditNewsForm },
  mounted() {
    this.getNewsDetails(this.$route.params.id)
      .then(() => {})
      .catch((err) => console.error(err));
  },
};
</script>

<style></style>

<template>
  <div>
   
    <div v-for="(ids, date, index) in schedulingCalendar" :key="index">
      <!-- Date -->
      <v-row class="my-6">
        <v-icon color="secondary" class="ml-4">mdi-calendar</v-icon>
        <h3 class="primary--text">{{ date }}</h3>
      </v-row>
      <!-- Select Button -->
      <SchedulingCalendarSelectButton :date="date" :ids="ids" />
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useEthicalAdvicesStore } from "../../store/EthicalAdvicesStore";

import SchedulingCalendarSelectButton from "./SchedulingCalendarSelectButton.vue";

export default {
  components: { SchedulingCalendarSelectButton },
  computed: {
    ...mapState(useEthicalAdvicesStore, ["schedulingCalendar"]),
  },
};
</script>

<style></style>

<template>
  <v-expansion-panel class="transparent" v-canAny:[permissionList]>
    <v-expansion-panel-header class="lightBlack--text">
      {{ title }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs center-active background-color="transparent" vertical optional>
        <v-tab
          class="f-s-md justify-start"
          v-for="tab in tabs"
          v-can:[tab.requiredPermission]
          :key="tab.title"
          :to="tab.link"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  props: {
    permissionList: Array,
    title: String,
    tabs: Array,
  },
};
</script>

<style scoped>
.f-s-md {
  font-size: medium;
}
.justify-start {
  justify-content: start;
}
</style>

import Notification from "../Notification";

export default class NewApplicantNotification extends Notification{
    constructor(data){
        super(data);
        this.jobId=data.data.job_id;
        this.jobTitle=data.data.job_title;
        this.applicationId=data.data.application_id;
        this.applicantName=data.data.applicant_name;
    }
    getTitle(){
        return 'متقدم جديد'
    }
    getBody(){
        return `متقدم جديد "${this.applicantName}" على فرصة العمل "${this.jobTitle}" ذات الرقم : #${this.jobId}`
    }
    getActionLink(){
        return `/job-opportunities/${this.jobId}/applicants`
    }
}
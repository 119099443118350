<template>
  <BaseTableItemAction
    v-can:[deletePermission]
    v-on="$listeners"
    v-bind="$attrs"
    icon="mdi-delete"
    actionName="حذف"
    @click="openDeleteDialog()"
  >
    <DeleteProjectDialog
      :showCondition="showDeleteDialog"
      :item="item"
      :goBackWhenItsFinished="goBackWhenItsFinished"
      @closed="closeDeleteDialog"
    />
    <slot></slot>
  </BaseTableItemAction>
</template>

<script>
import { WRITE_PROJECTS_PERMISSION } from "@/core/statics/Permissions";
import DeleteProjectDialog from "../dialogs/DeleteProjectDialog.vue";

export default {
  props: {
    item: Object,
    goBackWhenItsFinished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deletePermission: WRITE_PROJECTS_PERMISSION,
      showDeleteDialog: false,
    };
  },
  methods: {
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
  },
  components: { DeleteProjectDialog },
};
</script>

<style></style>

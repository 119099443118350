<template>
  <BaseCRUDPageLayout :links="links">
  <v-container>
    <h1 class="text-center primary--text">جدولة النصائح الأخلاقية</h1>
    <p class="text-center secondary--text">
      قم باختيار أو تعديل النصائح الأخلاقية التي تود عرضها في هذا الأسبوع <br />
      ومن ثم اضغط على زر <span class="accent--text">إرسال الجدولة </span>الموجود
      في نهاية الصفحة
    </p>
    <v-row v-if="!fetchingData" class="mx-2" justify="center">
      <v-col cols="12" md="6" align="center">
        <SchedulingCalendar />
        <SendSchedulingCalendarButton />
      </v-col>
    </v-row>
  </v-container>
</BaseCRUDPageLayout>
</template>

<script>
import { mapActions } from "pinia";
import { useEthicalAdvicesStore } from "../store/EthicalAdvicesStore";

import SchedulingCalendar from "../components/scheduling/SchedulingCalendar.vue";
import SendSchedulingCalendarButton from "../components/scheduling/SendSchedulingCalendarButton.vue";

export default {
  components: {
    SchedulingCalendar,
    SendSchedulingCalendarButton,
  },
  data() {
    return {
      fetchingData: true,
      links: [
        {
          text: "الصفحة الرئيسية",
          href: "/",
          disabled: false,
        },
        {
          text: "النصائح الأخلاقية",
          href: "/ethical-advices",
          disabled: false,
        },
        {
          text: "جدولة النصائح",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions(useEthicalAdvicesStore, [
      "fetchAllEthicalAdvices",
      "fetchEthicalAdvicesSchedulingCalendar",
    ]),
  },
  mounted() {
    Promise.all([
      this.fetchAllEthicalAdvices(),
      this.fetchEthicalAdvicesSchedulingCalendar(),
    ])
      .then(() => {
        this.fetchingData = false;
      })
      .catch((err) => console.error(err));
  },
};
</script>

<style></style>

<template>
    <div v-can:[createPermission]>
      <v-btn to="/training-program-application-questions/create" color="accent"
        ><v-icon>mdi-plus</v-icon> إنشاء سؤال جديد </v-btn
      >
    </div>
  </template>
  <script>
  import { WRITE_TP_APPLICATION_QUESTIONS_PERMISSION } from '@/core/statics/Permissions';
  export default {
    data() {
      return {
        createPermission:WRITE_TP_APPLICATION_QUESTIONS_PERMISSION,
      };
    },
    methods: {},
  };
  </script>
  
import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";
import {
  createEthicalAdviceAPI,
  deleteEthicalAdviceAPI,
  getEthicalAdvicesAPI,
  editEthicalAdviceAPI,
  getEthicalAdvicesSchedulingCalenderAPI,
  sendEthicalAdvicesSchedulingCalenderAPI,
} from "./EthicalAdvices.api";

export default class EthicalAdvicesService extends BaseService {
  static getEthicalAdvices(params) {
    return Client.get(getEthicalAdvicesAPI, params).then((res) =>
      Promise.resolve(res)
    );
  }
  static deleteEthicalAdvice(id) {
    return Client.delete(deleteEthicalAdviceAPI(id)).then((res) =>
      Promise.resolve(res)
    );
  }
  static createEthicalAdvice(data) {
    return Client.post(createEthicalAdviceAPI, data).then((res) =>
      Promise.resolve(res)
    );
  }
  static editEthicalAdvice(id, data) {
    return Client.put(editEthicalAdviceAPI(id), data).then((res) =>
      Promise.resolve(res)
    );
  }
  static getEthicalAdvicesSchedulingCalendar() {
    return Client.get(getEthicalAdvicesSchedulingCalenderAPI).then((res) =>
      Promise.resolve(res)
    );
  }
  static sendEthicalAdvicesSchedulingCalendar(data) {
    return Client.post(sendEthicalAdvicesSchedulingCalenderAPI, data).then(
      (res) => Promise.resolve(res)
    );
  }
}

<template>
  <BaseDataTable :headers="headers" :paginatedItems="paginatedEthicalAdvices"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)">
    <template v-slot:actions="{ item }">
      <EditEthicalAdviceButton :item="item" />
      <DeleteEthicalAdviceButton :item="item" />
    </template>
    <template v-slot:[`item.adderName`]="{ item }">
      <RouterLink v-if="hasPermission(showAdminPermission)" :to="'/admins/' + item.adderId">
        {{ item.adderName }}
      </RouterLink>
      <label v-else> {{ item.adderName }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { useEthicalAdvicesStore } from "../store/EthicalAdvicesStore";
import EditEthicalAdviceButton from "./action_buttons/EditEthicalAdviceButton.vue";
import DeleteEthicalAdviceButton from "./action_buttons/DeleteEthicalAdviceButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";
import { READ_ADMINS_PERMISSION } from "@/core/statics/Permissions";

export default {
  mixins: [PermissionManagerMixin],
  components: { EditEthicalAdviceButton, DeleteEthicalAdviceButton, RouterLink },
  computed: {
    ...mapState(useEthicalAdvicesStore, ["paginatedEthicalAdvices"]),
    ...mapWritableState(useEthicalAdvicesStore, ["searchKey"]),
  },
  data() {
    return {
      showAdminPermission: READ_ADMINS_PERMISSION,
      //TODO make sure what cols are sortable
      headers: [
        { text: "الرقم", value: "id" },
        { text: "النصيحة", width: "50%", align: "center", value: "content" },
        { text: "أضافه", value: "adderName" },
        { text: "تاريخ الإضافة ", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchEthicalAdvices()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(useEthicalAdvicesStore, [
      "fetchEthicalAdvices",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  destroyed() {
    this.searchKey = null;
  }
};
</script>

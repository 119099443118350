<template>
  <v-checkbox
    :input-value="this.isOrganizationGraduate == 1"
    @change="setFilterState($event)"
    label="تصفية حسب خريجي الجمعية"
  ></v-checkbox>
</template>

<script>
import { mapActions } from "pinia";
import { useJobApplicantsStore } from "../store/JobApplicantsStore";
export default {
  data() {
    return {
      isOrganizationGraduate: null,
    };
  },
  methods: {
    ...mapActions(useJobApplicantsStore, ["setFilterNFetch"]),
    setFilterState(value) {
      if (value) {
        this.isOrganizationGraduate = 1;
      } else {
        this.isOrganizationGraduate = 0;
      }
      this.setFilterNFetch(this.isOrganizationGraduate);
    },
  },
};
</script>

<style scoped>
.selection-width {
  max-width: 200px;
}
</style>

import BasePaginatedCollection from "@/core/classes/base/BasePaginatedCollection";
import EthicalAdvice from "./EthicalAdvice";

export default class PaginatedEthicalAdvicesCollection extends BasePaginatedCollection {
  constructor(paginationResponse) {
    super(paginationResponse);
    paginationResponse?.data.map((advice) => {
      this.collection.push(new EthicalAdvice(advice));
    });
  }
  updatePaginationPage(paginationResponse) {
    super.updatePaginationPage(paginationResponse);
    paginationResponse.data.map((advice) => {
      this.collection.push(new EthicalAdvice(advice));
    });
  }
}

import Client from "@/core/classes/client/Client";
import BaseService from "@/core/classes/base/BaseService";
import {
  getJobsStatisticsAPI,
  getTPStatisticsAPI,
  getUsersStatisticsAPI,
  getPublishersStatisticsAPI
} from "./Home.api";

export default class HomeService extends BaseService {
  static getTPStatistics(params) {
    return Client.get(getTPStatisticsAPI,params).then((res) => Promise.resolve(res));
  }
  static getJobsStatistics(params) {
    return Client.get(getJobsStatisticsAPI,params).then((res) => Promise.resolve(res));
  }
  static getUsersStatistics(params) {
    return Client.get(getUsersStatisticsAPI,params).then((res) => Promise.resolve(res));
  }
  static getPublishersStatistics(params) {
    return Client.get(getPublishersStatisticsAPI,params).then((res) => Promise.resolve(res));
  }
}

<template>
  <BaseDataTable
    :headers="headers"
    :paginatedItems="paginatedNews"
    @debouncedSearch="(searchKey) => setSearchNFetch(searchKey)"
    @optionsChanged="(options) => setTableOptionsNFetch(options)"
  >
    <template v-slot:actions="{ item }">
      <ViewNewsButton :item="item" />
      <EditNewsButton :item="item" />
      <DeleteNewsButton :item="item" />
    </template>
    <template v-slot:[`item.adderName`]="{ item }">
      <RouterLink v-if="hasPermission(showAdminPermission)" :to="'/admins/'+item.adderId">
        {{ item.adderName }}
      </RouterLink>
      <label v-else> {{ item.adderName }}</label>
    </template>
  </BaseDataTable>
</template>

<script>
import { mapActions, mapState, mapWritableState } from "pinia";
import { usePaginatedNewsStore } from "../store/PaginatedNewsStore";
import { READ_ADMINS_PERMISSION } from "@/core/statics/Permissions";
import DeleteNewsButton from "./action_buttons/DeleteNewsButton.vue";
import ViewNewsButton from "./action_buttons/ViewNewsButton.vue";
import EditNewsButton from "./action_buttons/EditNewsButton.vue";
import { RouterLink } from "vue-router";
import PermissionManagerMixin from "@/core/mixins/PermissionManagerMixin.vue";

export default {
  mixins:[PermissionManagerMixin],
  components: { DeleteNewsButton, ViewNewsButton, EditNewsButton, RouterLink },
  computed: {
    ...mapState(usePaginatedNewsStore, ["paginatedNews"]),
    ...mapWritableState(usePaginatedNewsStore,["searchKey"]),
  },
  data() {
    return {
      showAdminPermission:READ_ADMINS_PERMISSION,
      //TODO make sure what cols are sortable
      headers: [
        { text: "الرقم", value: "id" },
        { text: "العنوان", value: "title" },
        { text: "أضافه", value: "adderName" },
        { text: "تاريخ الإضافة ", value: "createdAt" },
        { text: "الإجراءات", sortable: false, value: "actions" },
      ],
    };
  },
  mounted() {
    this.fetchNews()
      .then()
      .catch((err) => console.error(err));
  },
  methods: {
    ...mapActions(usePaginatedNewsStore, [
      "fetchNews",
      "setSearchNFetch",
      "setTableOptionsNFetch",
    ]),
  },
  destroyed(){
    this.searchKey=null;
  },
};
</script>
